import {
    Badge,
    Box,
    Center,
    FormControl,
    FormLabel,
    HStack,
    Input, Skeleton,
    Spacer, Stack,
    Text,
    useToast,
    VStack
} from "@chakra-ui/react";
import {Select as SearchSelect} from "chakra-react-select";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {StudentAPI} from "../../../api/StudentAPI";
import {ObjectId, to_oid} from "../../../utils/ObjectId";
import {EvaluationAPI} from "../../../api/EvaluationAPI";
import {Student} from "../../../models/student";
import {EvaluationEntryAPI} from "../../../api/EvaluationEntryAPI";
import {TableContent} from "../../table/TableContent";
import {EvaluationEntry} from "../../../models/evaluation_entry";
import {Evaluation} from "../../../models/evaluation";
import {MarkCategory} from "../../../models/mark_category";
import {MarkCategoriesAPI} from "../../../api/MarkCategoryAPI";
import {StudentEvaluationList} from "./StudentEvaluationList";


interface StudentMarkData {
    student: Student,
    final_mark?: number
    maximum_potential_mark?: number
}

interface Props {
    student_mark: StudentMarkData
}


function get_student_mark(final_mark: number | undefined) {
    if (final_mark) {
        return (
            <Box w='120px'>
                <Badge colorScheme='green'>
                    {final_mark!.toFixed(2) + "%"}
                </Badge>
            </Box>
        )
    } else {
        return (
            <Box w='120px'>
                <Badge colorScheme='orange'>
                    No Mark!
                </Badge>
            </Box>
        )
    }
}

function get_maximum_potential_mark(maximum_potential_mark: number | undefined) {
    if (maximum_potential_mark) {
        return (
            <Box w='120px'>
                <Badge colorScheme='blue'>
                    {maximum_potential_mark!.toFixed(2) + "%"}
                </Badge>
            </Box>
        )
    } else {
        return (
            <Box w='120px'>
                <Badge colorScheme='orange'>
                    No Mark!
                </Badge>
            </Box>
        )
    }
}



export const StudentView = ({student_mark}: Props) => {

    if (student_mark) {

        return (
            <>
                <HStack>
                    <FormControl>
                        <FormLabel>
                            Student Name
                        </FormLabel>
                        <Text>
                            {student_mark.student.first_name + " " + student_mark.student.last_name}
                        </Text>
                    </FormControl>
                    <FormControl>
                        <FormLabel>
                            Student Mark
                        </FormLabel>
                        <Text>
                            {get_student_mark(student_mark.final_mark)}
                        </Text>
                    </FormControl>
                    <FormControl>
                        <FormLabel>
                            Student Maximum Potential Mark
                        </FormLabel>
                        <Text>
                            {get_maximum_potential_mark(student_mark.maximum_potential_mark)}
                        </Text>
                    </FormControl>
                </HStack>
                <StudentEvaluationList student_id={student_mark.student._id}/>

            </>
        )
    } else return (
        <Center>
            <Text>Please Select a Student!</Text>
        </Center>
    )
}