import { useEffect, useState } from "react";
import {
    Heading,
    Select,
    Spacer,
    Badge,
    Checkbox,
    FormControl,
    WrapItem,
    Wrap,
    Button,
    useBreakpointValue,
} from '@chakra-ui/react';
import { AttendanceAPI } from "../../api/AttendanceAPI";
import { useParams, useLocation } from "react-router-dom";
import { Student } from "../../models/student";
import { Attendance } from "../../models/attendance";
import { Homeroom } from "protobuffer-ts/dist/class_service/homeroom";
import { AttendanceDesktopTable } from "../attendance/AttendanceDesktopTable";
import { AttendanceMobileTable } from "../attendance/AttendanceMobileTable";
import { DateTime } from "../../utils/DateTime";
import { SemesterAPI } from "../../api/SemesterAPI";
import { Semester } from "protobuffer-ts/dist/class_service/semester";
import { HomeroomAPI } from "../../api/HomeroomAPI";
import { to_oid } from "../../utils/ObjectId";
import ExcuseStudent from "../attendance/ExcuseStudent";
import { StudentAPI } from "../../api/StudentAPI";
import { proto_utils } from "../../utils/proto_utils";

export interface AttendanceTableData {
    student: Student,
    date: string,
    attendance_entry: Attendance,
    first_name: string,
    last_name: string,
    name: string
}

export const AttendanceHomeroomParents = (() => {
    let { homeroom_id, student_id, selected_date } = useParams();  // Capture selected_date from URL
    const { pathname } = useLocation();
    const [entry_data, set_entry_data] = useState<Array<AttendanceTableData>>([]);
    const [student_attendance_data, set_student_attendance_data] = useState<Array<AttendanceTableData>>([]);
    const [homeroom, set_homeroom] = useState<Homeroom>();
    const [selectedStudent, setSelectedStudent] = useState<Student>();
    const [startDate, setStartDate] = useState<Date>(new Date('2024-09-01T00:00:00'));
    const [endDate, setEndDate] = useState<Date>(new Date('2025-07-01T00:00:00'));
    const [semesters, setSemesters] = useState<Semester[]>([]);
    const [showAll, setShowAll] = useState<boolean>(false);
    const [totalDays, setTotalDays] = useState(0);
    const [totalPresent, setTotalPresent] = useState(0);
    const [totalLate, setTotalLate] = useState(0);
    const [totalAbsent, setTotalAbsent] = useState(0);
    const [isExcuseDialogOpen, setIsExcuseDialogOpen] = useState(false);
    const [excuseDate, setExcuseDate] = useState<Date>(new Date());  // State for optional date

    const handleOpenDialog = () => setIsExcuseDialogOpen(true);
    const handleCloseDialog = () => {
        if (selectedStudent) {
            get_student_attendance_entries(selectedStudent, startDate, endDate)
        }
        setIsExcuseDialogOpen(false);
    }

    const get_semesters = async (): Promise<void> => {
        const res = await SemesterAPI.get_active_semesters();
        setSemesters(res);
    };

    const on_semester_change = (semesterId: string) => {
        const selectedSemester = semesters.find(sem => sem.id!.id === semesterId);
        if (selectedSemester && selectedStudent) {
            setStartDate(selectedSemester.startDate!);
            setEndDate(selectedSemester.endDate!);
            get_student_attendance_entries(selectedStudent, selectedSemester.startDate!, selectedSemester.endDate!);
        }
    };

    const get_homeroom = () => {
        HomeroomAPI.get_homeroom(to_oid(homeroom_id!)).then((res) => {
            set_homeroom(res!);
        });
    };

    const get_student = () => {
        StudentAPI.get_student(student_id!).then((res) => {
            setSelectedStudent(res!);
        });
    };

    const get_student_attendance_entries = (student: Student, startDate: Date, endDate: Date) => {
        AttendanceAPI.get_student_homeroom_attendance_entries(homeroom_id!, student._id.$oid, startDate, endDate)
            .then((res) => {
                if (res) {
                    const entries = res.map((entry) => ({
                        date: entry.date ? new DateTime(entry.date as any).to_utc_string_date() : "No Date",
                        student: student,
                        attendance_entry: entry,
                        first_name: student.first_name,
                        last_name: student.last_name,
                        name: `${student.first_name} ${student.last_name}`
                    }));

                    const filteredEntries = showAll
                        ? entries
                        : entries.filter(entry => entry.attendance_entry.status === "Late" || entry.attendance_entry.status === "Absent");

                    filteredEntries.sort((a, b) => {
                        const dateA = new Date(parseInt(a.attendance_entry.date.$date.$numberLong));
                        const dateB = new Date(parseInt(b.attendance_entry.date.$date.$numberLong));

                        return dateA.getTime() - dateB.getTime();
                    });

                    setTotalDays(entries.length);
                    setTotalPresent(entries.filter(entry => entry.attendance_entry.status === 'Present').length);
                    setTotalLate(entries.filter(entry => entry.attendance_entry.status === 'Late').length);
                    setTotalAbsent(entries.filter(entry => entry.attendance_entry.status === 'Absent').length);

                    set_student_attendance_data([]);
                    set_student_attendance_data(filteredEntries);
                }
            });
    };

    useEffect(() => {
        if (selected_date) {
            let [day, month, year] = selected_date.split("-");
            day = (Number(day) + 1).toString().padStart(2, '0');
            const parsedDate = new Date(`${year}-${month}-${day}`);

            // Validate date
            if (!isNaN(parsedDate.getTime())) {
                setExcuseDate(parsedDate);
            }
        }
    }, [selected_date]);

    useEffect(() => {
        get_semesters();
        get_homeroom();
        get_student();
    }, []);

    useEffect(() => {
        if (selectedStudent && startDate && endDate) {
            get_student_attendance_entries(selectedStudent, startDate, endDate);
        }
    }, [selectedStudent, startDate, endDate, showAll]);

    useEffect(() => {
        // Automatically open excuse dialog if the URL ends with /excuse
        if (pathname.includes("/excuse")) {
            setIsExcuseDialogOpen(true);
        }
    }, [pathname]);

    const isMobile = useBreakpointValue({ base: true, md: false });

    return (
        <>
            {selectedStudent && (
                <>
                    <Heading size="sm" mt={4}>{selectedStudent?.first_name} {selectedStudent?.last_name} Attendance in {homeroom?.name} Homeroom</Heading>
                    <Wrap mt={4} spacing={4} align="center">
                        <WrapItem>
                            <FormControl id="semester">
                                <Select placeholder="Select semester" onChange={(e) => on_semester_change(e.target.value)}>
                                    {semesters.map((semester) => (
                                        <option key={semester.id!.id.toString()} value={semester.id?.id.toString()}>
                                            {semester.name} ({semester.startDate!.toLocaleDateString()} - {semester.endDate!.toLocaleDateString()})
                                        </option>
                                    ))}
                                </Select>
                            </FormControl>
                        </WrapItem>

                        <WrapItem>
                            <FormControl id="show-all" display="flex" alignItems="center">
                                <Checkbox colorScheme='green' isChecked={showAll} onChange={() => setShowAll(!showAll)}>
                                    Show Present
                                </Checkbox>
                            </FormControl>
                        </WrapItem>

                        <WrapItem>
                            <Button colorScheme="blue" onClick={handleOpenDialog}>
                                Excuse Student
                            </Button>

                            {selectedStudent && homeroom && (
                                <ExcuseStudent
                                    isOpen={isExcuseDialogOpen}
                                    onClose={handleCloseDialog}
                                    homeroom={homeroom}
                                    student={selectedStudent}
                                    selected_date={excuseDate}
                                />
                            )}
                        </WrapItem>
                    </Wrap>
                    <Wrap align="start" spacing={2} mt={4}>
                        <Badge colorScheme="blue">Total days: {totalDays}</Badge>
                        <Badge colorScheme="green">Total days present: {totalPresent}</Badge>
                        <Badge colorScheme="yellow">Total days late: {totalLate}</Badge>
                        <Badge colorScheme="red">Total days absent: {totalAbsent}</Badge>
                    </Wrap>
                    <Spacer height="10px" />
                    {isMobile ? (
                        <AttendanceMobileTable entry_data={student_attendance_data} single_student_view={true} editable={false} />
                    ) : (
                        <AttendanceDesktopTable entry_data={student_attendance_data} single_student_view={true} editable={false}  show_periods={false}/>
                    )}
                </>
            )}
        </>
    );
});