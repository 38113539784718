import React, { useEffect, useMemo, useState } from "react";
import {
    Text,
    Stack,
    HStack,
    FormLabel,
    SimpleGrid,
    useToast,
    Alert,
    Box,
    AlertTitle,
    AlertDescription,
    Badge,
    Spacer,
    GridItem,
    Switch,
} from '@chakra-ui/react';
import { ReportEntry, ReviewStatusColor } from "../../models/report_entry";
import { to_oid } from "../../utils/ObjectId";
import { Student } from "../../models/student";
import { StudentAPI } from "../../api/StudentAPI";
import { BlCard } from "../layout/Card";
import { FiAlertCircle } from "react-icons/fi";
import { ReportLayout } from "protobuffer-ts/dist/class_service/report_layout";
import { FlexWrap } from "../layout/FlexWrap";
import { convertCaseToNormal } from "../../utils/helpers";
import { CourseAPI } from "../../api/CourseAPI";
import { PdfGenerator } from "../homeroom_pdf/PdfGenerator";
import { PdfGenerator as HighSchoolPdfGenerator } from "../high_school_pdf/PdfGenerator";

interface Props {
	report_entry: ReportEntry,
	report_layout?: ReportLayout
}

export const StudentReportEntryView : React.FC<Props> = React.memo(({ report_entry, report_layout }) => {
	const [report_entry_state, set_report_entry_state] = useState<ReportEntry>(report_entry);
	const [report_layout_state, set_report_layout_state] = useState<ReportLayout | undefined>(report_layout);

    const student_id = useMemo(() => report_entry_state.student, [report_entry_state])
	const [student, set_student] = useState<Student>();

    const [hide_learning_skills, set_hide_learning_skills] = useState<boolean>(false);

    useEffect(() => {
        const fetchAllData = async () => {
            try {
                const _student = await StudentAPI.get_student(student_id.$oid)
                set_student(_student!);

                if (report_entry_state.course) {
                    if (!report_layout) {
                        report_layout = await CourseAPI.get_report_layout(report_entry_state.course!)
                        const course = await CourseAPI.get_course(report_entry_state.course!)

                        set_report_layout_state(report_layout)
                        if (course.homeroom) {
                            set_hide_learning_skills(true);
                        }

                    }

                    // loop over layout sections and ensure that they're filled
                    const filledCheckboxes = report_layout?.checkBoxes?.map((checkbox, index) => ({
                        check_box_id: to_oid(checkbox.id!.id),
                        state: report_entry.checkboxes?.find(e => e.check_box_id.$oid == checkbox.id!.id)?.state || false
                    })) || []

                    const filledSections =  report_layout?.sections?.map((section, index) => ({
                        layout_id: to_oid(section.id!.id),
                        mark: report_entry.sections?.find(e => e.layout_id.$oid == section.id!.id)?.mark || ""
                    })) || []

                    report_entry = {
                        ...report_entry,
                        checkboxes: filledCheckboxes,
                        sections: filledSections
                    }
                }

                set_report_entry_state(new ReportEntry(report_entry))
            } catch (e) {
                console.error('Error fetching data', e);
            }
        };
    
        fetchAllData();
    }, [report_entry])


    return (
        <Stack gap={4} id={report_entry_state?.student.$oid} >
			<BlCard>
                <FlexWrap mb="6">
                    <Stack spacing="0">
                        <HStack gap="2">
                            <Text fontSize="lg" fontWeight="bold" >
                                {student?.first_name} {student?.last_name}
                            </Text>

                            { report_entry_state?.review_status && 
                                <Badge fontSize='xs' colorScheme={ReviewStatusColor[report_entry_state.review_status]}>
                                    {convertCaseToNormal(report_entry_state.review_status)}
                                </Badge>
                            }
                        </HStack>
 
                        <FormLabel textColor="gray.500">
                            <strong>{report_entry_state.class_name}</strong>
                        </FormLabel>
                    </Stack>

                    <Spacer />

                    <FlexWrap />
                </FlexWrap>

            { report_entry_state?.requested_changes && 
                <Alert borderRadius="lg" bgColor="orange.100" gap="4" mb="8">
                    <FiAlertCircle color="orange" size="50" />

                    <Box>
                        <AlertTitle color="orange.600">Changes Requested!</AlertTitle>

                        <AlertDescription fontWeight="bold">
                            {report_entry_state.requested_changes}
                        </AlertDescription>
                    </Box>
                </Alert>
            }

				<SimpleGrid
					bg="gray.50"
                    border="1px dashed"
                    borderColor="gray.300"
					p="4"
					rounded="md"
					columns={{md: 2, lg: 4 }} gap="6"
				>
                    { report_entry_state.checkboxes.length !== 0 &&
                        <Stack gap="4">
                            <Text fontWeight="bold">Checkboxes</Text>

                            {report_entry_state.checkboxes.map(checkbox => (
                                <HStack key={checkbox.check_box_id.$oid}>
                                    <Switch isChecked={!!checkbox.state} />

                                    <Text>
                                        {report_layout_state?.checkBoxes.find(c => c.id?.id == checkbox.check_box_id.$oid)?.name}
                                    </Text>
                                </HStack>
                            ))}
                        </Stack>
                    }

                    { report_entry_state.sections.length !== 0 &&
                    <Stack gap="4">
                        <Text fontWeight="bold">Marks</Text>

                        { report_entry_state?.sections?.map(section => 
                            <FlexWrap>
                                <Text>
                                    {report_layout_state?.sections.find(s => s.id?.id == section.layout_id.$oid)?.name} 
                                    : 
                                </Text>

                                <Text fontWeight="bold">{section.mark}</Text>
                            </FlexWrap>
                        )}
                    </Stack>
                    }

                    {   !hide_learning_skills &&
                        <Stack gap="4" as={GridItem}  gridColumn="span 2">
                            <Text fontWeight="bold">Learning Skills</Text>

                            {report_entry_state.learning_skills.map(learningSkill => (

                                <FlexWrap>
                                    <Text>
                                    {report_entry_state?.semester_report_layout.learningSkills.find(s => s.id?.id == learningSkill.learning_skill_id.$oid)?.title}
                                    </Text>

                                    <Text fontWeight="bold">{learningSkill.mark}</Text>
                                </FlexWrap>
                            ))}
                        </Stack>
                    }
				</SimpleGrid>

				<Box>
                    <Text fontWeight="bold">Comment</Text>

                    <Text>{report_entry.comment}</Text>
                </Box>

                { report_entry.homeroom && 
                    <HStack>
                        <Spacer />

                        <PdfGenerator 
                            student_id={student_id}
                            homeroom_id={report_entry.homeroom}
                            report_type={report_entry.report_type}
                        />
                    </HStack>
                }

                { report_entry.course && 
                    <HStack>
                        <Spacer />

                        <HighSchoolPdfGenerator 
                            student_id={student_id}
                            semester_id={to_oid(report_entry.semester.id!.id)}
                            report_type={report_entry.report_type}
                        />
                    </HStack>
                }
			</BlCard>
        </Stack>
    );
});
