import React from "react";
import { FormControl, FormLabel, Select, Stack, RangeSlider, RangeSliderTrack, RangeSliderFilledTrack, RangeSliderThumb } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import FormInput from "../forms/FormInput";
import FormSwitch from "../forms/FormSwitch";

enum PatternMode {
    Direct = "direct",
    Common = "common",
    Builder = "builder",
}

const commonPatterns: Record<string, string> = {
    phone: "^\\+?[0-9]{10,15}$", // International phone numbers
    name: "^[a-zA-Z\\s]+$", // Full name (letters and spaces)
    email: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$",
};

interface CustomFieldPatternBuilderProps {
    onPatternChange: (pattern: string) => void;
    initialCustomPattern?: string;
}

const CustomFieldPatternBuilder: React.FC<CustomFieldPatternBuilderProps> = ({ onPatternChange, initialCustomPattern }) => {

    const { register, watch, setValue, control } = useForm({
        defaultValues: {
            mode: PatternMode.Direct,
            customPattern: initialCustomPattern || "",
            commonPattern: Object.keys(commonPatterns)[0],
            builderValues: {
                acceptedChars: {
                    letters: true,
                    numbers: true,
                    spaces: false,
                    special: false,
                },
                lengthRange: [1, 10],
            },
        },
    });

    const mode = watch("mode") as PatternMode;
    const customPattern = watch("customPattern");
    const commonPattern = watch("commonPattern");
    const builderValues = watch("builderValues");

    const generateBuilderPattern = () => {
        const { acceptedChars, lengthRange } = builderValues;
        const [minLength, maxLength] = lengthRange;
        let charSet = "";

        if (acceptedChars.letters) charSet += "a-zA-Z";
        if (acceptedChars.numbers) charSet += "0-9";
        if (acceptedChars.spaces) charSet += " ";
        if (acceptedChars.special) charSet += "!@#$%^&*()_+|{}:;.,<>?~`";

        return `^[${charSet}]{${minLength},${maxLength}}$`;
    };

    const handleLengthRangeChange = (value: [number, number]) => {
        setValue("builderValues.lengthRange", value);
    };

    React.useEffect(() => {
        let pattern = "";

        if (mode === PatternMode.Direct) {
            pattern = customPattern;
        } else if (mode === PatternMode.Common) {
            pattern = commonPatterns[commonPattern];
        } else if (mode === PatternMode.Builder) {
            pattern = generateBuilderPattern();
        }

        if (pattern) {
            onPatternChange(pattern);
        }
    }, [mode, customPattern, commonPattern, builderValues, onPatternChange]);

    return (
        <Stack spacing={4}>
            <FormControl>
                <FormLabel>Pattern Mode</FormLabel>
                <Select {...register("mode")}>
                    <option value={PatternMode.Direct}>Direct Regex Input</option>
                    <option value={PatternMode.Common}>Choose Common Pattern</option>
                    <option value={PatternMode.Builder}>Build Pattern</option>
                </Select>
            </FormControl>

            {mode === PatternMode.Direct && (
                <FormInput
                    name="customPattern"
                    label="Custom Pattern"
                    register={register("customPattern")}
                    placeholder="Enter your custom regex pattern here"
                />
            )}

            {mode === PatternMode.Common && (
                <FormControl>
                    <FormLabel>Choose Common Pattern</FormLabel>
                    <Select {...register("commonPattern")}>
                        {Object.keys(commonPatterns).map((key) => (
                            <option key={key} value={key}>
                                {key}
                            </option>
                        ))}
                    </Select>
                </FormControl>
            )}

            {mode === PatternMode.Builder && (
                <Stack spacing={4}>
                    <FormControl>
                        <FormLabel>Accepted Characters</FormLabel>

                        <Stack spacing={4}>
                            <Stack direction="row" spacing={4}>
                                <FormSwitch
                                    name="builderValues.acceptedChars.letters"
                                    control={control}
                                />
                                <FormLabel>Letters</FormLabel>

                                <FormSwitch
                                    name="builderValues.acceptedChars.numbers"
                                    control={control}
                                />
                                <FormLabel>Numbers</FormLabel>
                            </Stack>


                            <Stack direction="row" spacing={4}>
                                <FormSwitch
                                    name="builderValues.acceptedChars.spaces"
                                    control={control}
                                />
                                <FormLabel>Spaces</FormLabel>

                                <FormSwitch
                                    name="builderValues.acceptedChars.special"
                                    control={control}
                                />
                                <FormLabel>Special Characters</FormLabel>
                            </Stack>
                        </Stack>
                    </FormControl>

                    <FormControl>
                        <FormLabel>Length Range</FormLabel>
                        <RangeSlider
                            defaultValue={builderValues.lengthRange}
                            min={0}
                            max={30}
                            step={1}
                            onChange={handleLengthRangeChange}
                        >
                            <RangeSliderTrack>
                                <RangeSliderFilledTrack />
                            </RangeSliderTrack>
                            <RangeSliderThumb index={0} />
                            <RangeSliderThumb index={1} />
                        </RangeSlider>
                        <Stack direction="row" justify="space-between">
                            <span>Min: {builderValues.lengthRange[0]}</span>
                            <span>Max: {builderValues.lengthRange[1]}</span>
                        </Stack>
                    </FormControl>
                </Stack>
            )}
        </Stack>
    );
};

export default CustomFieldPatternBuilder;