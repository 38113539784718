import { Button, ButtonGroup } from '@chakra-ui/react';
import { memo } from "react";
import { TemplatePlaceholders } from '../../models/comment_template';

interface Props {
	onPlaceholderClick: (placeholder: string) => void
}

export const CommentPlaceholdersGroup = memo(({onPlaceholderClick}: Props) => {
    return (
        <ButtonGroup size='sm' isAttached variant="outline">
			{Object.values(TemplatePlaceholders).map((placeholder) => (
				<Button
					key={placeholder}
					onClick={() => onPlaceholderClick(placeholder)}
					bgColor="gray.100"
				>
					{placeholder}
				</Button>
			))}
		</ButtonGroup>
    );
})