import {ObjectId} from "../utils/ObjectId";
import {DateTime} from "../utils/DateTime";
export class Student {
    _id: ObjectId;
    id: string;
    status: StudentStatus;
    username: string;
    family_id: ObjectId;

    first_name: string;
    last_name: string;
    full_name: string; 
    gender: string;
    grade: string;
    date_of_birth: DateTime;

    registration_date: DateTime | null;
    admission_date: DateTime | null;

    documents: Array<Document>;
    forms: Array<StudentForm>
    form_data: FormDataInterface;
    
    constructor(data: any){
        this._id = data._id
        this.id = data.id
        this.status = data.status
        this.username = data.username
        this.family_id = data.family_id

        this.first_name = data.first_name
        this.last_name = data.last_name
        this.full_name = `${data.first_name} ${data.last_name}`;
        this.gender = data.gender
        this.grade = data.grade
        this.date_of_birth  = new DateTime(data.date_of_birth)

        this.registration_date = data.registration_date?  new DateTime(data.registration_date) : null
        this.admission_date = data.admission_date?  new DateTime(data.admission_date) : null

        this.documents = Array.isArray(data.documents) ? data.documents : [];
        this.documents.forEach((document) => {
            document.date = new DateTime(document.date);
        });
    
        this.forms = data.forms   
        this.form_data = data.form_data
    }

    static getName(student?: Student) {
        return student?.first_name + " " + student?.last_name;
    }
}


export interface StudentProfileData {
    first_name: string;
    last_name: string;
    full_name: string;
    gender: string;
    grade: string;
    date_of_birth: DateTime;
}

export interface StudentForm {
    form_name: string,
    data: any,
    signature: Signature,
}

export interface Signature {
    document_id: ObjectId,
    signed_by: ObjectId,
}

export enum StudentStatus {
    Applicant = "Applicant",
    Processed = "Processed",
    Rejected = "Rejected",
    Enrolled = "Enrolled",
    ReRegistration = "ReRegistration",
    Withdrawn = "Withdrawn"
}

export enum DocumentType {
    Other = "Other",
    ProofOfAddress = "ProofOfAddress",
    HealthRecord = "HealthRecord",
    LegalDocument = "LegalDocument",
    EducationDocument = "EducationDocument",
    Signature = "Signature"
}

export interface Document {
    _id: ObjectId,
    name: string,
    document_type: DocumentType,
    date: DateTime
}


export enum FormDataStatus {
    None = "None",
    Waiting = "Waiting",
    Submitted = "Submitted",
    NeedsChange = "NeedsChange",
    Approved = "Approved"
}

interface FormDataDocumentsCheckList {
    immunization_record: boolean,
    citizenship_passport_PR: boolean,
    proof_of_address: boolean,
    education_documents: boolean,
    health_documents: boolean,
    legal_documents: boolean,
    void_check: boolean
}

export interface FormDataDocuments {
    status: FormDataStatus,
    comment: string,
    check_list: FormDataDocumentsCheckList
}

export interface FormDataObj {
    status: FormDataStatus,
    comment: string
}

export interface FormDataInterface {
    documents: FormDataDocuments,
    profile: FormDataObj,
    financial: FormDataObj,
    health: FormDataObj,
    family: FormDataObj,
    office_information: FormDataObj,
    consent: FormDataObj,
    uniform: FormDataObj
    donation: FormDataObj
}

export const Grades = new Map<string, string>([
    ["", ""],
    ["pk", "Pre K"],
    ["jk", "Junior Kindergarten"],
    ["sk", "Senior Kindergarten"],
    ["grade1", "Grade 1"],
    ["grade2", "Grade 2"],
    ["grade3", "Grade 3"],
    ["grade4", "Grade 4"],
    ["grade5", "Grade 5"],
    ["grade6", "Grade 6"],
    ["grade7", "Grade 7"],
    ["grade8", "Grade 8"],
    ["grade9", "Grade 9"],
    ["grade10", "Grade 10"],
    ["grade11", "Grade 11"],
    ["grade12", "Grade 12"]
])

export const GradesArray = Array.from(Grades.values());

export const getGradeKeyFromValue = (searchValue: string): string | undefined => {
	for (let [key, value] of Grades.entries()) {
		if (value === searchValue) {
			return key
		}
	}
	return undefined // Return undefined if the value is not found
}
