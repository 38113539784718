import React, { useEffect, useState } from "react";
import { Box, Heading, Center } from '@chakra-ui/react';
import { Lookup } from "../../models/lookup";
import { SemesterAPI } from "../../api/SemesterAPI";
import { TabRoute, TabRoutes } from "../layout/TabRoutes";
import { useTeachersLookup } from "../../hooks/useTeachersLookup";
import { HomeroomAPI } from "../../api/HomeroomAPI";
import SkeletonTable from "../table/TableSkeleton";
import {HomeroomList} from "../classes/HomeroomList";
import {CourseList} from "../classes/CourseList";
import { ReportsQueue } from "./ReportsQueue";
import LocalStorageService from "../../api/LocalStorageService";
import { UserRole, UserType } from "../../api/UserApi";
import { ReportStudentsView } from "./ReportsStudentView";

export const Reports = React.memo(() => {
    const [semesters, set_semesters] = useState<Lookup[]>([]);
    const [defaultTabIndex, setDefaultTabIndex] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);

    const { teachers } = useTeachersLookup();

    const user = LocalStorageService.getInstance().getUser()!;
    const isAdmin = user.has_role(UserRole.Super_Admin)
    const isTeacher = user.user_type === UserType.Teacher

    const tabs = [
        {
            label: "Homerooms",
            path: "/reports/homerooms",
            element: (
                <HomeroomList
                    teachers={teachers}
                    semesters={semesters}
                    base_url="reports"
                />
            ),
        },
        {
            label: "Courses",
            path: "/reports/courses",
            element: (
                <CourseList
                    teachers={teachers}
                    semesters={semesters}
                    base_url="reports"
                />
            ),
        },
        isAdmin &&
        {
            label: "Student View",
            path: "/reports/student-view",
            element: (
                <ReportStudentsView />
            ),
        },
        isTeacher &&
        {
            label: "Teacher Queue",
            path: "/reports/teacher-queue",
            element: (
                <ReportsQueue teacher_id={user._id} />
            ),
        },
        isAdmin &&
        {
            label: "Admin Queue",
            path: "/reports/admin-queue",
            element: (
                <ReportsQueue />
            ),
        },
    ].filter(Boolean) as TabRoute[];

    useEffect(() => {
        SemesterAPI.semester_list()
            .then((res) => {
                let data = res!.semesters.map((e) => {
                    return {
                        "value": e._id,
                        "label": e.name
                    }
                })
                set_semesters(data)
            })

        HomeroomAPI.homeroom_list(null, null, undefined, false)
            .then((res) => {
                // check if homeroom is empty => redirect to courses page
                if (!res.homerooms_count) setDefaultTabIndex(1) // courses page is in the tab 1
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    return (
        <Box overflowX="auto" overflowY="hidden">
            <Center>
                <Heading size="md" mb="6">
                    Report Cards
                </Heading>
            </Center>

            {
                loading ?
                    <SkeletonTable></SkeletonTable>
                    :
                    <TabRoutes tabs={tabs} defaultTabIndex={defaultTabIndex} />
            }
        </Box>
    );
});
