import { CreateCustomField, CustomField } from "../models/custom_field";
import { CreateCustomFieldEntry, CreateCustomFieldsGroup, CustomFieldEntry, CustomFieldsGroup } from "../models/custom_fields_group";
import { ProfileSection } from "../models/organization";
import { ObjectId } from "../utils/ObjectId";
import HTTPApi from "./ApiCommon";
import { UserType } from "./UserApi";

export namespace CustomFieldsGroupAPI {

    export const get_custom_fields_groups_for_user_type = async (user_type: UserType) => {
        return HTTPApi.get("/custom_fields_group/get_custom_fields_groups_for_user_type/" + user_type)
            .then((res) => {
                return res.data as Array<CustomFieldsGroup>
            })
    }

    export const get_groups_for_user_type_and_profile_section = async (user_type: UserType, profile_section: ProfileSection) => {
        return HTTPApi.get("/custom_fields_group/get_groups_for_user_type_and_profile_section/" + user_type + "/" + profile_section)
            .then((res) => {
                return res.data as Array<CustomFieldsGroup>
            })
    }

    export const get_group_custom_fields = async (group_id: ObjectId) => {
        return HTTPApi.get("/custom_fields_group/get_group_custom_fields/" + group_id.$oid)
            .then((res) => {
                return res.data as Array<CustomField>
            })
    }

    export const get_group_active_custom_fields = async (group_id: ObjectId) => {
        return HTTPApi.get("/custom_fields_group/get_group_active_custom_fields/" + group_id.$oid)
            .then((res) => {
                return res.data as Array<CustomField>
            })
    }

    export const add_custom_field_to_group = async (group_id: ObjectId, body: CreateCustomField) => {
        return HTTPApi.post(
            "/custom_fields_group/add_new_custom_field_to_group/" + group_id.$oid, body
        ).then((res) => {
            return res.data as CustomField
        })
    }

    export const create_new_custom_fields_group = async (body: CreateCustomFieldsGroup) => {
        return HTTPApi.post(
            "/custom_fields_group/create_custom_fields_group", body
        ).then((res) => {
            return res.data as CustomFieldsGroup
        })
    }


    export const assign_entries_to_custom_fields_group = async (userId: ObjectId, groupId: ObjectId, body: CreateCustomFieldEntry[]) => {
        return HTTPApi.post(
            "/custom_fields_entries/update_custom_fields/" + userId.$oid + "/" + groupId.$oid, body
        ).then((res) => {
            return res.data as Array<CustomFieldEntry>
        })
    }
    
    export const update_custom_field = async (fieldId: ObjectId, body: CreateCustomField) => {
        return HTTPApi.post(
            "/custom_fields_group/update_custom_field/" + fieldId.$oid, body
        ).then((res) => {
            return res.data as CustomField
        })
    }
}