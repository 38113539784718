import React from 'react';
import { AsyncSelect } from 'chakra-react-select';
import { HomeroomAPI } from '../../api/HomeroomAPI';
import { ObjectId } from '../../utils/ObjectId';
import { Lookup } from '../../models/lookup';

interface HomeroomLookupProps {
  onSelect: (homeroomId?: ObjectId) => void;
}

export const HomeroomsLookup: React.FC<HomeroomLookupProps> = ({ onSelect }) => {
  const formatOptions = (homerooms: HomeroomAPI.ListHomeroom[]) : Lookup[] => {
    return homerooms.map(homeroom => ({
      value: homeroom._id,
      label: homeroom.name
    }));
  };

  const loadOptions = async (inputValue: string) => {
    if (!inputValue) return [];

    try {
      const homerooms = await HomeroomAPI.homeroom_list(undefined, undefined, inputValue).then(res => res?.homerooms || []);

      return formatOptions(homerooms);
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const handleChange = (selectedOption: Lookup | null) => {
    if (selectedOption) {
      onSelect(selectedOption.value);
    } else {
      onSelect(undefined);
    }
  };

  return (
	<AsyncSelect
		placeholder="Search homeroom by name..."
		loadOptions={loadOptions}
		onChange={handleChange}
		chakraStyles={{
		menu: (provided) => ({ ...provided, zIndex: 9999, boxShadow: 'lg' }),
		}}
	/>
  );
};