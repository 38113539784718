import React, { useEffect, useState } from 'react';
import { AsyncSelect } from 'chakra-react-select';
import { ObjectId } from '../../utils/ObjectId';
import { Lookup } from '../../models/lookup';
import { Family } from '../../models/family';
import { FamilyAPI } from '../../api/FamilyAPI';


interface FamilyLookupProps {
  onSelect: (familyId?: ObjectId) => void;
  family?: Family;
}

const FamilyLookup: React.FC<FamilyLookupProps> = ({ onSelect, family }) => {
  const [selectedOption, setSelectedOption] = useState<Lookup | null>(null);

  const formatOptions = (families: Family[]): Lookup[] => {
    return families.map(family => ({
      value: family._id,
      label: family.name,
    }));
  };

  const loadOptions = async (inputValue: string) => {
    if (!inputValue) return [];

    try {
      const families = await FamilyAPI.get_all_families();
      return formatOptions(families);
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const handleChange = (selectedOption: Lookup | null) => {
    setSelectedOption(selectedOption);
    if (selectedOption) {
      onSelect(selectedOption.value);
    } else {
      onSelect(undefined);
    }
  };

  useEffect(() => {
    if (family) {
      setSelectedOption({
        value: family._id,
        label: family.name,
      });
    }
  }, [family]);

  return (
    <AsyncSelect
      placeholder="Search Family by name..."
      loadOptions={loadOptions}
      value={selectedOption}
      onChange={handleChange}
      chakraStyles={{
        menu: (provided) => ({ ...provided, zIndex: 9999, boxShadow: 'lg' }),
      }}
    />
  );
};

export default FamilyLookup;
