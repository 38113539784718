// src/components/invoices/InvoiceForm.tsx

import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SimpleGrid, Box, Button, FormLabel, Text, HStack, Grid, Flex } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { BlCard } from '../layout/Card';
import { AddIcon } from '@chakra-ui/icons';
import { FiPercent } from 'react-icons/fi';
import { ObjectId } from '../../utils/ObjectId';
import { Coupon, CreateInvoice, getInvoiceUserInfo, Invoice, InvoiceUserCategory, InvoiceUserCategoryArray, InvoiceUserCategoryColor } from '../../models/invoice';
import FormDatePicker from '../forms/FormDatePicker';
import FormInput from '../forms/FormInput';
import FormTextarea from '../forms/FormTextarea';
import FormSwitch from '../forms/FormSwitch';
import { CreateInvoiceSchema } from '../../validations/invoice';
import { useFieldArray, useWatch } from 'react-hook-form';
import { CouponDialog } from './CouponDialog';
import { LightButton } from '../layout/LightButton';
import { RemoveIconButton } from '../layout/RemoveIconButton';
import StudentLookup from '../students/StudentsLookup';
import { InvoicePdfLink } from './InvoicePdfLink';
import { useInvoiceTotals } from '../../hooks/useInvoiceTotals';
import FamilyLookup from '../family/FamilyLooup';
import { Student } from '../../models/student';
import { Family } from '../../models/family';
import { CoursesLookup } from '../classes/CoursesLookup';
import { HomeroomsLookup } from '../classes/HomeroomsLookup';
import { useChipCell } from '../../hooks/useChipCell';

interface InvoiceFormProps {
	invoice?: Invoice;
	user?: Student; 
	family?: Family;
	loading?: boolean;
	onSubmit: (data: CreateInvoice) => void;
}

export const InvoiceForm: React.FC<InvoiceFormProps> = ({ invoice, user, family, loading, onSubmit }) => {
	const editMode = Boolean(invoice)

	const { register, handleSubmit, control, setValue, formState: { errors } } = useForm({
		resolver: yupResolver(CreateInvoiceSchema),
		defaultValues: {
			items: [],
			coupons: [],
			course: null,
			homeroom: null,
			...invoice
		}
	});

	const { fields: items, append, remove } = useFieldArray({
		control,
		name: "items"
	});

	const [dialogOpen, setDialogOpen] = React.useState(false);
	const [userCategory, setUserCategory] = React.useState<InvoiceUserCategory>(InvoiceUserCategory.Student);

	const watchedItems = useWatch({ name: "items", control });
	const watchedCoupon = useWatch({ name: "coupons.0", control });

	const onFormSubmit: SubmitHandler<CreateInvoice | Invoice> = (data) => {
		onSubmit(data);
	};

	const { userName, userCategory: _category } = getInvoiceUserInfo(invoice, user, family);
	
	React.useEffect(() => {
		setUserCategory(_category);
	}, [_category]);

	const categoryChipCell = useChipCell(userCategory, InvoiceUserCategoryColor[userCategory]);


	const { subtotal, discount, discountUnit, grandTotal } = useInvoiceTotals(watchedItems, watchedCoupon)

	const handleOpenCouponDialog = () => {
		setDialogOpen(true);
	};

	const handleCouponSubmit = (couponData?: Coupon) => {
		if (couponData) {
			const { Percentage, Amount } = couponData.coupon_type;

			setValue("coupons.0", {
				title: couponData.title,
				coupon_type: Percentage !== undefined ? { Percentage } : { Amount },
			});
		} else {
			setValue("coupons", []);
		}

		setDialogOpen(false);
	};

	const onUserChange = (user?: ObjectId) => {
		if (user) setValue('user', user);
	}

	const onFamilyChange = (family?: ObjectId) => {
		if (family) setValue('family', family);
	}

	const onCourseChange = (course?: ObjectId) => {
		if (course) setValue("course", course)
	}

	const onHomeroomChange = (homeroom?: ObjectId) => {
		if (homeroom) setValue("homeroom", homeroom)
	}
	
	const handleUserCategoryChange = (category: InvoiceUserCategory) => {
		setUserCategory(category);

		// reset values
		setValue('user', null);
		setValue('family', null);
		setValue('course', null);
		setValue('homeroom', null);
	};

	return (
		<>
			<form onSubmit={handleSubmit(onFormSubmit)}>
				<SimpleGrid columns={12} spacing={6}>
					{/* General Information */}
					<Box as="aside" gridColumn="span 4">
						<BlCard h="full">
							<FormLabel fontSize="lg" fontWeight="bold" mb={4}>
								General Information
							</FormLabel>

							<FormInput
								name="title"
								label="Invoice Title"
								register={register("title")}
								error={errors.title?.message}
								isRequired
							/>

							<FormTextarea
								name="description"
								label="Invoice Description"
								register={register("description")}
								error={errors.description?.message}
								isRequired
							/>

							{ !editMode && 
							<Box mt={4}>
								<FormLabel className="required">Create Invoice for</FormLabel>
								<Select
									options={InvoiceUserCategoryArray}
									onChange={(e) => handleUserCategoryChange(e?.value as InvoiceUserCategory)}
								/>
							</Box>
							}

							{ editMode ? (
								<Box mb={4}>
									<Text fontWeight="semibold">Bill to</Text>
									<HStack>
										<Text>{userName}</Text>

										{categoryChipCell}
									</HStack>
								</Box> 
							) : (
								<Box mt={4}>
									<FormLabel className="required">{userCategory}</FormLabel>
									{userCategory === InvoiceUserCategory.Student && (
										<StudentLookup onSelect={onUserChange} student={user}/>
									)}
									{userCategory === InvoiceUserCategory.Family && (
										<FamilyLookup onSelect={onFamilyChange} family={family}/>
									)}
									{userCategory === InvoiceUserCategory.Course && (
										<CoursesLookup onSelect={onCourseChange} />
									)}
									{userCategory === InvoiceUserCategory.Homeroom && (
										<HomeroomsLookup onSelect={onHomeroomChange} />
									)}

									{errors.user && <Text color="red.500">{errors.user.message}</Text>}
								</Box>
							)
							}

							<FormDatePicker
								name="due_date"
								label="Due Date"
								control={control}
								error={errors.due_date?.message}
							/>

							<SimpleGrid columns={2}>
								<Flex align="center" gap="4">
									<FormSwitch
										name="show_hst" 
										control={control}
									/>

									<FormLabel>Show HST</FormLabel>
								</Flex>

								<Flex align="center" gap="4">
									<FormSwitch
										name="disable_tax" 
										control={control}
									/>

									<FormLabel>Diasble Tax</FormLabel>
								</Flex>
							</SimpleGrid>
						</BlCard>
					</Box>

					{/* Invoice Items */}
					<Box as="main" gridColumn="span 8">
						<BlCard>
							<FormLabel fontSize="lg" fontWeight="bold" mb={4}>
								Invoice Items
							</FormLabel>

							<Box borderBottom="1px solid" borderColor="gray.200" pb={2} mb={4}>
								<HStack spacing={4} fontSize="md">
									<Box flex="2" fontWeight="medium" className="required">
										Title
									</Box>
									<Box flex="4" fontWeight="medium" className="required">
										Description
									</Box>
									<Box flex="1" fontWeight="medium" className="required">
										Quantity
									</Box>
									<Box flex="1" fontWeight="medium" className="required">
										Item Price
									</Box>
									<Box flex="0.5" />
								</HStack>
							</Box>

							{items.map((field, index) => (
								<Box key={field.id} mb={4} borderBottom="1px solid" borderColor="gray.200" pb={4}>
									<HStack spacing={4} fontSize="md">
										<Box flex="2">
											<FormInput
												name={`items.${index}.title`}
												label=""
												register={register(`items.${index}.title` as const)}
												error={errors.items?.[index]?.title?.message}
												placeholder="Item Title"
												isRequired
											/>
										</Box>

										<Box flex="4">
											<FormInput
												name={`items.${index}.description`}
												label=""
												register={register(`items.${index}.description` as const)}
												error={errors.items?.[index]?.description?.message}
												placeholder="Item Description"
												isRequired
											/>
										</Box>

										<Box flex="1">
											<FormInput
												name={`items.${index}.quantity`}
												label=""
												register={register(`items.${index}.quantity` as const, { valueAsNumber: true })}
												error={errors.items?.[index]?.quantity?.message}
												placeholder="Quantity"
												isRequired
											/>
										</Box>

										<Box flex="1">
											<FormInput
												name={`items.${index}.price`}
												label=""
												register={register(`items.${index}.price` as const, { valueAsNumber: true })}
												error={errors.items?.[index]?.price?.message}
												placeholder="Price"
												isRequired
											/>
										</Box>

										<Box flex="0.5" display="flex" justifyContent="flex-end">
											<RemoveIconButton
												onClick={() => remove(index)}
												aria-label="Remove item"
											/>
										</Box>
									</HStack>
								</Box>
							))}

							<Button
								aria-label="Add Item"
								leftIcon={<AddIcon />}
								variant="outline"
								border="1px dashed"
								borderColor="gray.400"
								size="sm"
								onClick={() => append({ title: "", description: "", price: 0, quantity: 0 })}
								mb={6}
							>
								Add Item
							</Button>

							{/* Totals Section */}
							<Box mt={4}>
								<BlCard bgColor="gray.200" boxShadow="none">
									<Grid templateColumns="1fr" rowGap={3} ml={{ lg: "50%" }}>
										<HStack justify="space-between" mb={2}>
											<Text>Subtotal</Text>
											<Text fontWeight="medium">${subtotal.toFixed(2)}</Text>
										</HStack>

										<HStack justify="space-between" mb={2}>
											<Text>Discount {discountUnit && `(${discountUnit})`}</Text>
											<Text fontWeight="medium" color={discount > 0 ? "green.600" : "black"}>
												${discount.toFixed(2)}
											</Text>
										</HStack>

										<HStack justify="space-between" mb={2}>
											<Text fontWeight="bold" color="blue.600">Grand Total</Text>
											<Text fontWeight="bold" color="blue.600">${grandTotal.toFixed(2)}</Text>
										</HStack>
									</Grid>
								</BlCard>
							</Box>

							<HStack mt={4} justifyContent="flex-end">
								<LightButton
									color="purple"
									leftIcon={<FiPercent />}
									onClick={handleOpenCouponDialog}
								>
									{watchedCoupon ? "Edit Coupon" : "Add Coupon"}
								</LightButton>

								{ editMode && invoice && <InvoicePdfLink invoice={invoice} userName={userName} />}

								<Button colorScheme="blue" type="submit" isLoading={loading}>
									{editMode ? 'Update' : 'Create'} Invoice
								</Button>
							</HStack>
						</BlCard>
					</Box>
				</SimpleGrid>
			</form>

			{dialogOpen && (
				<CouponDialog
					isOpen={dialogOpen}
					onClose={() => setDialogOpen(false)}
					onSubmit={handleCouponSubmit}
					initialData={watchedCoupon}
				/>
			)}
		</>
	);
};