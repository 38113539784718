import { Parent } from "../models/parent";
import { Student } from "../models/student";
import { ObjectId } from "../utils/ObjectId";
import HTTPApi from "./ApiCommon";



export namespace ParentAPI {
    export const enrolled_students = async () => {
        return HTTPApi.get("/parents/enrolled_students").then((res) => {
            return res.data as Student[]
        })
    }

    export const get_parent = async (parent_id: ObjectId) => {
        return HTTPApi.get("/parents/get/" + parent_id.$oid).then((res) => {
            return res.data as Parent
        })
    }

    export const new_parent = async (email: string, name: string) => {
        return HTTPApi.post(
            "parents/new_parent", {
                email: email,
                name: name
            }
        ).then((res) => {
            return res.data as Parent
        })
    }

    export const update_parent = async (_id: ObjectId, parent: Parent) => {
        return HTTPApi.post(
            "parents/update_parent", {
                _id: _id,
                parent: parent
            }
        ).then((res) => {
            return res.data as Parent
        })
    }
}