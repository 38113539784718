import { Alert, AlertIcon, Box, Button, Divider, Flex, FormControl, FormLabel, Grid, Input, Radio, RadioGroup, Skeleton, Stack, Switch, Text, Textarea, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CustomFieldEntryAPI } from '../../api/CustomFIeldEntryAPI';
import { CustomFieldsGroupAPI } from '../../api/CustomFieldsGroupAPI';
import { CustomField, CustomFieldType } from '../../models/custom_field';
import { CustomFieldsGroup } from '../../models/custom_fields_group';
import { ObjectId, to_oid } from '../../utils/ObjectId';
import DatePicker from '../DatePicker';


type EntryValue = string | string[] | boolean | Date;

export const CustomFieldsGroupDisplay: React.FC<{ group: CustomFieldsGroup, userId: ObjectId }> = ({ group, userId }) => {

    const [fields, setFields] = useState<CustomField[]>();
    const [fieldValues, setFieldValues] = useState<Map<string, EntryValue>>(new Map());
    const [missingRequiredFields, setMissingRequiredFields] = useState<boolean>(false);
    const [havePermissionToView, setHavePermissionToView] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);

    const toast = useToast();

    const fetchFields = async () => {
        setLoading(true);

        try {
            const fetchedActiveFields = await CustomFieldsGroupAPI.get_group_active_custom_fields(group._id); 
            setFields(fetchedActiveFields);
        } catch (error: any) {
            toast({
                title: 'Error',
                description: error.response?.data || 'Failed to load fields',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    }

    const fetchEntries = async () => {
        try {
            const fetchedEntries = await CustomFieldEntryAPI.get_user_active_entries_for_group(userId, group._id);
            const newMap = new Map<string, EntryValue>();
            fetchedEntries.forEach((entry) => {
                const field = fields!.find((f) => f._id.$oid == entry.custom_field_id.$oid)!;
                const deserializedValue = handleValueDeserialization(entry.value, field!.field_type);
                newMap.set(entry.custom_field_id.$oid, deserializedValue);  
            })
            
            setFieldValues(newMap);
            setMissingRequiredFields(fields!.some(field => field.is_required && !newMap.has(field._id.$oid)));               
        } catch (error: any) {
            if (error.response?.status === 403) { 
                setHavePermissionToView(false);
            } else {
                toast({
                    title: 'Error',
                    description: error.response?.data || 'Failed to load entries',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } finally {
            setLoading(false);
        }
    }


    const submitEntries = async () => {
        setLoading(true);
        try {

            fields?.forEach((field) => {
                if (field.field_type === CustomFieldType.CheckBox && !fieldValues.has(field._id.$oid)) {
                    fieldValues.set(field._id.$oid, false);
                }
            });

            const payload = Array.from(fieldValues.entries())
                .filter(([_, entryValue]) => entryValue !== "")
                .map(([fieldId, entryValue]) => {
                    const field = fields!.find((f) => f._id.$oid === fieldId)!;
                    return {
                        custom_field_id: to_oid(fieldId),
                        value: handleValueSerialization(entryValue, field.field_type),
                    }
                });

            

            const new_entries = await CustomFieldsGroupAPI.assign_entries_to_custom_fields_group(userId, group._id, payload);

            const updatedFieldValues = new Map(fieldValues);

            new_entries.forEach((entry) => {
                const field = fields?.find((f) => f._id.$oid === entry.custom_field_id.$oid);
                const deserializedValue = handleValueDeserialization(entry.value, field!.field_type);
                updatedFieldValues.set(entry.custom_field_id.$oid, deserializedValue);
            });

            setFieldValues(updatedFieldValues);
            setMissingRequiredFields(false);

            toast({
                title: 'Success',
                description: `Entries saved successfully for group: ${group.group_name}`,
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
        } catch (error: any) {
            const errorMessage = error.response?.data || `Failed to save entries for group: ${group.group_name}`;
            toast({
                title: 'Error',
                description: errorMessage,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setLoading(false);
        }
    };

    const handleValueSerialization = (value: EntryValue, fieldType: CustomFieldType) => {
        if (fieldType === CustomFieldType.Select || fieldType === CustomFieldType.CheckBox) {
            value = JSON.stringify(value);
        }
        return value as string;
    };
    
    const handleValueDeserialization = (value: string, fieldType: CustomFieldType) => {
        if (fieldType === CustomFieldType.Date) {
            return new Date(value);
        } else if (fieldType === CustomFieldType.Select || fieldType === CustomFieldType.CheckBox) {
            return JSON.parse(value) as EntryValue;
        }
        return value as EntryValue;
    };

    useEffect(() => {
        fetchFields();
    }, []);

    useEffect(() => {
        if(fields)
            fetchEntries();
    }, [fields]);

    const handleFieldChange = (fieldId: ObjectId, newValue: EntryValue) => {
        setFieldValues((prevValues) => {
            const updatedValues = new Map(prevValues);
            updatedValues.set(fieldId.$oid, newValue);
            return updatedValues;
        });
    };

    const renderFieldInput = (field: CustomField) => {
        const value = fieldValues.get(field._id.$oid);

        const renderStringField = (value: string) => (
            <Textarea value={value} onChange={(e) => handleFieldChange(field._id, e.target.value)} />
        )

        const renderSelectField = (value: string[]) => {
            const selectedValue = value && value.length > 0 ? value[0] : '';

            return (
                <RadioGroup
                    onChange={(e) => {
                        const selectedArray = [e];
                        handleFieldChange(field._id, selectedArray);
                    }}
                    value={selectedValue}
                >
                    <Stack>
                        {field.options?.map((option, index) => (
                            <Radio key={index} value={option}>
                                {option}
                            </Radio>
                        ))}
                    </Stack>
                </RadioGroup>
            );
        };

        const renderAddressField = (value: string) => (
            <Input value={value} onChange={(e) => handleFieldChange(field._id, e.target.value)} />
        );

        const renderDateField = (value: Date) => (
            <DatePicker
                selectedDate={value}
                onChange={(date) => {
                    if (date instanceof Date) {
                        handleFieldChange(field._id, date);
                    } else {
                        console.error('Invalid date value:', date);
                    }
                }}
            />
        );

        const renderRegexField = (value: string) => (
            <Input value={value} onChange={(e) => handleFieldChange(field._id, e.target.value)} />
        );

        const renderCheckboxField = (value: boolean) => (
            <Switch
                isChecked={value}
                onChange={(e) => handleFieldChange(field._id, e.target.checked)}
            />
        );

        switch (field.field_type) {
            case CustomFieldType.String:
                return renderStringField(value as string);
            case CustomFieldType.Select:
                return renderSelectField(value as string[]);
            case CustomFieldType.Address:
                return renderAddressField(value as string);
            case CustomFieldType.Date:
                return renderDateField(value as Date);
            case CustomFieldType.RegexValidated:
                return renderRegexField(value as string);
            case CustomFieldType.CheckBox:
                return renderCheckboxField(value as boolean);
        }
    };
          
    // Do not display group if it has no fields
    if(!fields || fields.length == 0) {
        return null;
    }

    return (
        <Box
            as="form"
            bg={missingRequiredFields ? 'red.50' : 'bg-surface'}
            border="2px solid"
            borderColor={missingRequiredFields ? 'red.500' : 'transparent'}
            boxShadow="sm"
            borderRadius="lg"
            flex="1"
            p={{ base: '4', md: '6' }}
        >
            <FormLabel fontSize="2xl" fontWeight="bold" mb={4}>
                {group.group_name}
            </FormLabel>

            {group.hints && group.hints.length > 0 && (
                <Box mb={6}>
                    {group.hints.map((hint, index) => (
                        <Box mb={2} key={index}>
                            <Alert status="info" variant="subtle">
                                <AlertIcon />
                                <Text>{hint}</Text>
                            </Alert>
                        </Box>
                    ))}
                </Box>
            )}

            {loading ? (
                <Stack gap="4">
                    <Skeleton height="100px" />
                    <Skeleton height="100px" />
                    <Skeleton height="100px" />
                </Stack>
            ) : (
                <Grid
                    templateColumns="repeat(auto-fill, minmax(280px, 1fr))"
                    gap={6}
                    mb={6}
                >
                    {fields.map((field) => 
                        <FormControl isRequired={field.is_required} key={field._id.$oid}>
                            <FormLabel fontSize="lg" fontWeight="bold">
                                {field.name}
                            </FormLabel>
                            {field.description && (
                                <Box mb={2} color="gray.500">
                                    {field.description}
                                </Box>
                            )}
                            {havePermissionToView ? renderFieldInput(field) : <Input isDisabled placeholder="******" opacity={0} />}
                        </FormControl>
                    )}
                </Grid>
            )}

            <Divider mt={6} mb={4} />
            {havePermissionToView 
            ? 
            <Flex direction="row-reverse">
                <Button onClick={submitEntries} isLoading={loading}>
                    Save
                </Button>
            </Flex>
            :
            <Text fontSize="lg" color="red.500" textAlign="center" mt={4}>
                You do not have the necessary permissions to view the values of the fields in the group "
                {group.group_name}". Please contact your administrator if you believe this is a mistake.
            </Text>
            }
        </Box>
    );
};