import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Spinner, useToast, Heading, FormLabel, Button, HStack } from '@chakra-ui/react';
import { InvoiceForm } from './InvoiceForm';
import { CreateInvoice, InvoiceResponse } from '../../models/invoice';
import { InvoiceAPI } from '../../api/InvoiceAPI';
import { to_oid } from '../../utils/ObjectId';
import { BlCard } from '../layout/Card';
import { TableContent } from '../table/TableContent';
import { Item, PaymentStatus, PaymentStatusColor } from '../../models/transaction';
import { useChipCell } from '../../hooks/useChipCell';
import { DateTime } from '../../utils/DateTime';
import { StudentAPI } from '../../api/StudentAPI';
import { FiArchive } from 'react-icons/fi';
import { FamilyAPI } from '../../api/FamilyAPI';
import { Student } from '../../models/student';
import { Family } from '../../models/family';

const InvoiceDetails: React.FC = () => {
  const { invoice_id } = useParams<{ invoice_id: string }>();
  const navigate = useNavigate();
  const toast = useToast();

  const [invoiceRes, setInvoiceRes] = useState<InvoiceResponse>();
  const [user, setUser] = useState<Student>();
  const [family, setFamily] = useState<Family>();
  const [submitLoading, setSubmitLoading] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>();

  const transactionsColumns = [
		{
			Header: "Date",
			accessor: "date",
			Cell: (data: DateTime) => <>{data.to_date().toLocaleDateString()}</>
		},
		{
			Header: "Status",
			accessor: "status",
			Cell: (data: PaymentStatus) => useChipCell(data, PaymentStatusColor[data])
		},
		{
			Header: "Items Count",
			accessor: "items",
			Cell: (items: Item[]) => useChipCell(items.length ? `${items.length} Items` : 'No Items', "gray")
		},
	];

  useEffect(() => {
    const fetchInvoice = async () => {
      if (!invoice_id) return;

	  setLoading(true);

      try {
        const response = await InvoiceAPI.get(to_oid(invoice_id));
        setInvoiceRes(response);

        if(response.invoice.user) {
          await StudentAPI.get_student(response.invoice.user.$oid)
          .then(res => {
            if (res) 
              setUser(res);
          })
        } else if(response.invoice.family) {
          await FamilyAPI.get_family(response.invoice.family).then(res => setFamily(res))
        }

      } catch (err) {
        console.error('Error fetching invoice:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchInvoice();
  }, [invoice_id]);

  const handleUpdate = async (data: CreateInvoice) => {
    setSubmitLoading(true)

    try {
      await InvoiceAPI.update(data);
      toast({
        title: 'Success',
        description: 'Invoice updated successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      navigate('/invoices')
    } catch (error) {
        console.error('Error fetching invoice:', error);

      toast({
        title: 'Error',
        description: 'Failed to update invoice.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }

    setSubmitLoading(false)
  };

  const archiveInvoice = async () => {
    if (!invoiceRes?.invoice?._id) return

    setLoading(true)

		try {
			await InvoiceAPI.archive(invoiceRes.invoice._id)

			toast({
				title: 'Success',	
				description: "Invoice Archived Successfully",
				status: 'success',
				duration: 5000,
				isClosable: true,
			})

      navigate('/invoices')

		} catch (error) { }
		
		setLoading(false)
	}

  if (loading) {
    return (
      <Box textAlign="center" mt="20">
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <>
		  <Heading size="md" mb={6} textAlign="center">
        	Invoice Information
      </Heading>

    <InvoiceForm invoice={invoiceRes?.invoice} onSubmit={handleUpdate} user={user} family={family} loading={submitLoading}/>

	  <BlCard mt={6}>
      <FormLabel fontSize={"lg"} fontWeight="bold">
        Transactions
      </FormLabel>

      <TableContent columns={transactionsColumns} data={invoiceRes?.transactions} />
    </BlCard>

    <HStack justify={"end"} mt={4}>
      <Button
        bgColor='yellow.50'
        textColor='yellow.600'
        _hover={{ bg: 'yellow.100' }}
        leftIcon={<FiArchive />}
        onClick={archiveInvoice}
      >
          Archive
      </Button>
    </HStack>
    </>
  );
};

export default InvoiceDetails;
