import {
Box,
FormControl,
FormLabel,
Input,
Stack,
useColorModeValue,
InputGroup,
InputLeftAddon,
HStack,
} from '@chakra-ui/react'
import * as React from 'react'
import 'react-datepicker/dist/react-datepicker.css';
import { Parent } from '../../../models/parent';
import { ProfileSectionCustomFieldsGroups } from '../../custom_fields/ProfileSectionCustomFieldsGroups';
import { ProfileSection } from '../../../models/organization';
import { UserType } from '../../../api/UserApi';

interface Card {
    guardian: Parent
    save_guardian: (guardian: Parent) => void
    save_on_edit: boolean
    read_only?: boolean 
    profile_sections: ProfileSection[];
}
    
export const FamilyGuardianCard = React.memo(({guardian, save_guardian, save_on_edit, read_only, profile_sections}: Card) => {
    const [guardian_state, set_guardian_state] = React.useState({...guardian})

    const update_state = (name: any, value: any) => {
        set_guardian_state({
            ...guardian_state,
            [name]: value
        });
        if(save_on_edit) {
            save_guardian({
                ...guardian_state,
                [name]: value
            });
        }
    }

    return (
        <>
        <Box
        as="form"
        bg="bg-surface"
        boxShadow={useColorModeValue('sm', 'sm-dark')}
        borderRadius="lg"
        flex="1"
    >
        <Stack
            spacing="5" 
            px={{ base: '4', md: '6' }} 
            py={{ base: '5', md: '6' }} 
            border="1px solid" 
            borderColor="gray.200" 
            borderRadius="md" 
            boxShadow="md" 
            bg="white"
        >
            <HStack>
                <FormControl id="name" isRequired isDisabled={read_only}>
                    <FormLabel>Full Name</FormLabel>
                    <Input 
                        value={guardian_state.name} 
                        onChange={(value) => {
                            update_state(value.target.id, value.target.value)
                        }}
                    />
                </FormControl>
      
                <FormControl id="email" isRequired={true} isDisabled={read_only}>
                    <FormLabel>Email</FormLabel>
                    <Input 
                        value={guardian_state.email} 
                        onChange={(value) => {
                            update_state(value.target.id, value.target.value)
                        }}
                    />
                </FormControl>
                <FormControl id="phone" isRequired isDisabled={read_only}>
                    <FormLabel>Primary Phone</FormLabel>
                    <InputGroup>
                        <InputLeftAddon children='+1' />
                        <Input 
                            value={guardian_state.phone} 
                            onChange={(value) => {
                                update_state(value.target.id, value.target.value)
                            }}
                        />
                    </InputGroup>
                </FormControl>
            </HStack>
            
            {profile_sections.map((section) => (
                <ProfileSectionCustomFieldsGroups
                    userType={UserType.Parent}
                    profileSection={section}
                    userId={guardian._id}
                />
            ))}
        </Stack>
    </Box>
        </>
    )
})