import { CreateCustomField, CustomField } from "../models/custom_field";
import { CreateCustomFieldsGroup, CustomFieldEntry, CustomFieldsGroup } from "../models/custom_fields_group";
import { ObjectId } from "../utils/ObjectId";
import HTTPApi from "./ApiCommon";
import { UserType } from "./UserApi";

export namespace CustomFieldEntryAPI {

    export const get_user_entries_for_group = async (userId: ObjectId, groupId: ObjectId) => {
        return HTTPApi.get("/custom_fields_entries/get_group_entries_for_user/" + userId.$oid + "/" + groupId.$oid)
            .then((res) => {
                return res.data as Array<CustomFieldEntry>;
            })
    }

    export const get_user_active_entries_for_group = async (userId: ObjectId, groupId: ObjectId) => {
        return HTTPApi.get("/custom_fields_entries/get_group_active_entries_for_user/" + userId.$oid + "/" + groupId.$oid)
            .then((res) => {
                return res.data as Array<CustomFieldEntry>;
            })
    }
}