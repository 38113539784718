import React, { useState } from 'react';
import { Button } from '@chakra-ui/react';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { ObjectId } from '../../utils/ObjectId';
import { StudentAPI } from '../../api/StudentAPI';
import { HomeroomAPI } from '../../api/HomeroomAPI';
import { ReportAPI } from '../../api/ReportAPI';
import { ReportCardPdf } from './ReportCardPdf';
import { ReportType } from 'protobuffer-ts/dist/class_service/semester';
import { ReportEntry } from '../../models/report_entry';
import { Student } from '../../models/student';
import { TeacherAPI } from '../../api/TeacherAPI';
import { proto_utils } from '../../utils/proto_utils';
import { AttendanceAPI } from '../../api/AttendanceAPI';
import { AiOutlineFilePdf } from 'react-icons/ai';

interface PdfGeneratorProps {
  student_id: ObjectId;
  homeroom_id: ObjectId;
  report_type: ReportType;
}

export const PdfGenerator: React.FC<PdfGeneratorProps> = ({ student_id, homeroom_id, report_type }) => {
  const [loading, setLoading] = useState(false);

  const generatePdf = async () => {
    setLoading(true);
  
    try {
      const [student, homeroom, courses] = await Promise.all([
        StudentAPI.get_student(student_id.$oid),
        HomeroomAPI.get_homeroom(homeroom_id),
        HomeroomAPI.get_courses(homeroom_id),
      ]);
  
      // Fetch all reports for the student in the homeroom
      const reports = await ReportAPI.get_student_report_entries_for_homeroom(student_id, homeroom_id) as ReportEntry[];

      // Extract specific report type from homeroom reports
      const selectedReport = reports.find((r) => r.report_type === report_type);
      if (!selectedReport) {
        throw new Error(`Report type ${report_type} not found for student ${student_id.$oid}`);
      }
  
      const teacherIds = proto_utils.from_proto_object_id_arr(homeroom.teacherIds);
  
      const totalAttendanceCountPayload = {
        homeroom_id,
        student_id,
        start_date: homeroom.semester!.startDate!.toISOString(),
        end_date: homeroom.semester!.endDate!.toISOString(),
      };
  
      const selectedReportEndDate = selectedReport.semester_report_layout.reportDates
        .find((r) => r.reportType === report_type)!
        .distributionDate!.toISOString();
  
      const attendanceCountPayload = {
        ...totalAttendanceCountPayload,
        end_date: selectedReportEndDate,
      };
  
      const [teachers, total_days_count, days_count] = await Promise.all([
        TeacherAPI.teachers_by_ids(teacherIds)
          .then(res => res
            .slice(0, 2) // Get only the first two teachers
            .map(teacher => `${teacher.first_name} ${teacher.last_name}`)
            .join(', ') + (res.length > 2 ? ', and others' : '') // Add "and others" if there are more than 2
          )
          .catch(() => ""), // don't add teacher name if user don't have access to teacher API
        AttendanceAPI.get_student_homeroom_attendance_count(totalAttendanceCountPayload),
        AttendanceAPI.get_student_homeroom_attendance_count(attendanceCountPayload),
      ]);
  
      const coursePromises = courses.map((course) =>
        ReportAPI.get_student_report_entries_for_course(student_id, proto_utils.from_proto_object_id(course.id!))
      );
  
      const coursesReports = (await Promise.all(coursePromises)).map((res: ReportEntry[]) =>
        res.find((r) => r.report_type === report_type)!
      );
  
      // Generate the PDF document
      const document = (
        <ReportCardPdf
          student={student!}
          homeroom={homeroom!}
          courses={courses}
          teachers={teachers}
          report={selectedReport}
          coursesReports={coursesReports.filter(Boolean)}
          days={{ days_count, total_days_count }}
        />
      );
  
      const asBlob = await pdf(document).toBlob();
      saveAs(asBlob, `${Student.getName(student!)} ${homeroom.name} Report.pdf`);
    } catch (error) {
      console.error('Error generating PDF:', error);
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <Button colorScheme="teal" onClick={generatePdf} isLoading={loading} leftIcon={<AiOutlineFilePdf />}>
      {loading ? 'Generating PDF...' : `Export PDF`}
    </Button>
  );
};
