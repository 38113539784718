import { Box, Center, FormControl, FormLabel, Heading, Input, InputLeftAddon, Stack, Text, useColorModeValue, SimpleGrid, Flex, Badge, Select, InputGroup, useToast, Divider, Button, Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverCloseButton, PopoverHeader, PopoverBody, Checkbox, HStack, Spacer, useDisclosure, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter, Tabs, TabList, Tab, TabPanels, TabPanel } from "@chakra-ui/react";
import React, {RefObject, useEffect} from "react";
import { FiAlertTriangle, FiArrowDown } from "react-icons/fi";
import { TeacherAPI } from "../../api/TeacherAPI";
import { Teacher } from "../../models/teacher";
import { DateTime } from "../../utils/DateTime";
import { ObjectId } from "../../utils/ObjectId";
import DatePicker from "../DatePicker";
import { TeacherList } from "./TeacherList";
import {FocusableElement } from "@chakra-ui/utils"
import { TeacherRoles } from "./TeacherRoles";
import { UserRole } from "../../api/UserApi";
import LocalStorageService from "../../api/LocalStorageService";



interface Card {
    teacher: Teacher,
    set_teacher: React.Dispatch<React.SetStateAction<Teacher | undefined>> | React.Dispatch<React.SetStateAction<Teacher>>
    show_save?: boolean
    show_overview?: boolean
}

export const TeacherProfile = ({teacher, set_teacher, show_save = true, show_overview = true} : Card) =>  {
    const toast = useToast()

    const required_info = () => {
        return teacher.first_name != "" && 
            teacher.last_name != "" && 
            teacher.gender != "" && 
            teacher.phone_number != "" &&
            teacher.personal_email != ""
    }

    const update_profile_state = (name: any, value: any) => {
        set_teacher(
            (prevState: any) => (prevState ? {
                ...prevState,
                [name]: value,
            } : undefined)
        );
    }

    const update_profile = () => {
        if(required_info()){
            TeacherAPI.update_profile(teacher._id, teacher)
                .then((res) => {
                    TeacherAPI.get_teacher(teacher._id).then(res => set_teacher(res))
                    toast({
                        title: 'Success',
                        description: "Saved Profile",
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                    })
                })
                .catch((err) => {
                    console.log(err)
                    toast({
                        title: 'Error.',
                        description: "Failed to update profile",
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    })
                })
        }
        else {
            toast({
                title: 'Error.',
                description: "Please fill in all required fields.",
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        }
        
    }

    return (
        <Stack>
        <Tabs>
        <TabList>
            <Tab>Profile</Tab>
            <Tab>Roles</Tab>
            {LocalStorageService.getInstance().getUser()?.has_role(UserRole.Can_Access_Scheduling) &&
            <Tab>Schedule</Tab>

            }
        </TabList>
        <TabPanels>
        <TabPanel>
            <Box
                as="form"
                bg="bg-surface"
                boxShadow={useColorModeValue('sm', 'sm-dark')}
                borderRadius="lg"
                flex="1"
            >
                <Stack spacing="5" px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
                    {show_overview &&
                        <SimpleGrid columns={4} spacing={10}>
                            <Text fontWeight="bold">Status:</Text>
                            <Flex>
                                <Badge colorScheme={badgeEnum[teacher.status]}>
                                    {teacher.status}
                                </Badge>
                            </Flex>

                            {/* <Text fontWeight="bold">Admin:</Text>
                            <Text>{teacher.has_admin ? "Yes" : "No"}</Text> */}

                            <Text fontWeight="bold">Full Name:</Text>
                            <Text>{teacher.first_name} {teacher.last_name}</Text>

                            <Text fontWeight="bold">Username:</Text>
                            <Text>{teacher.username}</Text>
                        </SimpleGrid>
                    }
                    <br></br>
                    <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                        <FormControl id="first_name" isRequired>
                            <FormLabel>First Name</FormLabel>
                            <Input 
                                value={teacher.first_name} 
                                onChange={(value) => {
                                    update_profile_state(value.target.id, value.target.value)
                                }}
                            />
                        </FormControl>
                        <FormControl id="last_name" isRequired>
                            <FormLabel>Last Name</FormLabel>
                            <Input 
                                value={teacher.last_name}
                                onChange={(value) => {
                                    update_profile_state(value.target.id, value.target.value)
                                }}
                            />
                        </FormControl>
                        <FormControl id="gender" isRequired>
                            <FormLabel>Gender</FormLabel>
                            <Select 
                                value={teacher.gender}
                                onChange={(value) => {
                                    update_profile_state(value.target.id, value.target.value)
                                }}
                            >
                                    <option value='F'>Female</option>
                                    <option value='M'>Male</option>
                            </Select>
                        </FormControl>
                    </Stack>
                    <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                        <FormControl id="phone_number" isRequired>
                            <FormLabel>Phone Number</FormLabel>
                            <InputGroup>
                                <InputLeftAddon children='+1' />
                                <Input 
                                    value={teacher.phone_number} 
                                    onChange={(value) => {
                                        update_profile_state(value.target.id, value.target.value)
                                    }}
                                />
                            </InputGroup>
                        </FormControl>
                        
                        <FormControl id="date_of_birth" isRequired>
                            <FormLabel>Date of Birth</FormLabel>
                            <DatePicker 
                                selectedDate={new DateTime(teacher.date_of_birth).to_date()} 
                                onChange={(value) => {
                                    update_profile_state("date_of_birth", DateTime.from_date(value as Date))
                                }}
                            />
                        </FormControl>
                        <FormControl id="personal_email" isRequired>
                            <FormLabel>Personal Email</FormLabel>
                            <Input
                                value={teacher.personal_email}
                                type='email'
                                onChange={(value) => {
                                    update_profile_state(value.target.id, value.target.value)
                                }}
                            />
                        </FormControl>

                    </Stack>
                </Stack>
                {show_save &&
                <>
                    <Divider />
                    <Flex direction="row-reverse" py="4" px={{ base: '4', md: '6' }}>
                        <Button onClick={update_profile}>
                            Save
                        </Button>
                    </Flex>
                </>
                }
            </Box>
        </TabPanel>
        <TabPanel>
            <TeacherRoles teacher={teacher} set_teacher={set_teacher}/>
        </TabPanel>
        </TabPanels>
        </Tabs>
        </Stack>
    )

}

const badgeEnum: Record<string, string> = {
    "Active": "green",
    "Inactive": "red",
}