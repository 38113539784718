import {
Box,
Button,
Divider,
Flex,
FormControl,
FormLabel,
Input,
Stack,
Select,
useColorModeValue,
useToast,
HStack,
Spacer,
Text,
AlertDialog,
AlertDialogOverlay,
AlertDialogContent,
AlertDialogHeader,
AlertDialogBody,
Skeleton,
AlertDialogFooter,
} from '@chakra-ui/react'
import * as React from 'react'
import 'react-datepicker/dist/react-datepicker.css';
import { FiPlus } from 'react-icons/fi';
import LocalStorageService from '../../../api/LocalStorageService';
import { ParentAPI } from '../../../api/ParentAPI';
import { Family, FamilyContact } from '../../../models/family';
import { Parent } from '../../../models/parent';
import { Student } from '../../../models/student';
import { FamilyContactCard } from './FamilyContactCard';
import { FamilyGuardianCard } from './FamilyGuardianCard';
import * as EmailValidator from 'email-validator';
import { to_oid } from '../../../utils/ObjectId';
import { OrganizationProfileSettings, ProfileSection } from '../../../models/organization';
import { OrganizationAPI } from '../../../api/OrganizationAPI';


interface Card {
    family: Family
    save_family?: (family: Family) => void
    save_on_edit?: boolean
    read_only?: boolean
}

export const FamilyCard = ({family, save_family, save_on_edit, read_only = false}: Card) => {
    const toast = useToast()

    const [isOpen, setIsOpen] = React.useState(false)
    const onClose = () => setIsOpen(false)
    const cancelRef = React.useRef()

    const [family_state, set_family_state] = React.useState({...family})
    const [parents, set_parents] = React.useState<Parent[]>([])
    const [parentProfileSections, setParentProfileSections] = React.useState<ProfileSection[]>();
    const [loading, set_loading] = React.useState<boolean>(false);

    const update_state = (name: any, value: any) => {
        set_family_state({
            ...family_state,
            [name]: value
        });
        if(save_on_edit && save_family) {
            save_family({
                ...family_state,
                [name]: value
            });
        }
    }

    const save_guardian = (guardian: Parent) => {
        let idx = parents.findIndex((e) => e._id.$oid == guardian._id.$oid)
        let current_guardian = parents[idx]

        // we do not want to change the current user's email
        if(guardian._id.$oid == LocalStorageService.getInstance().getUser()?._id.$oid) {
            if(current_guardian.email != guardian.email) {
                let cpy = [...parents]
                let idx = cpy.findIndex((e) => e._id.$oid == LocalStorageService.getInstance().getUser()?._id.$oid)
                cpy[idx] = {...current_guardian}
                set_parents([])
                setTimeout(function() { set_parents(cpy); }, 100);
                toast({
                    title: 'Error.',
                    description: "You can not change the current user's email",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })
                return;
            }
        }

        // we do not want to change a user's email if they have a firebase_user_id - which means they already have an account
        if(guardian.firebase_user_id && current_guardian.email != guardian.email) {
            let cpy = [...parents]
            let idx = cpy.findIndex((e) => e._id.$oid == guardian._id.$oid)
            cpy[idx] = {...cpy[idx]}
            set_parents([])
            setTimeout(function() { set_parents(cpy); }, 100);
            toast({
                title: 'Error.',
                description: "You can not change the user's email",
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
            return;
        }

        let cpy = [...parents]
        cpy[idx] = {...guardian}
        set_parents(cpy)
    }

    const add_parent = (parent: Parent) => {
        update_state("guardians", [...family_state.guardians, parent._id])
        set_parents([...parents, parent])
    }

    const guardian_required_info = (parent: Parent) => {
        return parent.phone && parent.phone.length > 1 &&
        parent.name.length > 1 &&
        EmailValidator.validate(parent.email)
    }


    const family_required_info = () => {
        return family_state.name.length > 1 &&
        family_state.information.student_living.length > 1 &&
        family_state.information.language_spoken.length > 1 &&
        // family_state.information.preferred_contact &&
        family_state.information.emergency_contact.name.length > 1 &&
        family_state.information.emergency_contact.phone.length > 1 &&
        EmailValidator.validate(family_state.information.emergency_contact.email)
    }

    const save = () => {
        if(!save_family) {
            return;
        }
        set_loading(true)
        if(family_state.guardians.length == 0) {
            toast({
                title: 'Error.',
                description: "Fill all required information for guardians",
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
            set_loading(false)
            return;
        }
        let has_all_guardian_required_info = parents.every(guardian_required_info)
        if(!has_all_guardian_required_info) {
            toast({
                title: 'Error.',
                description: "Fill all required information for guardians",
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
            set_loading(false)
            return;
        }
        if(!family_required_info()){
            toast({
                title: 'Error.',
                description: "Fill all required information",
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
            set_loading(false)
            return;
        }
        // save new parent info
        let parents_promise = Promise.allSettled(parents.map((e) => ParentAPI.update_parent(e._id, e)))
        parents_promise.then((e) => {
            if(e.every(e => e.status != "rejected")){
                save_family(family_state)
            } else {
                toast({
                    title: 'Error.',
                    description: "An Error has occurred while saving the guardians, please report this issue directly to the team",
                    status: 'error',
                    duration: 10000,
                    isClosable: true,
                })
            }
        })

    }

    React.useEffect(() => {

        let parents = Promise.all(family_state.guardians.map((e) => ParentAPI.get_parent(e)))
        let profileSettings = OrganizationAPI.get_organization_profile_settings();

        parents.then(e => set_parents(e))
        profileSettings.then(res => setParentProfileSections(res.parentProfileSections));
    },[])

    return (
        <Box
            as="form"
            bg="bg-surface"
            boxShadow={useColorModeValue('sm', 'sm-dark')}
            borderRadius="lg"
            flex="1"
        >
            <Stack spacing="5" px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
                <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                    <FormControl id="name" isRequired isDisabled={read_only}>
                        <FormLabel>Family Name</FormLabel>
                        <Input 
                            value={family_state.name} 
                            onChange={(value) => {
                                update_state(value.target.id, value.target.value)
                            }}
                        />
                    </FormControl>
                    <FormControl id="student_living" isRequired isDisabled={read_only}>
                        <FormLabel>Living With</FormLabel>
                            <Select 
                                value={family_state.information.student_living}
                                onChange={(value) => {
                                    update_state("information", {...family_state.information, [value.target.id]: value.target.value})
                                }}
                            >
                                    <option value=''></option>
                                    <option value='guardian1.guardian2'>Guardian 1 & 2</option>
                                    <option value='guardian1'>Guardian 1</option>
                                    <option value='guardian2'>Guardian 2</option>
                            </Select>
                    </FormControl>
                    <FormControl id="preferred_contact" isRequired isDisabled={read_only}>
                        <FormLabel>Preferred Contact</FormLabel>
                            <Select 
                                value={family_state.information.preferred_contact?.$oid}
                                onChange={(value) => {
                                    update_state("information", {...family_state.information, [value.target.id]: to_oid(value.target.value)})
                                }}
                            >
                                { parents.map((guardian) => {
                                    return <option value={guardian._id.$oid}>{guardian.name}</option>
                                })
                                }
                            </Select>
                    </FormControl>
                    <FormControl id="language_spoken" isRequired isDisabled={read_only}>
                        <FormLabel>Language Spoken at Home</FormLabel>
                        <Input 
                            value={family_state.information.language_spoken} 
                            onChange={(value) => {
                                update_state("information", {...family_state.information, [value.target.id]: value.target.value})
                            }}
                        />
                    </FormControl>
                </Stack>

            
                <Stack spacing="5" px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
                    <HStack>
                        <Text fontWeight={"bold"} fontSize="xl">Guardians</Text>
                        <Spacer/>
                        { !read_only && 
                            <>
                                <Button 
                                    colorScheme='green'
                                    leftIcon={<FiPlus/>}
                                    onClick={() => setIsOpen(true)}
                                >
                                    Add Guardian
                                </Button>
                                <CreateParentDialog 
                                    add_parent={add_parent}
                                    isOpen={isOpen} onClose={onClose} cancelRef={cancelRef}
                                />
                            </>
                        }
                        
                    </HStack>
                    <Stack>
                    {parentProfileSections && parents.map((guardian) => {
                        return (
                            <FamilyGuardianCard guardian={guardian} save_guardian={save_guardian} save_on_edit={true} read_only={read_only} profile_sections={parentProfileSections}/>
                        )
                     })
                    }
                    </Stack>
                    
                </Stack>

                <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                    <FormLabel>Emergency Contact</FormLabel>
                    <FamilyContactCard read_only={read_only} family_contact={family_state.information.emergency_contact} save_family_contact={(contact: FamilyContact) => update_state("information", {...family_state.information, "emergency_contact": contact})}/>
                </Stack>
            </Stack>
            { !save_on_edit && !read_only &&
                <>
                <Divider />
                <Flex direction="row-reverse" py="4" px={{ base: '4', md: '6' }}>
                    <Button onClick={() => save()} colorScheme="green" isLoading={loading}>
                        Save
                    </Button>
                </Flex>
                </>
            }
            
        </Box>
    )

}


interface CreateParentDialogProps {
    add_parent: (parent: Parent) => void;
    isOpen: boolean
    onClose: any
    cancelRef: any
}

const CreateParentDialog = React.memo(({add_parent, isOpen, onClose, cancelRef}: CreateParentDialogProps) => {
    const toast = useToast() 
    const [loading, set_loading] = React.useState<boolean>(false);

    const [name, set_name] = React.useState<string>("");
    const [email, set_email] = React.useState<string>("");
    
    const create = () => {
        if(name.length > 3 && EmailValidator.validate(email)){
            set_loading(true)
            ParentAPI.new_parent(email, name)
                .then((res) => {
                    add_parent(res)
                    set_loading(false)
                    cancel()
                })
        }else{
            toast({
                title: 'Error.',
                description: "Please fill in all the required information",
                status: 'error',
                duration: 5000,
                isClosable: true,
              })
        }
    }

    const cancel = () => {
        set_name("")
        set_email("")
        onClose()
    }

    return (
        <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              New Guardian
            </AlertDialogHeader>

            <AlertDialogBody>
              {loading ?
                <Stack>
                  <Skeleton height='20px' />
                  <Skeleton height='20px' />
                  <Skeleton height='20px' />
                </Stack>
                :
                <>
                  <FormControl id="name" isRequired>
                    <FormLabel>Name</FormLabel>
                    <Input isRequired
                        value={name}
                        onChange={(value) => {set_name(value.target.value)}}
                    />
                  </FormControl>
                  <br></br>
                  <FormControl id="email" isRequired>
                    <FormLabel>Email</FormLabel>
                    <Input isRequired
                        value={email}
                        onChange={(value) => {set_email(value.target.value)}}
                    />
                  </FormControl>                  
                  <br></br>
                </>
              }
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={cancel}>
                Cancel
              </Button>
              <Button colorScheme='green' onClick={create} ml={3}>
                Create
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    )

})
