import React from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Invoice } from '../../models/invoice'; // Adjust path as needed
import InvoicePDF from './InvoicePdf';
import { AiOutlineFilePdf } from 'react-icons/ai';
import { LightButton } from '../layout/LightButton';

interface InvoicePreviewProps {
  invoice: Invoice;
  userName: string; 
}

export const InvoicePdfLink: React.FC<InvoicePreviewProps> = ({ invoice, userName }) => {
  return (
      <PDFDownloadLink 
        document={<InvoicePDF invoice={invoice} userName={userName} />} 
        fileName={`invoice-${invoice._id.$oid}.pdf`}
      >
        <LightButton color="teal" leftIcon={<AiOutlineFilePdf /> }>Export PDF</LightButton>
      </PDFDownloadLink>
  );
};