import React, { useEffect, useState } from 'react';
import { AsyncSelect } from 'chakra-react-select';
import { Student } from '../../models/student';
import { StudentAPI } from '../../api/StudentAPI';
import { ObjectId } from '../../utils/ObjectId';
import { Lookup } from '../../models/lookup';

interface StudentLookupProps {
  onSelect: (studentId?: ObjectId) => void;
  student?: Student;
}

const StudentLookup: React.FC<StudentLookupProps> = ({ onSelect, student }) => {
  const [selectedOption, setSelectedOption] = useState<Lookup | null>(null);

  const formatOptions = (students: Student[]): Lookup[] => {
    return students.map(student => ({
      value: student._id,
      label: `${student.first_name} ${student.last_name}`
    }));
  };

  const loadOptions = async (inputValue: string) => {
    if (!inputValue) return [];

    try {
      const students = await StudentAPI.students_list(undefined, undefined, inputValue).then(res => res?.students || []);

      return formatOptions(students);
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const handleChange = (selectedOption: Lookup | null) => {
    setSelectedOption(selectedOption);
    if (selectedOption) {
      onSelect(selectedOption.value);
    } else {
      onSelect(undefined);
    }
  };

  useEffect(() => {
    if (student) {
      setSelectedOption({
        value: student._id,
        label: `${student.first_name} ${student.last_name}`,
      });
    }
  }, [student]);
  
  return (
	<AsyncSelect
		placeholder="Search student by name..."
		loadOptions={loadOptions}
    value={selectedOption}
		onChange={handleChange}
		chakraStyles={{
		menu: (provided) => ({ ...provided, zIndex: 9999, boxShadow: 'lg' }),
		}}
	/>
  );
};

export default StudentLookup;
