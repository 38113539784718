import { useEffect, useState } from 'react'
import { TeacherAPI } from '../api/TeacherAPI'
import { Lookup } from '../models/lookup'
import { useUser } from './useUser'

export const useTeachersLookup = () => {
	const [teachers, set_teachers] = useState<Lookup[]>([])
	const [loading, setLoading] = useState<boolean>(true)
	const { userIsParent } = useUser() 

	useEffect(() => {

		if (userIsParent) return

		const fetchTeachers = async () => {
			try {
				setLoading(true) // Start loading
				const res = await TeacherAPI.teachers_list()
				const data = res?.teachers.map(e => ({
					value: e._id,
					label: `${e.first_name} ${e.last_name}`
				}))

				set_teachers(data || [])
			} finally {
				setLoading(false) // Stop loading
			}
		}

		fetchTeachers() 
	}, [userIsParent])

	return { teachers, loading }
}
