import React, {useEffect} from "react"
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
    Button,
    FormControl,
    FormLabel,
    Stack,
    Text,
    SimpleGrid,
    Badge,
    HStack,
    Spacer,
    useToast,
    Heading,
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
} from '@chakra-ui/react';
import { HomeroomUpdate } from "../../models/homeroom";
import { Homeroom } from "protobuffer-ts/dist/class_service/homeroom";
import { Course } from "protobuffer-ts/dist/class_service/course";
import { HomeroomAPI } from "../../api/HomeroomAPI";
import { ObjectId, to_oid } from "../../utils/ObjectId";
import { GradesArray, Student } from "../../models/student";
import {FiArchive, FiCopy, FiPlus} from "react-icons/fi";
import { TableContent } from "../table/TableContent";
import DatePicker from "../DatePicker";
import { AttendanceAPI } from "../../api/AttendanceAPI";
import ExternalLinks from "./ExternalLinks";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { updateHomeroomSchema } from "../../validations/homeroom";
import LocalStorageService from "../../api/LocalStorageService";
import { UserRole } from "../../api/UserApi";
import { CheckIcon } from "@chakra-ui/icons";
import FormInput from "../forms/FormInput";
import FormSelect from "../forms/FormSelect";
// import {CloneHomeroomDialog} from "./CreateHomeroomDialog";
import FormSelectOptions from "../forms/FormSelectObjects";
import { SemesterAPI } from "../../api/SemesterAPI";
import { CreateCourseDialog } from "./CreateCourseDialog";
import { TeacherAPI } from "../../api/TeacherAPI";
import { Lookup } from "../../models/lookup";
import { LightButton } from "../layout/LightButton";
import SimpleTeacherList from "../teachers/SimpleTeacherList";
import { TableTeacher, Teacher } from "../../models/teacher";
import AddStudentsDialog from "./AddStudentsDialog";
import AddTeachersDialog from "./AddTeachersDialog";
import { useTeacherCell } from "../../hooks/useTeacherCell";
import GClassItemViewer from "../gclass/GClassItemViewer";
import { StudentAPI } from "../../api/StudentAPI";
import SimpleStudentsList from "../students/SimpleStudentsList";
import { BlCard } from "../layout/Card";
import { ObjectId as ProtoObjectId } from "protobuffer-ts/dist/utils/object_id";
import { proto_utils } from "../../utils/proto_utils";

export const HomeroomDashboard = () =>  {
    let { homeroom_id } = useParams();

    const toast = useToast()

    const [homeroom, set_homeroom] = useState<Homeroom>();
    const [semesters, set_semesters] = useState<Lookup[]>([]);
    const [courses, set_courses] = useState<Course[]>();
    const [students, set_students] = useState<Student[]>();
    const [teachers, set_teachers] = useState<Teacher[]>([]);
    const [table_teachers, set_table_teachers] = useState<TableTeacher[]>();
    const [teachers_lookup, set_teachers_lookup] = useState<Lookup[]>([]);

    const { register, handleSubmit, reset, control, formState: { errors } } = useForm<HomeroomUpdate>({
        resolver: yupResolver(updateHomeroomSchema)
    });

    const [loading, set_loading] = useState<boolean>(false);
    const [archive_loading, set_archive_loading] = React.useState<boolean>(false);

    const [isOpen_add_course, setIsOpen_add_course] = React.useState(false)
    const onClose_add_course = () => setIsOpen_add_course(false)

    const [isOpen_add_teachers, setIsOpen_add_teachers] = React.useState(false)

    const [isOpen_add_students, setIsOpen_add_students] = React.useState(false)

    const [attendance_start_date, set_attendance_start_date] = useState<Date>(new Date(Date.now()))
    const [attendance_end_date, set_attendance_end_date] = useState<Date>(new Date(Date.now()))

    const user = LocalStorageService.getInstance().getUser();
    const canModifyHomeroom = user?.has_role(UserRole.Can_Modify_Homeroom);

    const [isOpen_homeroom, setIsOpen_homeroom] = useState(false)
    const onClose_homeroom = () => setIsOpen_homeroom(false)

    const courseTableColumns = [
        {Header: "Name", accessor: "name"},

        {
            Header: "Teachers",
            accessor: "teacherIds",
            Cell: (data:  ProtoObjectId[]) => {
                return useTeacherCell(data ? data.map(e => to_oid(e.id)) : data, teachers_lookup)
            }
        },
    ]

    const fetchHomeroom = () => {
        HomeroomAPI.get_homeroom(to_oid(homeroom_id!)).then((res) => {
            set_homeroom(res)
            let homeroom_update: HomeroomUpdate = {
                _id: proto_utils.from_proto_object_id(res.id!),
                semester_id: proto_utils.from_proto_object_id(res.semester!.id!),
                name: res.name,
                grade: res.grade!,
            }
            reset(homeroom_update)

            if (res?.teacherIds?.length) {
                TeacherAPI.teachers_by_ids(proto_utils.from_proto_object_id_arr(res.teacherIds!)).then(res => {
                    set_teachers(res)
                })
            } else set_teachers([])

            if (res?.studentIds?.length) {
                StudentAPI.students_by_ids(proto_utils.from_proto_object_id_arr(res.studentIds!)).then(res => {
                    set_students(res)
                })
            } else set_students([])
        })
    }

    const onClose_add_teachers = () => {
        setIsOpen_add_teachers(false)

        fetchHomeroom()
    }

    const onClose_add_students = () => {
        setIsOpen_add_students(false)

        fetchHomeroom()
    }

    useEffect(() => {
        if (homeroom_id) fetchHomeroom()

        HomeroomAPI.get_courses(to_oid(homeroom_id!)).then((res) => {
            let courses = res.map((course) => {
                return {
                    ...course,
                    link: '/class/courses/'+ course.id!.id
                }
            })

            set_courses(courses)
        })

        TeacherAPI.teachers_list(null, null, null).then((res) => {
                let data = res!.teachers.map((e) => {
                    return {
                        "value": e._id,
                        "label": e.first_name + " " + e.last_name
                    }
                })
            set_teachers_lookup(data)
        })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        SemesterAPI.semester_list(null, undefined, "", null,null, false)
            .then((res) => {
                let data = res!.semesters.map((e) => {
                    return {
                        "value": e._id,
                        "label": e.name
                    }
                })
                set_semesters(data)
            })
    }, [])

    useEffect(() => {
        set_table_teachers(teachers.map((teacher) => {
            return {
                _id: teacher._id, 
                profile: {
                    first_name: teacher.first_name,
                    last_name: teacher.last_name, 
                    email: teacher.email,
                }
            } as TableTeacher;
        }));
    }, [teachers]);
    
    const download_attendance_csv = () => {
        AttendanceAPI.homeroom_attendance_csv_data(homeroom!, attendance_start_date, attendance_end_date)
            .catch((err) => {
                toast({
                    title: 'Error',
                    description: "Failed to download attendance csv",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })
            })
    }

    const update_homeroom = async (data: HomeroomUpdate) => {
        set_loading(true)

        try {
            const updatedHomeroom = await HomeroomAPI.update(
                data._id,
                data.semester_id,
                data.name,
                data.grade,
            );

            set_homeroom(updatedHomeroom);

            toast({
                title: 'Success',
                description: "Homeroom updated successfully",
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: 'Error',
                description: "Homeroom failed to update",
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            set_loading(false)
        }
    }


    const archive = () => {
        set_archive_loading(true)
        HomeroomAPI.archive_homeroom(proto_utils.from_proto_object_id(homeroom!.id!))
        .then(() => {
            toast({
                title: 'Success',
                description: "Homeroom Archived",
                status: 'success',
                duration: 5000,
                isClosable: true,
            })
        })
        .catch(() => {
            toast({
                title: 'Error',
                description: "Failed to Archive Homeroom",
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        })
    }

    const removeTeacher = async (teacherId: ObjectId) => {
        try {
            await HomeroomAPI.remove_teachers(to_oid(homeroom_id!), [teacherId]);

            toast({
                title: 'Success',
                description: 'Teacher removed successfully',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });

            fetchHomeroom()
        } catch (error) {
            toast({
                title: 'Error',
                description: 'Failed to remove Teacher',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const removeStudent = async (studentId: ObjectId) => {
        try {
            await HomeroomAPI.remove_students(to_oid(homeroom_id!), [studentId]);
        
            toast({
                title: 'Success',
                description: 'Student removed successfully',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });

            fetchHomeroom()
        } catch (error) {
            toast({
                title: 'Error',
                description: 'Failed to remove Student',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };


    return (
        <>
        <HStack align={"center"} mb={8}>
            <Heading as="h1" size="md" fontWeight={"bold"}>
                {homeroom?.name}
            </Heading>

            <Badge colorScheme={homeroom?.archived ? "red" : "green"}>
                {homeroom?.archived ? "Archived" : "Active"}
            </Badge>

            <Spacer/>

            {
                !courses?.length && 
                <LightButton
                    color='green'
                    leftIcon={<FiPlus />}
                    onClick={() => setIsOpen_add_course(true)}
                >
                    Add Course
                </LightButton>
            }


            <ExternalLinks type="homeroom" id={homeroom_id!} />
        </HStack>

        <Tabs variant='line'>
            <TabList>
                <Tab>General</Tab>

                <Tab>Academic Tracker</Tab>
            </TabList>
            
            <TabPanels>
                <TabPanel>
                    <Stack gap="4">
                        <BlCard
                            as="form"
                            onSubmit={handleSubmit(update_homeroom)}
                        >
                            <HStack>
                                <FormLabel fontSize={"lg"} fontWeight="bold">
                                    Homeroom Information
                                </FormLabel>

                                <Spacer />

                                { canModifyHomeroom && (
                                        <Button
                                            isLoading={loading}
                                            colorScheme='green'
                                            leftIcon={<CheckIcon/>}
                                            type="submit"
                                        >
                                            Update
                                        </Button>
                                    )
                                }
                            </HStack>

                            <SimpleGrid columns={{sm: 1, md: 3}} spacing={10}>
                                <FormInput
                                    name="name"
                                    label="Name"
                                    register={register('name', { required: true })}
                                    error={errors.name?.message}
                                    isDisabled={!canModifyHomeroom}
                                    isRequired
                                />

                                <FormSelect
                                    name="grade"
                                    label="Grade"
                                    options={GradesArray}
                                    register={register('grade', { required: true })}
                                    error={errors.grade?.message}
                                    isDisabled={!canModifyHomeroom}
                                    isRequired
                                    />
                                <FormSelectOptions
                                    name="semester_id"
                                    label="Semester"
                                    options={semesters}
                                    control={control}
                                    error={errors.semester_id?.message}
                                    isDisabled={!canModifyHomeroom}
                                    isRequired
                                />
                            </SimpleGrid>
                        </BlCard>

                        {
                            !!courses?.length &&
                        <BlCard>
                            <HStack>
                                <FormLabel fontSize={"lg"} fontWeight="bold">
                                    Courses
                                </FormLabel>

                                <Spacer/>

                                <LightButton
                                    color='green'
                                    leftIcon={<FiPlus />}
                                    onClick={() => setIsOpen_add_course(true)}
                                >
                                    Add Course
                                </LightButton>

                            </HStack>

                            <TableContent
                                data={courses}
                                columns={courseTableColumns}
                            />
                        </BlCard>
                        }

                        <BlCard>
                            <HStack>
                                <FormLabel fontSize={"lg"} fontWeight="bold">
                                    Teachers
                                </FormLabel>

                                <Spacer/>

                                <LightButton
                                    color="green"
                                    leftIcon={<FiPlus />}
                                    onClick={() => setIsOpen_add_teachers(true)}
                                >
                                    Add Teacher
                                </LightButton>

                                { homeroom &&
                                    <AddTeachersDialog
                                        homeroom={proto_utils.from_proto_object_id(homeroom.id!)}
                                        isOpen={isOpen_add_teachers}
                                        onClose={onClose_add_teachers}
                                    />
                                }
                            </HStack>

                            <SimpleTeacherList teachers={table_teachers} onDelete={removeTeacher} />
                        </BlCard>

                        <BlCard>
                            <HStack>
                                <FormLabel fontSize={"lg"} fontWeight="bold">
                                    Students
                                </FormLabel>

                                <Spacer/>

                                <LightButton
                                    color="green"
                                    leftIcon={<FiPlus />}
                                    onClick={() => setIsOpen_add_students(true)}
                                >
                                    Add Students
                                </LightButton>

                                { homeroom && 
                                    <AddStudentsDialog
                                        homeroom={proto_utils.from_proto_object_id(homeroom.id!)}
                                        isOpen={isOpen_add_students}
                                        onClose={onClose_add_students}
                                    />
                                }
                            </HStack>

                            <SimpleStudentsList students={students} onDelete={removeStudent} />
                        </BlCard>

                        <BlCard>
                            <HStack>
                                <Text fontSize={"lg"} fontWeight="bold">
                                    Attendance Export
                                </Text>

                                <Spacer/>

                                <LightButton
                                    color="blue"
                                    onClick={download_attendance_csv}
                                >
                                    Export Attendance CSV
                                </LightButton>
                            </HStack>
                            
                        

                            <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                                <FormControl id="start_date" isRequired>
                                    <FormLabel>Start Date</FormLabel>
                                    <DatePicker
                                        selectedDate={attendance_start_date}
                                        onChange={(value) => {
                                            set_attendance_start_date(value as Date)
                                        }}
                                    />
                                </FormControl>
                                <FormControl id="end_date" isRequired>
                                    <FormLabel>End Date</FormLabel>
                                    <DatePicker
                                        selectedDate={attendance_end_date}
                                        onChange={(value) => {
                                            set_attendance_end_date(value as Date)
                                        }}
                                    />
                                </FormControl>
                            </Stack>
                        </BlCard>

                        <HStack>
                            <Spacer/>
                            <Button
                                bgColor={"blue.100"}
                                textColor="blue.700"
                                leftIcon={<FiCopy/>}
                                onClick={() => setIsOpen_homeroom(true)}
                            >
                                Clone Homeroom
                            </Button>
                            {homeroom && ( true // TODO RE-ADD THIS
                                // <CloneHomeroomDialog
                                //     isOpen={isOpen_homeroom}
                                //     onClose={onClose_homeroom}
                                //     clone_from_homeroom={homeroom}
                                //     semesters={semesters}
                                //     teachers={teachers_lookup}
                                //     />
                            )}
                            <Button 
                                bgColor={"orange.100"}
                                textColor="orange.700"
                                colorScheme='yellow'
                                leftIcon={<FiArchive/>}
                                onClick={archive}
                                isDisabled={homeroom?.archived || archive_loading}
                            >
                                Archive
                            </Button>
                        </HStack>
                    </Stack>
                </TabPanel>

                <TabPanel>
                    <BlCard>
                        { homeroom && <GClassItemViewer type="homeroom" item={homeroom!} /> }
                    </BlCard>
                </TabPanel>
            </TabPanels>
        </Tabs>

        <CreateCourseDialog
            isOpen={isOpen_add_course}
            semesters={semesters}
            teachers={teachers_lookup}
            homeroom={homeroom}
            onClose={onClose_add_course}
        />

        </>
    );
};