import React from 'react';
import { Document, Page, Text, StyleSheet, View, Image, Font } from '@react-pdf/renderer';
import { Invoice } from '../../models/invoice';
import { useInvoiceTotals } from '../../hooks/useInvoiceTotals';

const borderColor = '#758b9c';
const lightGray = '#f0f0f0';

Font.register({
  family: 'Roboto',
  fonts: [
    { src: '/fonts/Roboto-regular.woff', fontWeight: 'normal' },
    { src: '/fonts/Roboto-700.woff', fontWeight: 'bold' },
    { src: '/fonts/Roboto-900.woff', fontWeight: 'heavy' },
  ],
});

const styles = StyleSheet.create({
  page: {
    fontSize: 12,
    flexDirection: 'column',
    fontFamily: 'Roboto',
    color: '#303449'
  },
  headerRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  logo: {
    width: 100,
    height: 50
  },
  invoiceNumber: {
    fontSize: 12,
    textAlign: 'right'
  },
  thickLine: {
    marginTop: 10,
    marginBottom: 20,
    height: 2,
    backgroundColor: borderColor
  },
  title: {
    fontSize: 24,
    marginBottom: 5,
    fontWeight: 'heavy'
  },
  description: {
    fontSize: 12,
    marginBottom: 30,
    textAlign: 'left'
  },
  infoRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20
  },
  infoColumn: {
    flexDirection: 'column',
    width: '33%',
    fontSize: 10,
    paddingRight: 10
  },
  infoLabel: {
    fontWeight: 'bold',
    marginBottom: 4
  },
  infoValue: {
    marginBottom: 8
  },
  tableHeader: {
    flexDirection: 'row',
    backgroundColor: lightGray,
    borderRadius: 4,
    paddingVertical: 6,
    paddingHorizontal: 10,
    marginBottom: 5
  },
  tableHeaderCell: {
    fontSize: 10,
    fontWeight: 'bold',
    textAlign: 'left'
  },
  tableHeaderCellRight: {
    fontSize: 10,
    fontWeight: 'bold',
    textAlign: 'right'
  },
  itemColumn: {
    width: '25%',
  },
  descriptionColumn: {
    width: '35%',
  },
  quantityColumn: {
    width: '15%',
  },
  priceColumn: {
    width: '25%',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottom: `1 solid ${borderColor}`,
    paddingVertical: 6,
    paddingHorizontal: 10,
    fontSize: 10,
    marginBottom: 4
  },
  tableCell: {
    textAlign: 'left'
  },
  tableCellRight: {
    textAlign: 'right'
  },
  totalsSection: {
    marginTop: 30,
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  totalsColumn: {
    width: '40%',
    fontSize: 10
  },
  totalsRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 6
  },
  totalsLabel: {
    fontWeight: 'bold'
  },
  grandTotalValue: {
    fontWeight: 'bold',
    fontSize: 12
  }
});

interface InvoicePDFProps {
  invoice: Invoice;
  userName: string; 
}

const InvoicePDF: React.FC<InvoicePDFProps> = ({ invoice, userName }) => {
  const { subtotal, discount, grandTotal } = useInvoiceTotals(invoice?.items || [], invoice?.coupons?.[0])

  return (
    <Document>
      <Page style={styles.page}>
        <View style={{ padding: 20 }}>
          {/* Header with Logo and Invoice Number */}
          <View style={styles.headerRow}>
            <Image style={styles.logo} src="/ab-logo.jpeg" />
            {invoice.invoice_number && (
              <Text style={styles.invoiceNumber}>Invoice #: {invoice.invoice_number}</Text>
            )}
          </View>

          <View style={styles.thickLine} />

          {/* Invoice Title and Description */}
          <Text style={styles.title}>{invoice.title}</Text>
          <Text style={styles.description}>{invoice.description}</Text>

          {/* Due Date, Bill To, Bill From */}
          <View style={styles.infoRow}>
            <View style={styles.infoColumn}>
              <Text style={styles.infoLabel}>Bill To</Text>
              <Text style={styles.infoValue}>{userName}</Text>
            </View>

            <View style={styles.infoColumn}>
              <Text style={styles.infoLabel}>Bill From</Text>
              <Text style={styles.infoValue}>Alfajrul Bassem Academy</Text>
              <Text style={styles.infoValue}>700 Kerr St</Text>
              <Text style={styles.infoValue}>Oakville, ON, L6K 3E1</Text>
            </View>

            <View style={styles.infoColumn}>
              <Text style={styles.infoLabel}>Due Date</Text>
              <Text style={styles.infoValue}>
                {invoice.due_date ? new Date(invoice.due_date).toLocaleDateString() : '-'}
              </Text>
            </View>
          </View>

          {/* Items Table */}
          <View>
            <View style={styles.tableHeader}>
              <Text style={[styles.tableHeaderCell, styles.itemColumn]}>Item</Text>
              <Text style={[styles.tableHeaderCell, styles.descriptionColumn]}>Description</Text>
              <Text style={[styles.tableHeaderCellRight, styles.quantityColumn]}>Quantity</Text>
              <Text style={[styles.tableHeaderCellRight, styles.priceColumn]}>Item Price</Text>
            </View> 

            {invoice.items.map((item, idx) => (
              <View style={styles.tableRow} key={idx}>
                <Text style={[styles.tableCell, styles.itemColumn]}>{item.title}</Text>
                <Text style={[styles.tableCell, styles.descriptionColumn]}>{item.description}</Text>
                <Text style={[styles.tableCellRight, styles.quantityColumn]}>{item.quantity}</Text>
                <Text style={[styles.tableCellRight, styles.priceColumn]}>${item.price.toFixed(2)}</Text>
              </View>
            ))}

          </View>

          {/* Totals Section */}
          <View style={styles.totalsSection}>
            <View style={styles.totalsColumn}>
              <View style={styles.totalsRow}>
                <Text>Subtotal</Text>
                <Text>${subtotal.toFixed(2)}</Text>
              </View>
              <View style={styles.totalsRow}>
                <Text>Discount</Text>
                <Text>${discount.toFixed(2)}</Text>
              </View>
              <View style={styles.totalsRow}>
                <Text style={styles.totalsLabel}>Grand Total</Text>
                <Text style={styles.grandTotalValue}>${grandTotal.toFixed(2)}</Text>
              </View>
            </View>
          </View>
        </View>

      </Page>
    </Document>
  );
};

export default InvoicePDF;
