import { sha3_256 } from 'js-sha3';
import { ObjectId } from '../utils/ObjectId';
import { UserAPI, UserRole } from './UserApi';
import { setUser } from "@sentry/react";

class LocalStorageService {
    private static instance: LocalStorageService;
    // all serialised in user
    // public static _id: ObjectId;
    // public static full_name: string;
    // public static user_type: UserType;
    // public static user_roles: Array<UserRoles>;

    private constructor() { 
        
    }
    public static getInstance(): LocalStorageService {
        if (!LocalStorageService.instance) {
            LocalStorageService.instance = new LocalStorageService();
        }
        return LocalStorageService.instance;
    }

    public setToken(firebase_token: string) {
        // When we set the token, lets set the user data too
        return UserAPI.login(firebase_token).then((res) => {
            localStorage.setItem("token_data", JSON.stringify(res));
            localStorage.setItem("access_token", res.token);
            return res
        })
    }

    public getUser(): UserAPI.AccessTokenData | null {
        let user = localStorage.getItem("token_data")
        if(user){
            return (new UserAPI.LoginResponse(JSON.parse(user))).token_data
        }
        return null;
    }

    public getAccessToken(): string | null {
        return localStorage.getItem("access_token");
    }
 
    public clearToken() {
        localStorage.removeItem("access_token");
    }

    public get_show_all_classes(): boolean {
        if(localStorage.getItem("show_all_classes") == null && (this.getUser()?.has_role(UserRole.Can_Access_All_Homeroom) || this.getUser()?.has_role(UserRole.Can_Access_All_Course))){
            this.set_show_all_classes(true)
        }
        return localStorage.getItem("show_all_classes") == "true";
    }

    public set_show_all_classes(show_all_classes: boolean) {
        localStorage.setItem("show_all_classes", String(show_all_classes));
    }

    public clear(){
        localStorage.clear()
    }
}

export default LocalStorageService;
