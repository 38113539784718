import React from "react"
import { Text, Badge, Flex } from "@chakra-ui/react";
import { Grades, Student } from "../../../models/student"
import { Icon } from "@chakra-ui/icons"
import { FaFingerprint } from "react-icons/fa";
import { FiBookOpen, FiCalendar, FiKey, FiUser } from "react-icons/fi"
import { BlCard } from "../../layout/Card";

interface StudentOverviewCardProps {
	student: Student
}

const StudentOverview: React.FC<StudentOverviewCardProps> = ({ student }) => {
	return (
		<BlCard>
			<Flex gap="2">
				<Text fontSize="lg" fontWeight="bold">{student.first_name} {student.last_name}</Text>

				<Badge rounded="lg" colorScheme={badgeEnum[student.status]}>{student.status}</Badge>
			</Flex>

			<Flex gap="8" wrap="wrap">
				<Flex gap={2} align="center">
					<Icon as={FiKey} boxSize={6} color="blue.500" />
					<Text color="gray.600">Student ID: <strong>{student.id}</strong></Text>
				</Flex>

				<Flex gap={2}>
					<Icon as={FiUser} boxSize={6} color="green.500" />

					<Text color="gray.600">username: <strong>{student.username}</strong></Text>
				</Flex>

				<Flex gap={2} align="center">
					<Icon as={FiBookOpen} boxSize={6} color="orange.500" />

					<Text color="gray.600">Grade: <strong>{Grades.get(student.grade)}</strong></Text>
				</Flex>

				<Flex gap={2} align="center">
					<Icon as={FiCalendar} boxSize={6} color="pink.500" />

					<Text color="gray.600">Date of Birth: <strong>{student.date_of_birth?.to_date().toDateString()}</strong></Text>
				</Flex>
			</Flex>
		</BlCard>
	)
}

export default StudentOverview

const badgeEnum: Record<string, string> = {
	Enrolled: "green",
	Withdrawn: "orange",
	Applicant: "yellow",
	Processed: "blue",
	Rejected: "red"
}
