import { Center, Heading } from "@chakra-ui/react";
import React from "react";
import { useParams } from "react-router-dom";
import { StudentAPI } from "../../api/StudentAPI";
import { Student, StudentStatus } from "../../models/student";
import { ParentsStudentProfile } from "./ParentStudentProfile";

export const ParentStudent: React.FC = () => {
    let { student_id } = useParams();

    const [student, set_student] = React.useState<Student>()

    React.useEffect(()=>{
        StudentAPI.get_student(student_id!).then((res) => {
            let s = res!
            set_student(s)
        })
    }, [student_id])

    return (
        <>
        { student && 
        <>
            { student.status === StudentStatus.Applicant ? 
                <>
                    {/* <StudentForms student_id={student._id} FORM_NAME="Registration 24-25" show_uniform={true}/> */}
                </>
                :
                <>
                { student.status === StudentStatus.ReRegistration ?
                    <>
                        {/* <StudentForms student_id={student._id} FORM_NAME="Re-Registration 24-25" show_uniform={false}/> */}
                    </>
                    :
                    <>
                        <Center>
                            <Heading size="md" mb="6">
                                {student?.first_name} {student?.last_name}
                            </Heading>
                        </Center>

                        <ParentsStudentProfile student={student} />
                    </>
                }
                </>
            }
        </>
        }
        </>
    )
}