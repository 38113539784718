import React, {useEffect} from "react"
import { useState } from "react";
import {
    Box,
    Grid,
    Heading,
    Badge,
    Stack,
    Skeleton,
    Center,
    VStack,
    FormControl,
    InputGroup,
    FormLabel,
    InputLeftElement,
    Input,
    HStack,
    Spacer,
    Text,
    Button,
    useToast
}
from '@chakra-ui/react'
import { TableContent } from "../table/TableContent";
import { useLocation } from 'react-router-dom'
import {StudentAPI} from "../../api/StudentAPI"
import { TablePagination } from "../table/TablePagination";
import { TableActions } from "../table/TableActions";
import { BsSearch } from "react-icons/bs";
import { ObjectId, to_oid } from "../../utils/ObjectId";
import { Family } from "../../models/family";
import { FamilyAPI } from "../../api/FamilyAPI";
import SearchInput from "../forms/SearchInput";


export const FamilyManagerList = React.memo(() =>  {
    const location = useLocation();
    const [family_data, _set_family_data] = useState<Array<familyObj>>();
    const [full_family_data, set_full_family_data] = useState<Array<Family>>();
    const [name_search, set_name_search] = useState<string>("");

    const FamilyColumns = [
        {
            Header: "Family Name",
            accessor: "name",
        }
    ]

    const set_family_data = (_families: Array<Family>) => {
        let families = name_search.length > 0 ? _families.filter((e) => e.name.toLowerCase().includes(name_search)) : _families
        let data = families.map((e) => {
            return {
                _id: e._id.$oid,
                name: e.name,
                link: location.pathname + "/" + e._id.$oid
            }
        })
        _set_family_data(data)
    }

    useEffect(() => {
        FamilyAPI.get_all_families().then((e) => {
            set_full_family_data(e)
            set_family_data(e)
        })
    }, []);

    useEffect(() => {
        if(full_family_data){
            set_family_data(full_family_data)
        }
    }, [name_search]);


    return (
        <Box>
            <Center>
                    <Heading size="md" mb="6">
                        Student Families
                    </Heading>
            </Center>
            { family_data ?
                (
                    <>
                    <Stack>
                        <Stack spacing="4" direction={{ base: 'column', md: 'row' }} justify="space-between">
                            <HStack>
                                <SearchInput onChange={set_name_search} />
                            </HStack>
                        </Stack>

                        <TableContent data={family_data} columns={FamilyColumns} />
                    </Stack>
                    </>

                )
                    :
                        <Stack>
                            <Skeleton height='100px' />
                            <Skeleton height='100px' />
                            <Skeleton height='100px' />
                            <Skeleton height='100px' />
                            <Skeleton height='100px' />
                            <Skeleton height='100px' />
                            <Skeleton height='100px' />
                            <Skeleton height='100px' />
                            <Skeleton height='100px' />
                        </Stack>
            }
        </Box>
    );
} );
interface familyObj {
    _id: string
    name: string,
    link?: string
}

const badgeEnum: Record<string, string> = {
    Enrolled: 'green',
    Withdrawn: 'red',
}
