import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, Center, Heading, HStack, Skeleton, Spacer, Stack, Text, useToast } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { FiPlus } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";
import LocalStorageService from "../../api/LocalStorageService";
import { TeacherAPI } from "../../api/TeacherAPI";
import { UserRole } from "../../api/UserApi";
import { Teacher } from "../../models/teacher";
import { DateTime } from "../../utils/DateTime";
import { to_oid } from "../../utils/ObjectId";
import { TeacherList } from "./TeacherList";
import { TeacherProfile } from "./TeacherProfile";

export const TeacherListRoute = () =>  {

    const [isOpen, setIsOpen] = React.useState(false)
    const onClose = () => setIsOpen(false)
    const cancelRef = React.useRef()

    return (
        <>
        <Center>
            <Heading size="md" mb="6">Teacher List</Heading>
        </Center>
        <br></br>
        <Stack>
          { LocalStorageService.getInstance().getUser()?.has_role(UserRole.Can_Create_Teacher) &&
            <HStack>
                <Spacer/>
                <Button
                    colorScheme='green'
                    leftIcon={<FiPlus/>}
                    onClick={() => setIsOpen(true)}
                >
                    Add Teacher
                </Button>
                <CreateTeacherDialog 
                    isOpen={isOpen} onClose={onClose} cancelRef={cancelRef}
                />
            </HStack>
          }
        </Stack>
        <TeacherList/>
        </>
    )

}

interface CreateTeacherDialogProps {
    isOpen: boolean
    onClose: any
    cancelRef: any
}

type KeyOfType<T, V> = keyof {
    [P in keyof T as T[P] extends V? P: never]: any
}

const CreateTeacherDialog = React.memo(({isOpen, onClose, cancelRef}: CreateTeacherDialogProps) => {
    let navigate = useNavigate()
    const toast = useToast() 
    const [loading, set_loading] = React.useState<boolean>(false);

    const [teacher, set_teacher] = React.useState<Teacher>(
        new Teacher({
            _id: to_oid("NONE"),
            has_admin: false,
            status: "Active",
            username: "",
            first_name: "",
            last_name: "",
            phone_number: "",
            gender: "F",
            date_of_birth: DateTime.from_date(new Date()),
            personal_email: ""
        })
    );

    const required_info = () => {
        return teacher.first_name != "" && 
            teacher.last_name != "" && 
            teacher.gender != "" && 
            teacher.phone_number != "" &&
            teacher.personal_email != ""
    }
    
    const create = () => {
        if(required_info()){
            set_loading(true)
            TeacherAPI.create_teacher(teacher.first_name, teacher.last_name, teacher.gender, teacher.phone_number, teacher.date_of_birth, teacher.personal_email)
                .then((res) => {
                    set_loading(false)
                    cancel()
                    navigate("/teachers/" + res._id.$oid)
                })
        }else{
            toast({
                title: 'Error.',
                description: "Please fill in all the required information",
                status: 'error',
                duration: 5000,
                isClosable: true,
              })
        }
    }

    const cancel = () => {
        set_teacher(
            new Teacher({
                _id: to_oid("NONE"),
                has_admin: false,
                status: "Active",
                username: "",
                first_name: "",
                last_name: "",
                phone_number: "",
                gender: "F",
                date_of_birth: DateTime.from_date(new Date()),
                personal_email: ""
            })
        )
        onClose()
    }

    return (
        <AlertDialog
            size={'1000px'}
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
        >
        <AlertDialogOverlay>
          <AlertDialogContent
              width={"800px"}
              height={"80%"}
          >
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Create Teacher
            </AlertDialogHeader>

            <AlertDialogBody>
              {loading ?
                <Stack>
                  <Skeleton height='20px' />
                  <Skeleton height='20px' />
                  <Skeleton height='20px' />
                </Stack>
                :
                <>
                  <TeacherProfile teacher={teacher} set_teacher={set_teacher} show_overview={false} show_save={false}/>
                </>
              }
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={cancel} isDisabled={loading}>
                Cancel
              </Button>
              <Button colorScheme='green' onClick={create} ml={3} isDisabled={loading}>
                Create
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    )

})