import { Document, Image, Page, View, Text as PdfText, Font } from "@react-pdf/renderer";
import { Course } from "protobuffer-ts/dist/class_service/course";
import { ReportLayout } from "protobuffer-ts/dist/class_service/report_layout";
import { ReportType, Semester } from "protobuffer-ts/dist/class_service/semester";
import { CourseAPI } from "../../api/CourseAPI";
import { PdfAttendanceCount } from "../../models/attendance";
import { ReportEntry } from "../../models/report_entry";
import { Grades, Student } from "../../models/student";
import { Teacher } from "../../models/teacher";
import { utils } from "../../utils/array_utils";
import { AttendanceCourse } from "../attendance/AttendanceCourse";
import { CourseRowPdf } from "./CourseRowPdf";
import PdfStylesheet from "./PdfStylesheet";

// Define styles for the PDF document
const styles = PdfStylesheet

Font.registerHyphenationCallback(text => [text])

Font.registerEmojiSource({
	format: 'png',
	url: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/',
});

// Register Roboto fonts
Font.register({
	family: 'Roboto',
	fonts: [
	  { src: '/fonts/Roboto-regular.woff', fontWeight: 'normal' },
	  { src: '/fonts/Roboto-700.woff', fontWeight: 'bold' },
	],
  });

// School information (constant data)
const schoolInfo = {
	School: 'Alfajrul Bassem Academy',
	Address: '700 Kerr St Oakville, ON, L6K 3E1',
	Telephone: '+1 (905) 514-9995',
	Email: 'info@alfajrulbassem.com',
	Principal: 'Dr. Dania Wattar',
	BoysSupervisor: 'Abdulnafi Alrifaie',
	girlsSupervisor: 'Nivin Sarraj',
};

interface ReportCardProps { 
	student: Student
	semester: Semester
	reportType: ReportType
	semesterDate?: Date
	courses: CourseAPI.ListCourse[]
	teachers?: Teacher[]
	reportEntries: ReportEntry[]
	reportLayouts: ReportLayout[]
	attendanceCourses: Record<string, PdfAttendanceCount>
}

export const ReportCardPdf: React.FC<ReportCardProps> = ({ student, semester, reportType, semesterDate, courses, teachers, reportEntries, reportLayouts, attendanceCourses }) => {
	// Convert the subjects to an array of 4 items for each, each 4 will be rendered in a separated page 
	const coursesChunks = utils.chunkArray(courses, 4) as CourseAPI.ListCourse[][];

	return (
		<Document>
			{coursesChunks.map((chunk, index) => (
				<Page style={styles.page} orientation="landscape" key={`page-${index}`}>
					<View style={styles.headerContainer}>
						{/* Logo Column */}
						<View style={styles.headerColumn}>
							<Image style={styles.logo} src="/high-school-logo.jpeg" />
						</View>

						<View style={styles.headerColumn}>
							<PdfText style={styles.header}>Provincial Report Card, Grades 9 – 12</PdfText>
						</View>

						<View style={styles.headerColumn}>
							<View style={styles.headerSemester}>
								<View style={[styles.cell, styles.border]}>
									<PdfText style={styles.label}>Term: </PdfText>
									{/* -1 to skip the progress "we don't calculate progress in high school report types" */}
									<PdfText style={styles.value}>{reportType - 1}</PdfText>
								</View>

								<View style={[styles.cell, styles.border]}>
									<PdfText style={styles.label}>Date: </PdfText>
									<PdfText>
										{semesterDate?.toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' })}
									</PdfText>
								</View>
							</View>
						</View>
					</View>

					{/* Student Information Section Title */}
					<View style={styles.sectionTitleContainer}>
						<PdfText style={styles.sectionTitleText}>Student Information</PdfText>
					</View>

					{/* Student Information and School Information */}
					<View style={styles.tableContainer}>
						{/* First Row */}
						<View style={[styles.cell, styles.cellQuarterWidth]}>
							<PdfText style={styles.label}>Student Name: </PdfText>
							<PdfText style={styles.value}>
								{`${student.first_name} ${student.last_name}`}
							</PdfText>
						</View>
					{/* 
						<View style={[styles.cell, styles.cellQuarterWidth]}>
							<PdfText style={styles.label}>OEN: </PdfText>
							<PdfText style={styles.value}>{student.OEN}</PdfText>
						</View>
 					*/}
						<View style={[styles.cell, styles.cellQuarterWidth]}>
							<PdfText style={styles.label}>Grade: </PdfText>
							<PdfText style={styles.value}>{Grades.get(student.grade)}</PdfText>
						</View>

						<View style={[styles.cell, styles.cellQuarterWidth]}>
							<PdfText style={styles.label}>Supervisor: </PdfText>
							<PdfText style={styles.value}>
								{
									semester.name.toLowerCase().includes("boys") ? schoolInfo.BoysSupervisor : 
									semester.name.toLowerCase().includes("girls") ? schoolInfo.girlsSupervisor : ""
								}
							</PdfText>
						</View>

						<View style={[styles.cell, styles.cellQuarterWidth]}>
							<PdfText style={styles.label}>School: </PdfText>
							<PdfText style={styles.value}>{schoolInfo.School}</PdfText>
						</View>

						<View style={[styles.cell, styles.cellQuarterWidth]}>
							<PdfText style={styles.label}>Principal: </PdfText>
							<PdfText style={styles.value}>{schoolInfo.Principal}</PdfText>
						</View>

						<View style={[styles.cell, styles.cellQuarterWidth]}>
							<PdfText style={styles.label}>School Address:</PdfText>
							<PdfText style={styles.value}>{schoolInfo.Address}</PdfText>
						</View>

						<View style={[styles.cell, styles.cellQuarterWidth]}>
							<PdfText style={styles.label}>Telephone: </PdfText>
							<PdfText style={styles.value}>{schoolInfo.Telephone}</PdfText>
						</View>
					</View>

					{/* Grid Header */}
					<View style={[styles.gridContainer, {backgroundColor: "#dbdbf2"}]}>
						<View style={[styles.gridCell, styles.subjectCol, styles.flexCenter]}>
							<PdfText style={styles.value}>Courses</PdfText>
						</View>
						<View style={[styles.gridCell, styles.smallCol, { height: 81 }]}>
							<PdfText style={styles.verticalText}>Reporting-Period</PdfText>
						</View>
						<View style={[styles.gridCell, styles.smallCol]}>
							<PdfText style={styles.verticalText}>Percentage-Mark</PdfText>
						</View>
						<View style={[styles.gridCell, styles.smallCol]}>
							<PdfText style={styles.verticalText}>Course-Median</PdfText>
						</View>
						<View style={[styles.gridCell, styles.smallCol]}>
							<PdfText style={styles.verticalText}>Credits-Earned</PdfText>
						</View>

						<View style={[{width: '15%', padding: 0}]}>
							<PdfText style={[styles.gridCell, styles.learningSkillsLabel]}>Learning Skills and Work Habits</PdfText>

							<View style={[styles.gridContainer, {backgroundColor: "#dbdbf2", width: "100%", borderLeft: 0}]}>
								<View style={[styles.gridCell, styles.SemesterCol]}>
									<PdfText style={styles.verticalText}>Responsibility</PdfText>
								</View>

								<View style={[styles.gridCell, styles.SemesterCol]}>
									<PdfText style={styles.verticalText}>Organization</PdfText>
								</View>

								<View style={[styles.gridCell, styles.SemesterCol]}>
									<PdfText style={styles.verticalText}>Independent-Work</PdfText>
								</View>

								<View style={[styles.gridCell, styles.SemesterCol]}>
									<PdfText style={styles.verticalText}>Collaboration</PdfText>
								</View>

								<View style={[styles.gridCell, styles.SemesterCol]}>
									<PdfText style={styles.verticalText}>Initiative</PdfText>
								</View>

								<View style={[styles.gridCell, styles.SemesterCol]}>
									<PdfText style={styles.verticalText}>Self-Regulation</PdfText>
								</View>
							</View>
						</View>


						<View style={[styles.gridCell, styles.largeCol, styles.flexCenter]}>
							<PdfText style={styles.value}>Strengths/Next Steps for Improvement</PdfText>
						</View>

						<View style={[{width: '7.5%', padding: 0}]}>
							<PdfText style={[styles.gridCell, styles.learningSkillsLabel]}>Attendance</PdfText>

							<View style={[styles.gridContainer, {backgroundColor: "#dbdbf2", width: "100%", borderLeft: 0}]}>
								<View style={[styles.gridCell, styles.AttendanceCol]}>
									<PdfText style={styles.verticalText}>Classes-Missed</PdfText>
								</View>

								<View style={[styles.gridCell, styles.AttendanceCol]}>
									<PdfText style={styles.verticalText}>Times-Late</PdfText>
								</View>

								<View style={[styles.gridCell, styles.AttendanceCol]}>
									<PdfText style={styles.verticalText}>Total-Classes</PdfText>
								</View>
							</View>
						</View>
					</View>

					{/* Course Rows */}
					{chunk.map(course => {
						const teacherId = course.teachers?.[0]; // Get the first teacher
						const teacher = teachers?.find(t => t._id.$oid === teacherId.$oid);
						const teacherName = teacher ? `${teacher.first_name} ${teacher.last_name}` : undefined;

						return (
							<CourseRowPdf
								key={course._id.$oid}
								semester={semester}
								course={course}
								teacher={teacherName}
								reportEntry={reportEntries.find(r => r.course?.$oid === course._id.$oid)!}
								reportLayout={reportLayouts.find(r => r.courseId?.id === course._id.$oid)!}
								attendanceCount={attendanceCourses[course._id.$oid]}
							/>
						)
					})}

					{/* Footer */}
					<View style={styles.footer} fixed>
						<View style={styles.footerColumnLeft}>
							<PdfText>ABA R01</PdfText>
						</View>
						<View style={styles.footerColumnCenter}>
							<PdfText>app.bassemlabs.com</PdfText>
							<PdfText>highschool@alfajrulbassem.com</PdfText>
						</View>
						<View style={styles.footerColumnRight}>
							<PdfText>Tel: +1 (905) 514-9995</PdfText>
							<PdfText>Page {index + 1} of {coursesChunks.length}</PdfText>
						</View>
					</View>
				</Page>
			))}
		</Document>
	);
};
