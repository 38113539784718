import { Box, Skeleton, Spacer, Stack, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CustomFieldsGroupAPI } from '../../api/CustomFieldsGroupAPI';
import { UserType } from '../../api/UserApi';
import { CustomFieldsGroup } from '../../models/custom_fields_group';
import { ProfileSection } from '../../models/organization';
import { ObjectId } from '../../utils/ObjectId';
import { CustomFieldsGroupDisplay } from './CustomFieldsGroupDisplay';

interface ProfileSectionCustomFieldsGroupsProps {
    userType: UserType;
    profileSection: ProfileSection;
    userId: ObjectId;
}

export const ProfileSectionCustomFieldsGroups: React.FC<ProfileSectionCustomFieldsGroupsProps> = ({ userType, profileSection, userId }) => {

    const [groups, setGroups] = useState<CustomFieldsGroup[]>();
    const [loadingGroups, setLoadingGroups] = useState<boolean>(false);

    const toast = useToast();

    const fetchGroups = async () => {
        setLoadingGroups(true);

        try {
            const grps = await CustomFieldsGroupAPI.get_groups_for_user_type_and_profile_section(userType, profileSection)
            setGroups(grps);
        } catch (error: any) {
            toast({
                title: 'Error',
                description: "Failed to fetch fields data" + error.response.data,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setLoadingGroups(false);
        }
    };


    useEffect(() => {
        fetchGroups();
    }, []);

    return (
        <>
            <Box height={4} />
            {loadingGroups ? (
                <>
                    <Skeleton height="100px" />
                    <Skeleton height="100px" />
                    <Skeleton height="100px" />
                </>
            ) : (
                <Stack spacing={4}>
                    {groups?.map((group) => (
                        <CustomFieldsGroupDisplay key={group._id.toString()} group={group} userId={userId} />
                    ))}
                </Stack>
            )}
        </>
    );
}