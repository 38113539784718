import { OrganizationProfileSettings } from "../models/organization";
import HTTPApi from "./ApiCommon";

export namespace OrganizationAPI {

    export const get_organization_profile_settings = async () => {
        return HTTPApi.get("/organization/organization_profile_settings")
            .then((res) => {
                return res.data as OrganizationProfileSettings;
            })
    }
}