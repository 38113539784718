import React, { useState } from 'react';
import { Text } from '@chakra-ui/react';
import { Student } from '../../models/student'
import { TableContent } from '../table/TableContent'
import { ObjectId } from '../../utils/ObjectId'
import { DeleteDialog } from '../dialog/DeleteDialog'
import { RemoveIconButton } from '../layout/RemoveIconButton';

interface StudentsTableProps {
	students?: Student[]
	onDelete: (_id: ObjectId) => void
}

const SimpleStudentsList: React.FC<StudentsTableProps> = ({ students = [], onDelete }) => {
	const [isDialogOpen, setDialogOpen] = useState(false)
	const [selectedStudent, setSelectedStudent] = useState<ObjectId | null>(null)

	const openDialog = (studentId: ObjectId) => {
		setSelectedStudent(studentId)
		setDialogOpen(true)
	}

	const closeDialog = () => {
		setDialogOpen(false)
		setSelectedStudent(null)
	}

	const confirmRemoveStudent = async () => {
		if (!selectedStudent) return

		await onDelete(selectedStudent)

		closeDialog()
	}

	const columns = [
		{
			Header: 'Name',
			accessor: 'full_name',
			Cell: function cell(full_name: string) {
				return (
					<>
						<Text fontWeight={600}>
							{full_name}
						</Text>
					</>
				)
			}
		},
		{
			Header: '',
			accessor: '_id',
			Cell: (value : ObjectId) => (
				<div style={{ textAlign: 'right' }}>
					<RemoveIconButton
						onClick={() => openDialog(value)}
					/>
				</div>
			)
		}
	]

	return (
		<>
			<TableContent data={students} columns={columns} />

			<DeleteDialog
				item="student"
                isOpen={isDialogOpen}
                onClose={closeDialog}
                action={confirmRemoveStudent}
            />
		</>
	)
}

export default SimpleStudentsList
