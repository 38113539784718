import HTTPApi from "./ApiCommon";
import { ObjectId } from "../utils/ObjectId";
import { Teacher, TeacherList, TeacherProfile } from "../models/teacher";
import { DateTime } from "../utils/DateTime";
import { UserRole } from "./UserApi";



export namespace TeacherAPI {
    export const teachers_list = async (name_search: string | null = null, per_page: number | null = null, page: number | null = null): Promise<TeacherList | void> => {
        return HTTPApi.post(
                "teachers/teachers_list", {
                    per_page: per_page,
                    page: page,
                    name_search: name_search,
                })
                .then((res) => {
                    return res.data as TeacherList
                })
    }

    export const teachers_by_ids = async (teachers : ObjectId[]): Promise<Teacher[]> => {
        return HTTPApi.post(
                "teachers/teachers_by_ids", { teachers })
                .then((res) => {
                    return res.data as Teacher[]
                })
    }



    export const get_teacher = async (teacher_id: ObjectId) => {
        return HTTPApi.get("/teachers/" + teacher_id.$oid)
                .then((res) => {
                    return new Teacher(res.data)
                })
    }

    export const get_teacher_full_name = async (teacher_id: ObjectId) => {
        return HTTPApi.get("/teachers/" + teacher_id.$oid)
                .then((res) => {
                    let teacher = new Teacher(res.data)
                    return teacher.first_name + " " + teacher.last_name
                })
    }

    export const update_profile = async (teacher_id: ObjectId, profile: TeacherProfile) => {
        return HTTPApi.post(
            "teachers/update_profile", {
                _id: teacher_id,
                profile: profile
            }
        )
    }

    export const create_teacher = async (first_name: string, last_name: string, gender: string,phone_number: string,
                                         date_of_birth: DateTime, personal_email: string) => {
        return HTTPApi.post(
            "teachers/create", {
                first_name,
                last_name,
                gender,
                phone_number,
                date_of_birth,
                personal_email
            }
        ).then((res) => {
            return new Teacher(res.data)
        })
    }

    export const change_password = async (teacher_id: ObjectId, password: string, change_password_at_next_login: boolean) => {
        return HTTPApi.post(
            "teachers/change_password", {
                _id: teacher_id,
                password: password,
                change_password_at_next_login: change_password_at_next_login
            }
        )
    }

    export const withdraw = async (teacher_id: ObjectId) => {
        return HTTPApi.post(
            "teachers/withdraw", {
                _id: teacher_id
            }
        )
    }

    export const update_role = async (teacher_id: ObjectId, role: UserRole, remove: boolean = false) => {
        return HTTPApi.post(
            "teachers/update_role", {
                _id: teacher_id,
                role: role,
                remove: remove
            }
        )
    }
}
