import * as React from "react"
import {} from '@chakra-ui/react'
import {
    FiHome,
    FiCompass,
    FiStar,
    FiThumbsUp,
    FiBookOpen,
    FiActivity,
    FiUsers,
    FiUserCheck, FiBook, FiCode,
} from 'react-icons/fi';
import SimpleSidebar from "./3rd_party/SimpleSideBar";
import { useLocation, useNavigate } from "react-router-dom"
import LocalStorageService from "../api/LocalStorageService";
import { UserRole, UserType } from "../api/UserApi";
import { BiReceipt } from "react-icons/bi";



const LinkItems = [
    { name: 'Home', icon: FiHome, route: "/home" },
    { name: 'Classes', icon: FiBookOpen, route: "/class" },
    { name: 'Attendance', icon: FiCompass, route: "/attendance" },
    // { name: 'Violations', icon: FiAlertOctagon, route: "/violations" },
    { name: 'Report Card', icon: FiBook, route: "/reports" },
    { name: 'Mark Book', icon: FiBook, route: "/markbook" },
];

const LinkItemsParent = [
    { name: 'Home', icon: FiHome, route: "/parent/home" },
    { name: 'Family', icon: FiStar, route: "/parent/family" },
    { name: 'Invoices', icon: BiReceipt, route: "/parent/invoices" },
]

const LinkItemsAdmin = [
    ...LinkItems,
    { name: 'Family', icon: FiUsers, route: "/family" },
    { name: 'Students', icon: FiStar, route: "/students" },
    { name: 'Teachers', icon: FiStar, route: "/teachers" },
    { name: 'Invoices', icon: BiReceipt, route: "/invoices" },
    { name: 'Onboarding', icon: FiThumbsUp, route: "/onboarding" },
    { name: 'Re-Registration', icon: FiUserCheck, route: "/reregistration" },
    // { name: 'Scheduling', icon: FiCalendar, route: "/scheduling" },
    { name: 'Admin Portal', icon: FiCompass, route: "/admin_portal" }
];



export const Dashboard: React.FC = (props) =>  {
    let navigate = useNavigate();
    const location = useLocation();

    const user = LocalStorageService.getInstance().getUser()

    React.useEffect(() => {
        if (location.pathname === "/"){
            if(user!.user_type !== UserType.Parent){
                navigate("/home"); 
            }
            else{
                navigate("/parent/home")
            }
        }
        // dispatch(auth_token()).then((e)=> {
        //     if(!e.payload){
        //         dispatch(un_authenticate_user())
        //         navigate("/");
        //     }
        //     else{
        //         set_admin(user?.has_role(UserRole.Admin))
        //         set_parent(store.getState().CurrentUser.parent)
        //         if (location.pathname == "/"){
        //             if(!store.getState().CurrentUser.parent){
        //                 navigate("/home"); 
        //             }
        //             else{
        //                 navigate("/parent/home")
        //             }
        //         }
        //     }
        // })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const get_link_items = () => {
        if(user!.has_role(UserRole.Super_Admin)){
            let items = LinkItemsAdmin;
            if(user!.has_role(UserRole.Developer)){
                items = [
                    ...items,
                    { name: 'Developer Portal', icon: FiCode, route: "/developer_portal" }
                ]
            }
            return items;
        }
        let items = LinkItems;
        if(user!.has_role(UserRole.Can_Access_Family_Manager)){
            items = [
                ...items,
                { name: 'Family', icon: FiUsers, route: "/family" }
            ]
        }
        if(user!.has_role(UserRole.Can_Access_All_Student)){
            items = [
                ...items,
                { name: 'Students', icon: FiStar, route: "/students" }
            ]
        }
        if(user!.has_role(UserRole.Can_Access_All_Teacher)){
            items = [
                ...items,
                { name: 'Teachers', icon: FiStar, route: "/teachers" }
            ]
        }
        if(user!.has_role(UserRole.Can_Enroll_Student)){
            items = [
                ...items,
                { name: 'Onboarding', icon: FiThumbsUp, route: "/onboarding" },
                { name: 'Re-Registration', icon: FiUserCheck, route: "/reregistration" }
            ]
        }
        // if(user!.has_role(UserRole.Can_Modify_Scheduling)){
        //     items = [
        //         ...items,
        //         { name: 'Scheduling', icon: FiThumbsUp, route: "/scheduling" },
        //     ]
        // }
        if(user!.has_role(UserRole.Super_Admin)){
            items = [
                ...items,
                { name: 'Admin Portal', icon: FiThumbsUp, route: "/admin_portal" },
            ]
        }
        if(user!.has_role(UserRole.Developer)){
            items = [
                ...items,
                { name: 'Migrations', icon: FiActivity, route: "/migrations" }
            ]
        }
        return items
    }

    return (
        <>
            { user!.user_type === UserType.Parent ?
                        <SimpleSidebar link_items={LinkItemsParent}/>
                    :
                        <SimpleSidebar link_items={get_link_items()}/>
            }
        </>
    );
}
