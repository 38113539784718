import HTTPApi from "./ApiCommon";
import { ReportEntry, ReportEntryUpdate, RequestChangesReportEntry } from "../models/report_entry";
import { ObjectId } from "../utils/ObjectId";


export namespace ReportAPI {
    export const get_course_report_entries = async (courseId: string) : Promise<ReportEntry[]> => {
        return HTTPApi.get(`/reports/get_course_report_entries/${courseId}`)
            .then((res) => {
                return res.data.map((report : ReportEntry) => new ReportEntry(report))
            })
    }

    export const get_student_report_entries_for_course = async (student_id : ObjectId, course_id: ObjectId) => {
        return HTTPApi.post(`/reports/get_student_report_entries_for_course`, {student_id, course_id})
            .then((res) => {
                return res.data.map((report : ReportEntry) => new ReportEntry(report))
            })
    }

    export const get_homeroom_report_entries = async (homeroomId: string) => {
        return HTTPApi.get(`/reports/get_homeroom_report_entries/${homeroomId}`)
            .then((res) => {
                return res.data.map((report : ReportEntry) => new ReportEntry(report))
            })
    }

    export const get_student_report_entries_for_homeroom = async (student_id : ObjectId, homeroom_id: ObjectId) => {
        return HTTPApi.post(`/reports/get_student_report_entries_for_homeroom`, {student_id, homeroom_id})
            .then((res) => {
                return res.data.map((report : ReportEntry) => new ReportEntry(report))
            })
    }

    export const get_student_published_report_entries = async (studentId : string) => {
        return HTTPApi.get(`/reports/get_student_published_report_entries/${studentId}`)
            .then((res) => {
                return res.data.map((report : ReportEntry) => new ReportEntry(report))
            })
    }

    export const get_report_entries_queue = async (teacher_id?: ObjectId, student_id?: ObjectId, semester_id?: ObjectId, ) => {
        return HTTPApi.post("/reports/get_report_entries_queue", { teacher_id, student_id, semester_id })
            .then((res) => {
                return res.data.map((report : ReportEntry) => new ReportEntry(report))
            })
    }

    export const get_report_entry = async (reportId: string) => {
        return HTTPApi.get(`/reports/get_report_entry/${reportId}`)
            .then((res) => {
                return new ReportEntry(res.data)
            })
    }

    export const generate_report_entry_smart_comment = async (reportId: string, optional_prompt: string | undefined) => {
        return HTTPApi.post(`/reports/generate_report_entry_smart_comment/${reportId}`, {
            optional_prompt
        })
            .then((res) => {
                return String(res.data)
            })
    }

    export const update_report_entry = async (reportEntry: ReportEntryUpdate) => {
        return HTTPApi.post("/reports/update", { report_entry: reportEntry })
            .then((res) => {
                return new ReportEntry(res.data)
            })
    }

    export const request_changes = async (data: RequestChangesReportEntry) => {
        return HTTPApi.post("/reports/request_changes", data)
            .then((res) => {
                return res.data as ReportEntry
            })
    }

    export const approve = async (data: { report_id : ObjectId}) => {
        return HTTPApi.post("/reports/approve", data)
            .then((res) => {
                return res.data as ReportEntry
            })
    }

    export const unpublish = async (data: RequestChangesReportEntry) => {
        return HTTPApi.post("/reports/unpublish", data)
            .then((res) => {
                return res.data as ReportEntry
            })
    }

    export const publish = async (data: { report_id : ObjectId}) => {
        return HTTPApi.post("/reports/publish", data)
            .then((res) => {
                return res.data as ReportEntry
            })
    }
}
