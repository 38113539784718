import React, { useState, useEffect, useCallback } from 'react';
import { Box, FormLabel } from '@chakra-ui/react';
import { Select as SearchSelect } from 'chakra-react-select'
import { ObjectId } from '../../utils/ObjectId';
import { InvoiceResponse, InvoiceUserCategory } from '../../models/invoice';
import { InvoiceAPI } from '../../api/InvoiceAPI';
import StudentLookup from '../students/StudentsLookup';
import { FlexWrap } from '../layout/FlexWrap';
import EmptyPlaceholder from '../layout/EmptyPlaceholder';
import { InvoicesTable } from './InvoicesTable';
import FamilyLookup from '../family/FamilyLooup';

export const UserInvoices: React.FC = React.memo(() => {

	const [selectedStudent, setselectedStudent] = useState<ObjectId>();
	const [selectedFamily, setSelectedFmaily] = useState<ObjectId>();
	const [invoices, setInvoices] = useState<InvoiceResponse[]>([]);
	const [userCategory, setUserCategory] = useState<InvoiceUserCategory>(InvoiceUserCategory.Student);
	const [loading, setLoading] = useState<boolean>(false);

	const handleSelectStudent = useCallback((studentId?: ObjectId) => {
		setselectedStudent(studentId);
	}, []);

	const handleSelectFamily = useCallback((familyId?: ObjectId) => {
		setSelectedFmaily(familyId);
	}, []);

	const handleUserCategoryChange = (category: InvoiceUserCategory) => {
		setUserCategory(category);
		setselectedStudent(undefined); 
		setSelectedFmaily(undefined); 
  	};

	useEffect(() => {
		const fetchInvoices = async () => {
			if (!selectedStudent && !selectedFamily) {
				setInvoices([]);
				return;
			}

			setLoading(true);

			try {
				let response: InvoiceResponse[] = [];

				if (selectedStudent && userCategory === InvoiceUserCategory.Student) {
					response = await InvoiceAPI.user_invoices(selectedStudent);
				} else if (selectedFamily && userCategory === InvoiceUserCategory.Family) {
					response = await InvoiceAPI.family_invoices(selectedFamily);
				}

				response = response.map(i => ({
					...i,
					link: `/invoice/${i.invoice._id.$oid}`
				}))

				setInvoices(response);
			} catch (err) {
				console.error('Error fetching invoices:', err);
				setInvoices([]);
			} finally {
				setLoading(false);
			}
		};

		fetchInvoices();
	}, [selectedStudent, selectedFamily]);

	return (
		<>
			<FlexWrap mb={4}>
				<Box minW={{ md: 350 }}>
					<FormLabel>Show Invoices For</FormLabel>

					<SearchSelect
						options={[
							{ label: 'Student', value: InvoiceUserCategory.Student}, 
							{ label: 'Family', value: InvoiceUserCategory.Family}
						]}
						placeholder='Select a teacher...' 
						onChange={e => handleUserCategoryChange(e?.value!)}
					/>
				</Box>

				<Box minW={{md: 350}}>
					<FormLabel>Search by name</FormLabel>

					{userCategory === InvoiceUserCategory.Student && (
						<StudentLookup onSelect={handleSelectStudent} />
					)}
					{userCategory === InvoiceUserCategory.Family && (
						<FamilyLookup onSelect={handleSelectFamily} />
					)}
				</Box>
			</FlexWrap>

			{!loading && !selectedStudent && !selectedFamily ? (
				<EmptyPlaceholder
					title={`Select a ${userCategory === InvoiceUserCategory.Student ? 'student' : 'family'}`}
					message={`in order to view ${userCategory === InvoiceUserCategory.Student ? 'their' : 'its'} invoices`}
				/>
			) : (
				<InvoicesTable invoices={invoices} loading={loading} />
			)}
		</>
	);
});
