import {
    Button,
    Stack,
    AlertDialogFooter,
    AlertDialogBody,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialog,
    useToast,
} from '@chakra-ui/react';
import { memo } from "react";
import { useForm } from "react-hook-form";
import { CustomFieldsGroupAPI } from '../../api/CustomFieldsGroupAPI';
import { CreateCustomFieldsGroup, CustomFieldsGroup } from '../../models/custom_fields_group';
import { UserType } from '../../api/UserApi';
import { customFieldsUserTypes } from './CustomFields';
import { ProfileSection } from '../../models/organization';
import FormInput from '../forms/FormInput';
import FormSelect from '../forms/FormSelect';
import { yupResolver } from '@hookform/resolvers/yup';
import { createCustomFieldsGroupSchema } from '../../validations/custom_fields';

interface CreateCustomFieldsGroupDialogProps {
    isOpen: boolean;
    onClose: () => void;
    onGroupAdded: (group: CustomFieldsGroup) => void;
    initialUserType: UserType;
    userTypeProfileSections: Map<UserType, ProfileSection[]>;
}

export const CreateCustomFieldsGroupDialog = memo(
    ({ isOpen, onClose, onGroupAdded, initialUserType, userTypeProfileSections }: CreateCustomFieldsGroupDialogProps) => {

        const toast = useToast();

        const { register, handleSubmit, reset, setValue, watch, formState: { errors, isSubmitting } } = useForm({
            defaultValues: {
                user_type: initialUserType,
                profile_section: userTypeProfileSections.get(initialUserType)![0],
            },
            resolver: yupResolver(createCustomFieldsGroupSchema)
        });

        const userType = watch("user_type");
        const profileSectionsList = userTypeProfileSections.get(userType)!;

        const handleUserTypeChange = (selectedValue: UserType) => {
            setValue("user_type", selectedValue);
            setValue("profile_section", userTypeProfileSections.get(selectedValue)![0]);
        };

        const handleProfileSectionChange = (selectedValue: ProfileSection) => {
            setValue("profile_section", selectedValue);
        };

        const submitNewGroup = async (data: CreateCustomFieldsGroup) => {
            try {
                const newGroup = await CustomFieldsGroupAPI.create_new_custom_fields_group(data);

                toast({
                    title: 'Success',
                    description: "Group created successfully",
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });

                reset();
                onGroupAdded(newGroup);
                onClose();
            } catch (error: any) {
                toast({
                    title: 'Error',
                    description: "Failed to create a field: " + error.response.data,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        };

        return (
            <AlertDialog
                isOpen={isOpen}
                onClose={onClose}
                leastDestructiveRef={undefined}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Create Custom Fields Group
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            <form onSubmit={handleSubmit(submitNewGroup)}>
                                <Stack gap="4">

                                    <FormInput
                                        name='group_name'
                                        label='Group Name'
                                        register={register('group_name', { required: true })}
                                        placeholder="Enter group name"
                                        error={errors.group_name?.message}
                                        isRequired
                                    />

                                    <FormSelect
                                        name='user_type'
                                        label='User Type'
                                        options={customFieldsUserTypes}
                                        register={{
                                            ...register("user_type", {
                                                onChange: (event) => handleUserTypeChange(event.target.value as UserType),
                                            }),
                                        }}
                                        error={errors.user_type?.message}
                                        isRequired
                                    />

                                    <FormSelect
                                        name='profile_section'
                                        label='Profile Section'
                                        options={profileSectionsList}
                                        register={{
                                            ...register("profile_section", {
                                                onChange: (event) => handleProfileSectionChange(event.target.value as ProfileSection),
                                            }),
                                        }}
                                        error={errors.profile_section?.message}
                                        isRequired
                                    />

                                </Stack>
                            </form>
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button onClick={onClose} isDisabled={isSubmitting}>
                                Cancel
                            </Button>
                            <Button
                                colorScheme="green"
                                onClick={handleSubmit(submitNewGroup)}
                                isLoading={isSubmitting}
                                ml={3}
                            >
                                Submit
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        );
    }
);