import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Spacer,
  Stack,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { Coupon, CouponType } from "../../models/invoice";
import { RemoveIconButton } from "../layout/RemoveIconButton";
import FormInput from "../forms/FormInput";
import FormSelect from "../forms/FormSelect";

interface CouponDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (couponData?: Coupon) => void
  initialData?: Coupon
}

interface CouponFormData {
	title: string;
	discountType: "Percentage" | "Amount";
	discountValue: number;
}

export const CouponDialog: React.FC<CouponDialogProps> = ({
  isOpen,
  onClose,
  onSubmit,
  initialData
}) => {
  const { register, handleSubmit, reset, formState: { errors } } = useForm<CouponFormData>({
		defaultValues: initialData ? {
			title: initialData.title,
			discountType: initialData.coupon_type.Percentage !== undefined ? "Percentage" : "Amount",
			discountValue: initialData.coupon_type.Percentage ?? initialData.coupon_type.Amount ?? 0,
		} : {
			title: "",
			discountType: "Percentage",
			discountValue: 0,
		}
  });

  const onFormSubmit = (data: CouponFormData) => {
		const { title, discountType, discountValue } = data;

		let coupon_type: CouponType;
		if (discountType === "Percentage") coupon_type = { Percentage: discountValue };
		else  coupon_type = { Amount: discountValue };

		const coupon: Coupon = { title, coupon_type };

		onSubmit(coupon);
		reset();
  };


  const handleDelete = () => {
    onSubmit(undefined);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Coupon</ModalHeader>
        <ModalCloseButton />

        <form onSubmit={handleSubmit(onFormSubmit)}>
          <ModalBody as={Stack} gap={2}>
            <FormInput
							name="title"
							label="Title"
							register={register('title')}
							error={errors.title?.message}
							isRequired
						/>

						<FormSelect
							name="discountType"
							label="Discount Type"
							options={['Percentage', 'Amount']}
							register={register('discountType')}
							error={errors.discountType?.message}
							isRequired
						/>

						<FormInput
							name="discountValue"
							label="Discount Value"
							register={register('discountValue')}
							error={errors.discountValue?.message}
							isRequired
						/>
          </ModalBody>

          <ModalFooter>
            {initialData && (
              <RemoveIconButton
                onClick={handleDelete}
              />
            )}

            <Spacer />

            <Button variant="ghost" onClick={onClose} mr={3}>
              Cancel
            </Button>

            <Button colorScheme="blue" type="submit">
              Save
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};