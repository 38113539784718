import React, { useEffect } from "react"
import {
    Box,
    Center,
    Heading,
    Button,
    VStack,
    Stack,
    HStack,
    Spacer,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Text,
    Divider,
    useToast,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    Skeleton,
    FormControl,
    FormLabel,
    Input,
    Select,
    AlertDialogFooter,
} from '@chakra-ui/react';
import { Link, useNavigate } from "react-router-dom";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { Family } from "../../models/family";
import { FamilyAPI } from "../../api/FamilyAPI";
import { FamilyStudentList } from "./FamilyStudentList";
import { LightButton } from "../layout/LightButton";
import { FiChevronDown, FiEdit2, FiPlus } from "react-icons/fi";
import { BlCard } from "../layout/Card";
import { StudentAPI } from "../../api/StudentAPI";
import { Grades } from "../../models/student";
import { FlexWrap } from "../layout/FlexWrap";
import { DateTime } from "../../utils/DateTime";
import DatePicker from "../DatePicker";


export const FamilyList: React.FC = () => {

    const navigate = useNavigate()
    const [families, set_families] = React.useState<Family[]>([])

    const [isOpen, setIsOpen] = React.useState(false)

    const onClose = () => {
      setIsOpen(false)

      get_family()
    }

    const get_family = () => {
      return FamilyAPI.get_parent_families().then(res => set_families(res))
    }

    useEffect(() => {
      get_family()
    }, []);

    return (
            <Box>
                <Box overflowX="auto" overflowY="hidden">
                    <Center>
                        <Heading size="md" mb="6">
                            Parent's Dashboard
                        </Heading>
                        
                    </Center>
                    <Stack>
                        <HStack mb={4}>
                            <Breadcrumb spacing='8px' separator={<ChevronRightIcon color='gray.500' />}>
                                <BreadcrumbItem>
                                    <BreadcrumbLink href='/parent/home'>Home</BreadcrumbLink>
                                </BreadcrumbItem>

                                <BreadcrumbItem isCurrentPage>
                                    <BreadcrumbLink href='#'>Family</BreadcrumbLink>
                                </BreadcrumbItem>
                            </Breadcrumb>
                            <Spacer/>
                            <Link to="/parent/family/create">
                              <Button colorScheme={"green"}>Create New Family</Button>
                            </Link>
                        </HStack>

                        { families.length > 0 ?
                          <>
                          { families.map((family) => (
                              <>
                              <BlCard>
                                <FlexWrap>
                                  <Text fontSize={"lg"} fontWeight="bold">
                                      {family.name} Family
                                  </Text>

                                  <Spacer />

                                  <LightButton 
                                    leftIcon={<FiEdit2 />}
                                    color="blue" 
                                    onClick={() => navigate("/parent/family/update/" + family._id.$oid)}
                                  >
                                    Edit Family
                                  </LightButton>

                                  <LightButton 
                                      color='green'
                                      leftIcon={<FiPlus/>}
                                      onClick={() => setIsOpen(true)}
                                  >
                                      Add Student
                                  </LightButton>

                                  <CreateStudentDialog 
                                      family={family}
                                      isOpen={isOpen} 
                                      onClose={onClose}
                                  />
                                </FlexWrap>

                                <FamilyStudentList family={family}/>
                              </BlCard>


                              <Divider />
                              </>
                          ))}
                          </>
                          :
                          <>
                          <Center>
                                <VStack>
                                    <Text>No Family...</Text>
                                    <Text>Add a family using the above button to the right</Text>
                                </VStack>   
                            </Center>
                          </>

                        }

                    </Stack>
                </Box>
            </Box>
    );
}

interface CreateStudentDialogProps {
  family: Family
  isOpen: boolean
  onClose: any
}


const CreateStudentDialog = React.memo(({family, isOpen, onClose}: CreateStudentDialogProps) => {
  const toast = useToast() 
  const [loading, set_loading] = React.useState<boolean>(false);

  const [first_name, set_first_name] = React.useState<string>("");
  const [last_name, set_last_name] = React.useState<string>("");
  const [grade, set_grade] = React.useState<string>()
  const [gender, set_gender] = React.useState<string>()
  const [date_of_birth, set_date_of_birth] = React.useState<DateTime>();
  
	const cancelRef = React.useRef<HTMLButtonElement>(null)

  const create = () => {
      if(first_name && last_name && grade && gender && date_of_birth){
          set_loading(true)
          StudentAPI.new_student(first_name, last_name, grade, gender, family._id, date_of_birth)
              .then((res) => {
                  set_loading(false)
                  cancel()
              })
              .catch((err) => {
                  toast({
                      title: 'Error.',
                      description: "An Error Occurred",
                      status: 'error',
                      duration: 5000,
                      isClosable: true,
                    })
                  set_loading(false)
                  cancel()
              })
      }else{
          toast({
              title: 'Error.',
              description: "Please fill in all the required information",
              status: 'error',
              duration: 5000,
              isClosable: true,
            })
      }
  }

  const handleDateOfBirthChange = (date: any) => {
    if(date instanceof Date) {
      set_date_of_birth(DateTime.from_date(date));
    }
  }

  const cancel = () => {
      set_first_name("")
      set_last_name("")
      set_grade(undefined)
      set_gender(undefined)
      set_date_of_birth(undefined);
      onClose()
  }

  return (
      <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            Create Student
          </AlertDialogHeader>

          <AlertDialogBody>
            {loading ?
              <Stack>
                <Skeleton height='20px' />
                <Skeleton height='20px' />
                <Skeleton height='20px' />
              </Stack>
              :
              <>
                <FormControl id="first_name" isRequired>
                  <FormLabel>First Name</FormLabel>
                  <Input isRequired
                      value={first_name}
                      onChange={(value) => {set_first_name(value.target.value)}}
                  />
                </FormControl>
                <br></br>
                <FormControl id="last_name" isRequired>
                  <FormLabel>Last Name</FormLabel>
                  <Input isRequired
                      value={last_name}
                      onChange={(value) => {set_last_name(value.target.value)}}
                  />
                </FormControl>
                <br></br>
                <FormControl id="grade" isRequired>
                  <FormLabel>Incoming Grade</FormLabel>
                  <Select isRequired
                          value={grade}
                          placeholder="Please Select the Grade"
                          icon={<FiChevronDown/>}
                          onChange={(value) => {
                              set_grade(value.target.value)
                          }}
                      >   
                          {   
                              Array.from(Grades).map(([key, value]) => (
                                  <option value={key}>{value}</option>
                              ))  
                          }
                      </Select>
                </FormControl>
                <br></br>
                <FormControl id="gender" isRequired>
                  <FormLabel>Gender</FormLabel>
                  <Select isRequired
                          value={gender}
                          placeholder="Please Select the Gender"
                          icon={<FiChevronDown/>}
                          onChange={(value) => {
                              set_gender(value.target.value)
                          }}
                      >   
                            
                              <option value="F">Female</option>
                              <option value="M">Male</option>
                          
                      </Select>
                </FormControl>
                <br></br>
                <FormControl id="date_of_birth" isRequired>
                        <FormLabel>Date of Birth</FormLabel>
                        <DatePicker 
                            selectedDate={date_of_birth?.to_date()} 
                            onChange={(value) => { handleDateOfBirthChange(value) }}
                        />
                </FormControl>
                <br></br>
              </>
            }
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={cancel}>
              Cancel
            </Button>
            <Button colorScheme='green' onClick={create} ml={3}>
              Create
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )

})
