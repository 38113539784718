import * as React from "react"
import { useState } from "react";
import { Alert, AlertIcon, Container, Center, Button, Text, useToast, Heading, Image, Box, Spinner, Stack, AlertTitle } from '@chakra-ui/react';
import { MdEmail } from "react-icons/md";
import { AiOutlineLogin } from "react-icons/ai";
import { Link, useLocation, useNavigate } from "react-router-dom";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import logo from "../images/logo_transparent.png"
import { getAnalytics } from "firebase/analytics";
import LocalStorageService from "../api/LocalStorageService";
import { AuthLayout } from "./auth/authLayout";
import '../css/login.css';
import { UserType } from "../api/UserApi";

// Configure Firebase.
const firebaseConfig = {
    apiKey: "AIzaSyBFCKM5vQB5j2wb2HR6IuWaKIk6lovOrbg",
    authDomain: "bassemlabs.firebaseapp.com",
    projectId: "bassemlabs",
    storageBucket: "bassemlabs.appspot.com",
    messagingSenderId: "113165612533",
    appId: "1:113165612533:web:d0a39f5696004ea92ddcbc",
    measurementId: "G-L269QJ05NQ"
};

firebase.initializeApp(firebaseConfig);
getAnalytics(firebase.app())

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false,
  },
}

interface props {
    type: UserType
}

export const Login: React.FC<props> = ({ type }) =>  {
    let navigate = useNavigate();
    const location = useLocation(); // Added to retrieve the original path
    const from = (location.state as { from: { pathname: string } } | undefined)?.from?.pathname || "/";
    const toast = useToast()

    const [isSignedIn, setIsSignedIn] = useState<boolean | undefined>(undefined); // Local signed-in state.
    const [isVerified, setIsVerified] = useState<boolean | undefined>(firebase.auth().currentUser?.emailVerified);
    const [loading, setLoading] = useState<boolean>(false);
    const [invalidUserType, setinvalidUserType] = useState<UserType>();

    const previousValues = React.useRef({ isSignedIn, isVerified });

    // Listen to the Firebase Auth state and set the local state.
    React.useEffect(() => {
      const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
        setIsSignedIn(!!user);
        if(!!user){
            setIsVerified(user.emailVerified)
        }else{
            setIsVerified(false)
        }
      });
      return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, []);

    React.useEffect(() => {
        if (previousValues.current.isSignedIn !== isSignedIn && previousValues.current.isVerified !== isVerified) {
            previousValues.current = { isSignedIn, isVerified };
        }

        // Authenticate
        if (isSignedIn && isVerified) {
            setLoading(true)
            LocalStorageService.getInstance().setToken(firebase.auth().currentUser!.refreshToken).then((res) => {
                setLoading(false)

                let user = LocalStorageService.getInstance().getUser();

                if (user?.user_type !== type) setinvalidUserType(user?.user_type)
                else navigate(from, { replace: true }); // Redirects to the original path or "/"
                

            })
        }
    }, [isSignedIn, isVerified, from, navigate]); // Added from and navigate to the dependency array

    const sign_out = () => {
        firebase.auth().signOut()
        LocalStorageService.getInstance().clear()
    }

    const onResetLoginClicked = () => {
        sign_out()

        navigate(0)
    }

    const sendVerificationEmail = () => {
        firebase.auth().currentUser!.reload()
            .then(()=> {
                if(!firebase.auth().currentUser!.emailVerified){
                    firebase.auth().currentUser!.sendEmailVerification()
                    toast({
                        title: 'Email Sent.',
                        description: "We've sent your verification email to you.",
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })
                }
                else{
                    setIsVerified(firebase.auth().currentUser!.emailVerified)
                    navigate("/")
                }
            })
    }


    return (
        <AuthLayout>
        <Center>   
            {
                isSignedIn && !isVerified ?
                    <Container>
                        <Text>Please check your email to verify your account: {firebase.auth().currentUser!.email}</Text>
                        <Text>If you have not received an email please click below and wait up to 5 minutes before pressing again :)</Text>
                        <br></br>
                        <Center><Button onClick={sendVerificationEmail} colorScheme="blue">Send Email</Button></Center>
                            <br></br>
                            <Center><Button onClick={sign_out} colorScheme="red">Sign-out</Button></Center>
                    </Container>
                :
                    <Container>
                        <Container maxW='container.sm' mb="12" maxWidth={"xl"}>
                            <Image src={logo}></Image>
                        </Container>
                        
                            <Center>
                                <Heading size='sm'>
                                    { loading ? "Please wait while you are being logged in" : "Sign-in:" }
                                </Heading>
                            </Center>

                            <br />

                            { invalidUserType ? (
                                <Stack align={"center"} maxW="500" mx="auto">
                                    <Alert
                                        status='warning' 
                                        variant='subtle'
                                        rounded="md"
                                        flexDirection='column'
                                        alignItems='center'
                                        justifyContent='center'
                                        textAlign='center'
                                        gap='2'
                                        mb="4"
                                    >
                                        <AlertIcon boxSize='36px' />

                                        <AlertTitle>Account Type Mismatch</AlertTitle>

                                        <span>
                                            It looks like your account is a {invalidUserType} account.
                                            { type === UserType.Teacher && 
                                                <span> To log in as a Teacher, please use your organization email. </span>
                                            }
                                            { type === UserType.Parent && 
                                                <span> To log in as a Parent, please use your personal email. </span>
                                            }
                                        </span>
                                    </Alert>

                                    <Button as={Link} to="/" minW="220" leftIcon={<MdEmail />} colorScheme="primary" >
                                        Continue As {invalidUserType}
                                    </Button>

                                    <Button minW="220" leftIcon={<AiOutlineLogin />} border="1px solid #8d53a2" color="#8d53a2" colorScheme="white" onClick={onResetLoginClicked}>
                                        Login As {type}
                                    </Button>

                                </Stack>
                            ) : (
                                <div>
                                    <Box display={loading ? "none" : "block"}>
                                        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
                                    </Box>

                                    { loading && <Center><Spinner color="primary" /></Center> }
                                </div>
                            )}
                    </Container>
            }
            
        </Center>
        </AuthLayout>
    );
}