import * as yup from "yup";
import { UserType } from "../api/UserApi";
import { CustomFieldType } from "../models/custom_field";
import { ProfileSection } from "../models/organization";

export const createCustomFieldSchema = yup.object().shape({
    field_name: yup.string().required("Field name is required"),
    field_type: yup
        .mixed<CustomFieldType>()
        .oneOf(Object.values(CustomFieldType), "Invalid field type")
        .required("Field type is required"),
    is_required: yup.boolean().required("This field is required"),
    description: yup.string().default("").optional(),
    regex_pattern: yup.string().optional(),
    options: yup
        .array()
        .of(yup.string().required("Option cannot be empty"))
        .optional(),
    is_archived: yup.boolean().default(false).optional(),
});


export const createCustomFieldsGroupSchema = yup.object().shape({
    group_name: yup.string().required("Group name is required"),
    user_type: yup.mixed<UserType>().required("User type is required"),
    profile_section: yup.mixed<ProfileSection>().required("Profile section is required"),
});