import React, { useEffect, useState } from "react";
import {
    Box,
    FormControl,
    IconButton,
    Input,
    Select,
    useDisclosure,
    useToast,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    ButtonGroup,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { EvaluationAPI } from "../../../api/EvaluationAPI";
import { MarkCategoriesAPI } from "../../../api/MarkCategoryAPI";
import { DateTime } from "../../../utils/DateTime";
import DatePicker from "../../DatePicker";
import { DeleteDialog } from "../../dialog/DeleteDialog";
import { ObjectId } from "../../../utils/ObjectId";
import { Evaluation } from "../../../models/evaluation";
import { MarkCategory } from "../../../models/mark_category";

interface EvaluationsTableProps {
    evaluations: Evaluation[];
    onEvaluationSelect: (evaluation: Evaluation) => void;
    refreshEvaluations: () => void;
}

export const EvaluationsTable = ({
                                     evaluations,
                                     onEvaluationSelect,
                                     refreshEvaluations,
                                 }: EvaluationsTableProps) => {
    const toast = useToast();

    const [markCategories, setMarkCategories] = useState<MarkCategory[]>([]);

    useEffect(() => {
        const fetchMarkCategories = async () => {
            try {
                const categories = await MarkCategoriesAPI.get_mark_category_list_from_class(
                    evaluations[0]?.class_id
                );
                setMarkCategories(categories || []);
            } catch (error) {
                console.error("Failed to fetch mark categories:", error);
            }
        };
        if (evaluations.length > 0) {
            fetchMarkCategories();
        }
    }, [evaluations]);

    const handleUpdateEvaluation = async (updatedEvaluation: Evaluation) => {
        try {
            await EvaluationAPI.update_evaluation(updatedEvaluation);
            toast({
                title: "Success",
                description: `Updated ${updatedEvaluation.name} successfully.`,
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            refreshEvaluations();
        } catch (error) {
            console.error("Failed to update evaluation:", error);
            toast({
                title: "Error",
                description: "Failed to update evaluation.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleDeleteEvaluation = async (evaluation_to_delete: Evaluation) => {
        try {
            await EvaluationAPI.delete_evaluation(evaluation_to_delete._id);
            toast({
                title: "Success",
                description: `Deleted ${evaluation_to_delete.name} successfully.`,
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            refreshEvaluations();
        } catch (error) {
            console.error("Failed to delete evaluation:", error);
            toast({
                title: "Error",
                description: "Failed to delete evaluation.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    return (
        <Box overflowX="auto">
            <Table variant="simple">
                <Thead>
                    <Tr>
                        <Th>Evaluation</Th>
                        <Th>Date</Th>
                        <Th>Mark Category</Th>
                        <Th>Total</Th>
                        <Th>Weight</Th>
                        <Th>Actions</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {evaluations.length > 0 ? (
                        evaluations.map((evaluation) => (
                            <EvaluationRow
                                key={evaluation._id.$oid}
                                evaluation={evaluation}
                                markCategories={markCategories}
                                onEvaluationSelect={onEvaluationSelect}
                                handleUpdateEvaluation={handleUpdateEvaluation}
                                handleDeleteEvaluation={handleDeleteEvaluation}
                            />
                        ))
                    ) : (
                        <Tr>
                            <Td colSpan={6} textAlign="center" py={4}>
                                No evaluations available.
                            </Td>
                        </Tr>
                    )}
                </Tbody>
            </Table>
        </Box>
    );
};

interface EvaluationRowProps {
    evaluation: Evaluation;
    markCategories: MarkCategory[];
    onEvaluationSelect: (evaluation: Evaluation) => void;
    handleUpdateEvaluation: (updatedEvaluation: Evaluation) => void;
    handleDeleteEvaluation: (evaluation: Evaluation) => void;
}

const EvaluationRow = ({evaluation, markCategories, onEvaluationSelect, handleUpdateEvaluation, handleDeleteEvaluation}: EvaluationRowProps) => {
    const [name, setName] = useState(evaluation.name);
    const [date, setDate] = useState(new Date(parseInt(evaluation.date.$date.$numberLong)));
    const [markCategoryId, setMarkCategoryId] = useState(evaluation.mark_category?.$oid || "");
    const [total, setTotal] = useState(evaluation.total);
    const [weight, setWeight] = useState(evaluation.weight);

    const onBlurHandler = () => {
        if (
            name !== evaluation.name ||
            date.getTime() !== parseInt(evaluation.date.$date.$numberLong) ||
            markCategoryId !== (evaluation.mark_category?.$oid || "") ||
            total !== evaluation.total ||
            weight !== evaluation.weight
        ) {
            handleUpdateEvaluation({
                ...evaluation,
                name,
                date: DateTime.from_date(date),
                mark_category: markCategoryId ? { $oid: markCategoryId } : undefined,
                total,
                weight,
            });
        }
    };

    return (
        <Tr
            onClick={() => onEvaluationSelect(evaluation)}
            _hover={{ bg: "gray.100", cursor: "pointer" }}
        >
            <Td>
                <Input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    onBlur={onBlurHandler}
                    variant="filled"
                />
            </Td>
            <Td>
                <DatePicker
                    selectedDate={date}
                    onChange={(selectedDate) => {
                        setDate(selectedDate as Date);
                        handleUpdateEvaluation({
                            ...evaluation,
                            date: DateTime.from_date(selectedDate as Date),
                            name,
                            mark_category: markCategoryId ? { $oid: markCategoryId } : undefined,
                            total,
                            weight,
                        });
                    }}
                    isClearable={true}
                    showPopperArrow={true}
                />
            </Td>
            <Td>
                <FormControl>
                    <Select
                        placeholder="Select category"
                        value={markCategoryId}
                        onChange={(e) => setMarkCategoryId(e.target.value)}
                        onBlur={onBlurHandler}
                    >
                        {markCategories.map((category) => (
                            <option key={category._id.$oid} value={category._id.$oid}>
                                {category.name}
                            </option>
                        ))}
                    </Select>
                </FormControl>
            </Td>
            <Td>
                <Input
                    type="number"
                    value={total}
                    onChange={(e) => setTotal(Number(e.target.value))}
                    onBlur={onBlurHandler}
                    variant="filled"
                />
            </Td>
            <Td>
                <Input
                    type="number"
                    value={weight}
                    onChange={(e) => setWeight(Number(e.target.value))}
                    onBlur={onBlurHandler}
                    variant="filled"
                />
            </Td>
            <Td>
                <ButtonGroup variant="ghost" size="sm">
                    <DeleteEvaluationButton
                        evaluationId={evaluation._id}
                        onDelete={() => handleDeleteEvaluation(evaluation)}
                    />
                </ButtonGroup>
            </Td>
        </Tr>
    );
};

interface DeleteEvaluationButtonProps {
    evaluationId: ObjectId;
    onDelete: () => void;
}

const DeleteEvaluationButton = ({ evaluationId, onDelete }: DeleteEvaluationButtonProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <IconButton
                aria-label="Delete Evaluation"
                icon={<DeleteIcon />}
                colorScheme="red"
                onClick={onOpen}
            />
            <DeleteDialog isOpen={isOpen} onClose={onClose} action={onDelete} />
        </>
    );
};