
import * as React from "react"
import {
  ChakraProvider,
  extendTheme,
} from "@chakra-ui/react"
import { theme as proTheme} from '@chakra-ui/pro-theme'
import './css/app.css'
import { Login } from "./components/Login"
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate
} from "react-router-dom";
import { Dashboard } from "./components/Dashboard"
import { RequireAuth, RequireAuthTeacher } from "./components/RequireAuth"
import { Home } from "./components/Home";
import NotFound from "./components/3rd_party/NotFound";
import { Attendance } from "./components/attendance/Attendance";
import SetupInterceptors from "./utils/SetupInterceptors";
import { AttendanceHomeroom } from "./components/attendance/AttendanceHomeroom";
import { AttendanceCourse } from "./components/attendance/AttendanceCourse";
import { Students } from "./components/students/Students";
import { StudentProfileRoute } from "./components/students/StudentProfileRoute";
import { Reports } from "./components/reports/Reports";
import { Violations } from "./components/violations/Violations";
import { ViolationsHomeroom } from "./components/violations/ViolationsHomeroom";
import { ViolationsCourse } from "./components/violations/ViolationsCourse";
import { Markbook } from "./components/markbook/Markbook/Markbook";
import { MarkbookCourse } from "./components/markbook/Markbook/MarkbookCourse";
import { HomeParent } from "./components/parents/HomeParent";
import PaymentConfirmed from "./components/payments/PaymentConfirmed";
import { OnboardingList } from "./components/students/onboarding/OnboardingList";
import { FormStatusRoute } from "./components/parents/FormStatusRoute";
import { Class } from "./components/classes/Class";
import { HomeroomDashboard } from "./components/classes/HomeroomDashboard";
import { CourseDashboard } from "./components/classes/CourseDashboard";
import { TeacherProfileRoute } from "./components/teachers/TeacherProfileRoute";
import { TeacherListRoute } from "./components/teachers/TeacherListRoute";
import { PrintableStudent } from "./components/students/PrintableStudent";
import { Migrations } from "./components/migrations/Migrations";
import { AttendanceDownload } from "./components/attendance/AttendanceDownload";
import { FamilyList } from "./components/parents/FamilyList";
import { FamilyCreate } from "./components/parents/FamilyCreate";
import { FamilyEdit } from "./components/parents/FamilyEdit";
import { FamilyManagerList } from "./components/family/FamilyManagerList";
import { FamilyRoute } from "./components/family/FamilyRoute";
import { ParentStudent } from "./components/parents/ParentStudent";
import { ReRegistrationList } from "./components/students/reregistration/ReRegistrationList";
import {AdminPortal} from "./components/admin_portal/AdminPortal";
import {SemesterDashboard} from "./components/semesters/SemesterDashboard";
import { StudentCourseDashboard } from "./components/students/StudentCourseDashboard";
import { StudentHomeroomDashboard } from "./components/students/StudentHomeroomDashboard";
import { UserType } from "./api/UserApi";
import { LoginSelector } from "./components/LoginSelector";
import { AttendanceCourseParents } from "./components/parents_portal/AttendanceCourseParents";
import { AttendanceHomeroomParents } from "./components/parents_portal/AttendanceHomeroomParents";
import { HomeroomCoursesList } from "./components/classes/HomeroomCoursesList";
import { CourseReportDashboard } from "./components/reports/CourseReportDashboard";
import { HomeroomReportDashboard } from "./components/reports/HomeroomReportDashboard"
import { NotificationDetail } from "./components/notifications/Notification";
import {AiInteractionRecordDetail} from "./components/ai_interaction_record/AiInteractionRecord";
import {DeveloperPortal} from "./components/developer_portal/DeveloperPortal";
import { CreateInvoiceView } from "./components/invoice/CreateInvoice"
import InvoiceDetails from "./components/invoice/InvoiceDetails"
import { InvoiceDetailsParentView } from "./components/invoice/InvoiceDetailsParentView"
import { Invoices } from "./components/invoice/Invoices"
import { InvoiceListParentView } from "./components/invoice/InvoiceListParentView"


function NavigateFunctionComponent(props:any) {
  let navigate = useNavigate();
  SetupInterceptors(navigate);
  return <></>;
}

const theme = extendTheme(
  {
    fonts: {
      heading: 'Roboto, -apple-system, system-ui, sans-serif',
      body: 'Roboto, -apple-system, system-ui, sans-serif',
    },

    colors: {
      primary: {
        50: '#f2e5f7',
        100: '#dcb7e1',
        200: '#c486cb',
        300: '#ac54b4',
        400: '#9a38a6',
        500: '#8d53a2', // main primary color
      600: '#743c81',
        700: '#5b2e61',
        800: '#412141',
        900: '#281321',
      },
    }
  },
  proTheme,
)

export const App: React.FC = () =>  {

  return (
  <ChakraProvider theme={theme}>
        <BrowserRouter>
           {<NavigateFunctionComponent />}
            <Routes>

              <Route path="login" element={<LoginSelector />} />
              <Route path="login/teacher" element={<Login type={UserType.Teacher} />} />
              <Route path="login/parent" element={<Login type={UserType.Parent} />} />

              <Route element={<RequireAuth />}>
                <Route path="students/printable/:student_id" element={<PrintableStudent/>}/>\
                <Route path="/" element={<Dashboard />}>
                  <Route element={<RequireAuthTeacher />}>
                    <Route path="home" element={<Home/>}/>

                    <Route path="attendance/*" element={<Attendance />}/>
                      <Route path="attendance/homerooms/:homeroom_id" element={<AttendanceHomeroom/>}/>
                      <Route path="attendance/courses/:course_id" element={<AttendanceCourse/>}/>
                      <Route path="attendance/download" element={<AttendanceDownload/>}/>

                    <Route path="family" element={<FamilyManagerList />}/>
                    <Route path="family/:family_id" element={<FamilyRoute />}/>


                    <Route path="students" element={<Students />}>
                      <Route path=":student_id" element={<StudentProfileRoute onboarding={false} re_registration={false}/>}/>
                      <Route path=":student_id/course/:course_id" element={<StudentCourseDashboard/>}/>
                      <Route path=":student_id/homeroom/:homeroom_id" element={<StudentHomeroomDashboard/>}/>
                    </Route>



                    <Route path="class/*" element={<Class />} />
                      <Route path="class/homerooms/:homeroom_id" element={<HomeroomDashboard/>}/>
                      <Route path="class/courses/:course_id" element={<CourseDashboard/>}/>

                    {/* Report Cards */}
                    <Route path="reports/*" element={<Reports />}/>
                      <Route path="reports/courses/:course_id" element={<CourseReportDashboard />}/>
                      <Route path="reports/homerooms/:homeroom_id" element={<HomeroomReportDashboard />}/>

                    <Route path="invoices/*" element={<Invoices />} />
                      <Route path="invoice/create-invoice" element={<CreateInvoiceView />}/>
                      <Route path="invoice/:invoice_id" element={<InvoiceDetails />}/>

                    <Route path="markbook/*" element={<Markbook />}/>
                      <Route path="markbook/courses/:course_id" element={<MarkbookCourse/>}/>
                      <Route path="markbook/homerooms/:homeroom_id" element={<HomeroomCoursesList base_url={'markbook'}/>}/>
                    {/*<Route path="markbook/homeroom/:homeroom_id" element={<SubjectList/>}/>*/}
                    {/*<Route path="markbook/homeroom/:homeroom_id/subject/:subject_id" element={<MarkbookSubject/>}/>*/}

                    <Route path="onboarding" element={<OnboardingList />}>
                      <Route path=":student_id" element={<StudentProfileRoute onboarding={true} re_registration={false}/>}/>
                    </Route>

                    <Route path="admin_portal" element={<AdminPortal/>}/>
                    <Route path="admin_portal/semester/:semester_id" element={<SemesterDashboard/>}/>
                    <Route path="admin_portal/notification/:notification_id" element={<NotificationDetail/>}/>

                    <Route path="developer_portal" element={<DeveloperPortal/>}/>
                    <Route path="developer_portal/ai_interaction_record/:ai_interaction_record_id" element={<AiInteractionRecordDetail/>}/>

                    <Route path="reregistration" element={<ReRegistrationList />}/>
                    <Route path="reregistration/:student_id" element={<StudentProfileRoute onboarding={false}  re_registration={true}/>}/>

                    <Route path="violations" element={<Violations/>}/>
                      <Route path="violations/homeroom/:homeroom_id" element={<ViolationsHomeroom/>}/>
                      <Route path="violations/course/:course_id" element={<ViolationsCourse/>}/>

                    <Route path="teachers" element={<TeacherListRoute />}/>
                    <Route path="teachers/:teacher_id" element={<TeacherProfileRoute/>}/>
                  </Route>


                  {/* Parent */}
                  <Route path="/parent">
                    <Route path="home" element={<HomeParent/>}/>
                    <Route path="family" element={<FamilyList/>}/>
                    <Route path="invoices" element={<InvoiceListParentView />}/>
                    <Route path="invoices/:invoice_id" element={<InvoiceDetailsParentView />}/>
                    <Route path="family/create" element={<FamilyCreate/>}/>
                    <Route path="family/update/:family_id" element={<FamilyEdit/>}/>
                    <Route path="student/:student_id/*" element={<ParentStudent/>}/>
                    {/* todo: check what is this route for */}
                    <Route path="student/forms/:_form_type/:student_id" element={<FormStatusRoute/>}/>

                    <Route path="student/:student_id/attendance/homeroom/:homeroom_id/" element={<AttendanceHomeroomParents/>}/>
                    <Route path="student/:student_id/attendance/homeroom/:homeroom_id/excuse/:selected_date" element={<AttendanceHomeroomParents/>}/>
                    <Route path="student/:student_id/attendance/course/:course_id" element={<AttendanceCourseParents/>}/>
                    <Route path="student/:student_id/attendance/course/:course_id/excuse/:selected_date" element={<AttendanceCourseParents/>}/>
                  </Route>

                  <Route path="payment_confirmed" element={<PaymentConfirmed/>}/>
                </Route>


              </Route>

              <Route path="*" element={<NotFound/>}/>

            </Routes>
        </BrowserRouter>


    </ChakraProvider>
  )
}
