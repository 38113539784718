import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { Badge, FormLabel, HStack, Skeleton, Spacer, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FiPlus } from 'react-icons/fi';
import { CustomFieldsGroupAPI } from '../../api/CustomFieldsGroupAPI';
import { useChipCell } from '../../hooks/useChipCell';
import { CustomField, CustomFieldType, customFieldTypeColor } from '../../models/custom_field';
import { CustomFieldsGroup } from '../../models/custom_fields_group';
import { BlCard } from '../layout/Card';
import { LightButton } from '../layout/LightButton';
import { TableContent } from '../table/TableContent';
import { CreateCustomFieldDialog } from './CreateFieldDialog';

export const CustomFieldsGroupCard: React.FC<{ group: CustomFieldsGroup }> = ({ group }) => {

    const [fields, setFields] = useState<CustomField[]>();

    const [selectedField, setSelectedField] = useState<CustomField>();
    const [fieldDetailsOpen, setFieldDetailsOpen] = useState<boolean>(false);
    const [newFieldDialogOpen, setNewFieldDialogOpen] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);

    const toast = useToast();

    const fieldsTableColumns = [
        { Header: "Name", accessor: "name" },
        { Header: "Type", accessor: "field_type", Cell: (data: CustomFieldType) => useChipCell(data, customFieldTypeColor[data]) },
        { Header: "Required", accessor: "is_required", Cell: (isRequired: string) => isRequired ? <CheckIcon color="green.500" /> : <CloseIcon color="red.500" /> },
        { Header: "Misc", accessor: "misc", Cell: (misc: string) => <>{misc.slice(0, 30) + (misc.length > 30 ? "..." : "")}</> },
        { Header: "Archived", accessor: "is_archived", Cell: (is_archived: boolean) => <Badge colorScheme={is_archived ? "red" : "green"}> {is_archived ? "Archived" : "Active"} </Badge>}
    ];


    const fetchFields = async () => {
        setLoading(true);

        try {
            const fields = await CustomFieldsGroupAPI.get_group_custom_fields(group._id);
            const formattedFields = fields.map((field: CustomField) => addExtraDataToRow(field));
            setFields(formattedFields);
        } catch (error: any) {
            toast({
                title: 'Error',
                description: "Failed to fetch fields data" + error.response.data,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });

        } finally {
            setLoading(false);
        }
    }

    const onFieldRowClick = (field: CustomField) => {
        setSelectedField(field);
        setFieldDetailsOpen(true);
    }

    const addExtraDataToRow = (field: CustomField) => {
        return {
            ...field,
            misc: field.regex_pattern ? field.regex_pattern : (field.options ? "[" + field.options.join(", ") + "]" : "-"),
            run_func: () => onFieldRowClick(field),
        }
    }

    const addNewFieldToGroup = (newField: CustomField) => {
        newField = addExtraDataToRow(newField);
        setFields((prevFields) => prevFields ? [...prevFields, newField] : [newField]);
    }

    const updateFieldInGroup = (updatedField: CustomField) => {
        setFields((prevFields) => prevFields!.map((field) => field._id.$oid === updatedField._id.$oid ? addExtraDataToRow(updatedField) : field));
    };

    useEffect(() => {
        fetchFields();
    }, []);


    return (
        <BlCard>
            <HStack>
                <FormLabel fontSize={"lg"} fontWeight="bold">
                    {group.group_name}: {group.profile_section}
                </FormLabel>

                <Spacer />

                <LightButton
                    color='green'
                    leftIcon={<FiPlus />}
                    onClick={() => setNewFieldDialogOpen(true)}>
                    Add Field
                </LightButton>
            </HStack>

            {loading ? (
                <>
                    <Skeleton height='100px' />
                    <Skeleton height='100px' />
                    <Skeleton height='100px' />
                    <Skeleton height='100px' />
                </>
            ) : (
                <TableContent columns={fieldsTableColumns} data={fields} />
            )}

            {newFieldDialogOpen && (
                <CreateCustomFieldDialog
                    isOpen={newFieldDialogOpen}
                    onClose={() => setNewFieldDialogOpen(false)}
                    onFieldAdded={addNewFieldToGroup}
                    groupId={group._id}
                />
            )}

            {fieldDetailsOpen && selectedField && (
                <CreateCustomFieldDialog
                    isOpen={fieldDetailsOpen}
                    onClose={() => setFieldDetailsOpen(false)}
                    fieldToEdit={selectedField} 
                    groupId={selectedField.group_id}       
                    onFieldUpdated={updateFieldInGroup} 
                />
            )}
        </BlCard>
    );
}