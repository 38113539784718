import { DateTime } from "../utils/DateTime"

export interface Item {
    name: string,
    price: number
}

export interface Transaction {
    items: Array<Item>
    status: PaymentStatus
    date: DateTime
    description: string
}

export enum PaymentStatus {
    Created = "Created",
    Declined = "Declined",
    Paid = "Paid"
}

export const PaymentStatusColor: Record<PaymentStatus, string> = {
    [PaymentStatus.Created]: "blue",
    [PaymentStatus.Declined]: "red",
    [PaymentStatus.Paid]: "green"
}

export class Transaction {
    items: Array<Item>
    status: PaymentStatus
    date: DateTime
    description: string
    constructor(data: any){
        this.items = data.items
        this.status = data.status
        this.date = new DateTime(data.date)
        this.description = data.description
    }
}