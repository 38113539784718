import instance from "../api/ApiCommon";
import { processErrorMessage } from "../utils/error";
import { NavigateFunction } from "react-router-dom";
import LocalStorageService from "../api/LocalStorageService";

const localStorageService = LocalStorageService.getInstance();

let isRefreshing = false; // Flag to track if the token is being refreshed
let refreshSubscribers: Array<(newToken: string) => void> = []; // List of pending requests to retry

// Subscribe to token refresh completion
const subscribeTokenRefresh = (callback: (newToken: string) => void) => {
    refreshSubscribers.push(callback);
};

// Notify all subscribers with the refreshed token
const onRefreshed = (newToken: string) => {
    refreshSubscribers.forEach((callback) => callback(newToken));
    refreshSubscribers = [];
};

const SetupInterceptors = (navigate: NavigateFunction) => {
    // Attach request interceptor
    instance.interceptors.request.use(
        (config) => {
            const token = localStorageService.getAccessToken();
            if (token) {
                config.headers!["Authorization"] = "Bearer " + token;
            } else {
                LocalStorageService.getInstance().clearToken();
                navigate("/login");
            }
            return config;
        },
        (error) => Promise.reject(error)
    );

    // Attach response interceptor
    instance.interceptors.response.use(
        (response) => response, // Pass through successful responses
        async (error) => {
            const originalRequest = error.config;
            const errorMessage = processErrorMessage(error);
            const errorCode = error.response?.status || 599; // Default to 599 for unknown errors

            // Handle 401 errors (Unauthorized)
            if (errorCode === 401) {
                if (
                    errorMessage.toLowerCase().includes("token has expired") &&
                    !originalRequest._retry
                ) {
                    originalRequest._retry = true; // Mark request as retried

                    if (!isRefreshing) {
                        isRefreshing = true;

                        try {
                            // Refresh the token
                            const user = LocalStorageService.getInstance().getUser()!;
                            const newToken = await LocalStorageService.getInstance().setToken(
                                user.firebase_token
                            );

                            isRefreshing = false;

                            // Notify all queued requests with the new token
                            onRefreshed(newToken.token);
                        } catch (refreshError) {
                            isRefreshing = false;
                            LocalStorageService.getInstance().clearToken();
                            navigate("/login");
                            return Promise.reject(refreshError);
                        }
                    }

                    // Queue the request until the token is refreshed
                    return new Promise((resolve) => {
                        subscribeTokenRefresh((newToken) => {
                            originalRequest.headers["Authorization"] = "Bearer " + newToken;
                            resolve(instance(originalRequest));
                        });
                    });
                } else {
                    // If token refresh fails or token is invalid, clear and redirect to login
                    LocalStorageService.getInstance().clearToken();
                    navigate("/login");
                }
            }

            // Handle other types of errors (e.g., CORS or network errors)
            if (!error.response) {
                console.error("Network or CORS error:", errorMessage);
            }

            // Return a wrapped error for further handling
            const wrappedError = {
                response: {
                    status: errorCode,
                    data: errorMessage,
                },
            };

            return Promise.reject(wrappedError);
        }
    );
};

export default SetupInterceptors;
