export enum ProfileSection {
    Overview = "Overview",
    Profile = "Profile",
    Family = "Family",
    Health = "Health",
    History = "History",
    Documents = "Documents",
    Financial = "Financial",
    Donation = "Donation",
    Misc = "Misc",
    Incidents = "Incidents",
    Transactions = "Transactions",
    Forms = "Forms",
    Roles = "Roles",
    Schedule = "Schedule",
}

export interface OrganizationProfileSettings {
  studentProfileSections: ProfileSection[];
  parentProfileSections: ProfileSection[];
  teacherProfileSections: ProfileSection[];
}
