import React, { useState } from "react";
import { FormLabel, Box } from '@chakra-ui/react';
import { ObjectId } from "../../utils/ObjectId";
import { FlexWrap } from "../layout/FlexWrap";
import StudentLookup from "../students/StudentsLookup";
import { ReportsQueue } from "./ReportsQueue";
import EmptyPlaceholder from '../layout/EmptyPlaceholder'

export const ReportStudentsView = React.memo(() => {
	const [selected_student, set_selected_student] = useState<ObjectId>();

    return (
		<>
		<FlexWrap mb={4}>
			<Box minW={{md: 350}}>
				<FormLabel>Select Student</FormLabel>

				<StudentLookup onSelect={set_selected_student} />
			</Box>
		</FlexWrap>

		{ selected_student 
			? <ReportsQueue student_id={selected_student} />
			: <EmptyPlaceholder
				title="Select a student"
				message="in order to view his reports"
			/>
		}
		</>
	)
});
