import React, { useEffect, useMemo, useState } from 'react';
import { Box, SimpleGrid, FormLabel, Text, HStack, Grid, Flex, Heading } from '@chakra-ui/react';
import { BlCard } from '../layout/Card';
import { to_oid } from '../../utils/ObjectId';
import { getInvoiceUserInfo, InvoiceResponse, InvoiceUserCategory, InvoiceUserCategoryColor } from '../../models/invoice';
import { InvoicePdfLink } from './InvoicePdfLink';
import { useInvoiceTotals } from '../../hooks/useInvoiceTotals';
import { useParams } from 'react-router-dom';
import { InvoiceAPI } from '../../api/InvoiceAPI';
import { StudentAPI } from '../../api/StudentAPI';
import { FamilyAPI } from '../../api/FamilyAPI';
import { useChipCell } from '../../hooks/useChipCell';

export const InvoiceDetailsParentView: React.FC = () => {
  const { invoice_id } = useParams<{ invoice_id: string }>();
  const [invoiceRes, setInvoiceRes] = useState<InvoiceResponse>();
  const [loading, setLoading] = useState<boolean>();
  const [name, setName] = useState<string>();
  const [category, setCategory] = useState<InvoiceUserCategory>();

  const invoice = useMemo(() => invoiceRes?.invoice, [invoiceRes])

  useEffect(() => {
    const fetchInvoice = async () => {
      if (!invoice_id) return;

      setLoading(true);

      try {
        const response = await InvoiceAPI.get(to_oid(invoice_id));
        setInvoiceRes(response);

        let student, family

        if (response.invoice.user) 
          student = await StudentAPI.get_student(response.invoice.user.$oid).then(res => res!);
        else if (response.invoice.family) 
          family = await FamilyAPI.get_family(response.invoice.family).then(res => res!);
        
        const { userName, userCategory } = getInvoiceUserInfo(response.invoice, student, family);

        setName(userName);
        setCategory(userCategory);

      } catch (err) {
        console.error('Error fetching invoice:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchInvoice();
  }, [invoice_id]);

  const { subtotal, discount, discountUnit, grandTotal } = useInvoiceTotals(invoice?.items || [], invoice?.coupons?.[0])


  return (
    <>
      <Heading size="md" mb={6} textAlign="center">
        Invoice Preview
      </Heading>

      <SimpleGrid columns={12} spacing={6}>
        {/* General Information */}
        <Box as="aside" gridColumn="span 4">
          <BlCard h="full">
            <FormLabel fontSize="lg" fontWeight="bold" mb={4}>
              General Information
            </FormLabel>

            <Box mb={4}>
              <Text fontWeight="semibold">Title</Text>
              <Text>{invoice?.title || 'N/A'}</Text>
            </Box>

            <Box mb={4}>
              <Text fontWeight="semibold">Description</Text>
              <Text whiteSpace="pre-wrap">{invoice?.description || 'N/A'}</Text>
            </Box>

            <Box mb={4}>
              <Text fontWeight="semibold">Bill to</Text>
              <HStack>
                <Text>{name}</Text>
                {useChipCell(category, InvoiceUserCategoryColor[category!])}
              </HStack>
            </Box>

            <Box mb={4}>
              <Text fontWeight="semibold">Due Date</Text>
              <Text>{invoice?.due_date ? invoice.due_date.toLocaleDateString() : 'N/A'}</Text>
            </Box>

            <SimpleGrid columns={2} spacing={2}>
              <Flex align="center" gap={2}>
                <Text fontWeight="semibold">Show HST:</Text>
                <Text>{invoice?.show_hst ? 'Yes' : 'No'}</Text>
              </Flex>

              <Flex align="center" gap={2}>
                <Text fontWeight="semibold">Disable Tax:</Text>
                <Text>{invoice?.disable_tax ? 'Yes' : 'No'}</Text>
              </Flex>
            </SimpleGrid>
          </BlCard>
        </Box>

        {/* Invoice Items */}
        <Box as="main" gridColumn="span 8">
          <BlCard>
            <FormLabel fontSize="lg" fontWeight="bold" mb={4}>
              Invoice Items
            </FormLabel>

            <Box borderBottom="1px solid" borderColor="gray.200" pb={2} mb={4}>
              <HStack spacing={4} fontSize="md">
                <Box flex="2" fontWeight="medium">
                  Title
                </Box>
                <Box flex="4" fontWeight="medium">
                  Description
                </Box>
                <Box flex="1" fontWeight="medium">
                  Quantity
                </Box>
                <Box flex="1" fontWeight="medium">
                  Price
                </Box>
              </HStack>
            </Box>

            {invoice?.items.map((item, idx) => (
              <Box
                key={idx}
                mb={4}
                borderBottom="1px solid"
                borderColor="gray.200"
                pb={4}
              >
                <HStack spacing={4} fontSize="md">
                  <Box flex="2">
                    <Text>{item.title}</Text>
                  </Box>
                  <Box flex="4">
                    <Text>{item.description}</Text>
                  </Box>
                  <Box flex="1">
                    <Text>{item.quantity}</Text>
                  </Box>
                  <Box flex="1">
                    <Text>${item.price?.toFixed(2)}</Text>
                  </Box>
                </HStack>
              </Box>
            ))}

            {/* Totals Section */}
            <Box mt={4}>
              <BlCard bgColor="gray.200" boxShadow="none">
                <Grid templateColumns="1fr" rowGap={3} ml={{ lg: '50%' }}>
                  <HStack justify="space-between" mb={2}>
                    <Text>Subtotal</Text>
                    <Text fontWeight="medium">${subtotal.toFixed(2)}</Text>
                  </HStack>

                  <HStack justify="space-between" mb={2}>
                    <Text>
                      Discount{' '}
                      {discountUnit && <>( {discountUnit} )</>}
                    </Text>
                    <Text fontWeight="medium" color={discount > 0 ? 'green.600' : 'black'}>
                      ${discount.toFixed(2)}
                    </Text>
                  </HStack>

                  <HStack justify="space-between" mb={2}>
                    <Text fontWeight="bold" color="blue.600">
                      Grand Total
                    </Text>
                    <Text fontWeight="bold" color="blue.600">
                      ${grandTotal.toFixed(2)}
                    </Text>
                  </HStack>
                </Grid>
              </BlCard>
            </Box>

            <HStack mt={4} justifyContent="flex-end">
              {invoice && name && <InvoicePdfLink invoice={invoice} userName={name}  />}
            </HStack>
          </BlCard>
        </Box>
      </SimpleGrid>
    </>
  );
};
