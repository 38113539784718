import { 
    Accordion, 
    AccordionItem, 
    AccordionButton, 
    AccordionPanel, 
    AccordionIcon, 
    Stack, 
    Button, 
    HStack, 
    Spacer, 
    Skeleton, 
    useToast, 
    Text 
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FiPlus } from "react-icons/fi";
import { CustomFieldsGroup } from "../../models/custom_fields_group";
import { CustomFieldsGroupAPI } from "../../api/CustomFieldsGroupAPI";
import { UserType } from "../../api/UserApi";
import { CustomFieldsGroupCard } from "./CustomFieldsGroupCard";
import { CreateCustomFieldsGroupDialog } from "./CreateCustomFieldsGroupDialog";
import { ProfileSection } from "../../models/organization";
import { OrganizationAPI } from "../../api/OrganizationAPI";

export const customFieldsUserTypes = [UserType.Student, UserType.Teacher, UserType.Parent];

export const CustomFields = () => {
    const [groups, setGroups] = useState<Record<string, CustomFieldsGroup[]>>({});
    const [newGroupDialogOpen, setNewGroupDialogOpen] = useState<boolean>(false);
    const [userTypeProfileSections, setUserTypeProfileSections] = useState<Map<UserType, ProfileSection[]>>(new Map());
    const [loadingGroups, setLoadingGroups] = useState<boolean>(false);
    const [loadingProfileSections, setLoadingProfileSections] = useState<boolean>(false);
    const [currentUserType, setCurrentUserType] = useState<UserType | null>(null);

    const toast = useToast();

    const fetchGroups = async () => {
        setLoadingGroups(true);

        const fetchedGroups: Record<string, CustomFieldsGroup[]> = {};
        for (const user_type of customFieldsUserTypes) {
            try {
                const groups = await CustomFieldsGroupAPI.get_custom_fields_groups_for_user_type(user_type);
                fetchedGroups[user_type] = groups;
            } catch (error: any) {
                toast({
                    title: "Error",
                    description: "Failed to fetch groups: " + error.response.data,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        }
        setGroups(fetchedGroups);
        setLoadingGroups(false);
    };

    const addNewGroupToUserTypesGroups = (newGroup: CustomFieldsGroup) => {
        const userType = newGroup.user_type;
        setGroups((prevData) => ({
            ...prevData,
            [userType]: [...(prevData[userType] || []), newGroup],
        }));
    };

    const fetchOrganizationProfileSettings = async () => {
        setLoadingProfileSections(true);
        try {
            const settings = await OrganizationAPI.get_organization_profile_settings();
            const map = new Map<UserType, ProfileSection[]>();

            map.set(UserType.Student, settings.studentProfileSections);
            map.set(UserType.Parent, settings.parentProfileSections);
            map.set(UserType.Teacher, settings.teacherProfileSections);

            setUserTypeProfileSections(map);
        } catch (error: any) {
            toast({
                title: "Error",
                description: "Failed to fetch profile sections: " + error.response.data,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setLoadingProfileSections(false);
        }
    };

    useEffect(() => {
        fetchGroups();
        fetchOrganizationProfileSettings();
    }, []);

    return (
        <>
            <Stack>
                <Accordion allowToggle>
                    {customFieldsUserTypes.map((type) => (
                        <AccordionItem key={type}>
                            <h2>
                                <AccordionButton onClick={() => setCurrentUserType(type)}>
                                    <Stack flex="1" textAlign="left" direction="row" justify="space-between">
                                        <Text fontWeight="bold">{type}</Text>
                                        <Text fontSize="sm" color="gray.500">
                                            {groups[type]?.length || 0} Groups
                                        </Text>
                                    </Stack>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                <HStack mb={4}>
                                    <Spacer />
                                    <Button
                                        colorScheme="green"
                                        leftIcon={<FiPlus />}
                                        onClick={() => setNewGroupDialogOpen(true)}
                                    >
                                        Add Group
                                    </Button>
                                </HStack>
                                <Stack gap="4">
                                    {loadingGroups ? (
                                        <>
                                            <Skeleton height="100px" />
                                            <Skeleton height="100px" />
                                            <Skeleton height="100px" />
                                            <Skeleton height="100px" />
                                        </>
                                    ) : (
                                        groups[type]?.map((group) => (
                                            <CustomFieldsGroupCard key={group._id.$oid} group={group} />
                                        ))
                                    )}
                                </Stack>
                            </AccordionPanel>
                        </AccordionItem>
                    ))}
                </Accordion>
            </Stack>

            {newGroupDialogOpen && !loadingProfileSections && currentUserType && (
                <CreateCustomFieldsGroupDialog
                    isOpen={newGroupDialogOpen}
                    onClose={() => setNewGroupDialogOpen(false)}
                    onGroupAdded={addNewGroupToUserTypesGroups}
                    initialUserType={currentUserType}
                    userTypeProfileSections={userTypeProfileSections}
                />
            )}
        </>
    );
};