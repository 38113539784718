import { object, string, number, array, date, boolean } from 'yup';
import { ObjectIdSchema } from './schema';

export const CreateInvoiceSchema = object().shape({
  title: string().required('Title is required'),
  description: string().required('Description is required'),
  show_hst: boolean().required('Show HST is required').default(false),
  disable_tax: boolean().required('Disable Tax is required').default(false),
  
  user: ObjectIdSchema.nullable(),
  family: ObjectIdSchema.nullable(),
  course: ObjectIdSchema.nullable(),
  homeroom: ObjectIdSchema.nullable(),
  
  items: array().of(
    object().shape({
      title: string().required('Item title is required'),
      description: string().required('Item description is required'),
      price: number().typeError('Price must be a number').required('Price is required').min(0, 'Price cannot be negative'),
      quantity: number().typeError('Quantity must be a number').required('Quantity is required').min(1, 'Quantity cannot be less than 1')
    })
  ).required().min(1, 'At least one item is required'),
  
  coupons: array().of(
    object().shape({
      title: string().required('Coupon title is required'),
      coupon_type: object().shape({
        Percentage: number().min(0).max(100),
        Amount: number().min(0),
      }).required('Coupon type is required'),
    })
  ).required(),
  
  due_date: date().typeError('Due date must be a valid date').nullable(),
}).test(
  'invoice-billing-info',
  'User, Family, Course, or Homeroom must be selected',
  function (value) {
    const { user, family, course, homeroom } = value;
    if (!user && !family && !course && !homeroom) {
      return this.createError({
        path: 'user',
        message: 'User, Family, Course, or Homeroom must be selected',
      });;
    }
    return true;
  }
);
