import { useMemo } from 'react';
import { Text } from '@chakra-ui/react';
import { Invoice, InvoiceResponse, InvoiceStatus, InvoiceStatusColor } from '../../models/invoice';
import { TableContent } from '../table/TableContent';
import { useChipCell } from '../../hooks/useChipCell';
import { convertCaseToNormal } from '../../utils/helpers';

interface InvoicesTableProps {
	loading?: boolean
	invoices?: Array<InvoiceResponse>
}

export const InvoicesTable = ({invoices = [], loading}: InvoicesTableProps) => {
	const columns = useMemo(() => [
		{
			Header: 'Number',
			accessor: 'invoice',
			Cell: (invoice: Invoice) => invoice.invoice_number,
		},
		{
			Header: 'Title',
			accessor: 'invoice',
			Cell: (invoice: Invoice) => invoice.title,

		},
		{
			Header: 'Status',
			accessor: 'status',
			Cell: (value: InvoiceStatus) => useChipCell(convertCaseToNormal(value), InvoiceStatusColor[value]),
		},
		{
			Header: 'Total Amount',
			accessor: 'total_amount',
			Cell: (value: number) => (
				<Text fontWeight="bold">
					{`$${value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
				</Text>
			)
		},
		{
			Header: 'Total Paid',
			accessor: 'total_amount_paid',
			Cell: (value: number) => (
				<Text fontWeight="bold" color="green">
					{`$${value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
				</Text>
			)
		},
	], [])

	return (
		<>
		<TableContent data={invoices} columns={columns} loading={loading} />
		</>
	);
};
