import { Box, Heading, useToast } from "@chakra-ui/react";
import { InvoiceAPI } from "../../api/InvoiceAPI";
import { CreateInvoice } from "../../models/invoice";
import { useNavigate } from "react-router-dom";
import { InvoiceForm } from "./InvoiceForm";
import { useState } from "react";

export const CreateInvoiceView = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>();

  const onSubmit = async (data: CreateInvoice) => {
    setLoading(true);
    // check if data has defined course or homeroom defined
    const hasClass = data.course || data.homeroom

    // define the function 
    const submitFunction = hasClass ? InvoiceAPI.create_for_class : InvoiceAPI.create

    try {
      await submitFunction(data);

      toast({
        title: 'Success',
        description: "Invoice Created Successfully",
        status: 'success',
        duration: 5000,
        isClosable: true,
      })

      navigate('/invoices')

    } catch (error) {
      console.error("Error creating invoice:", error);
    }

    setLoading(false);
  };
  return (
    <Box>
      <Heading size="md" mb={6} textAlign="center">
        Create Invoice
      </Heading>

      <InvoiceForm onSubmit={onSubmit} loading={loading} />
    </Box>
  );
};
