import React from 'react';
import { AsyncSelect } from 'chakra-react-select';
import { CourseAPI } from '../../api/CourseAPI';
import { ObjectId } from '../../utils/ObjectId';
import { Lookup } from '../../models/lookup';

interface CourseLookupProps {
  onSelect: (courseId?: ObjectId) => void;
}

export const CoursesLookup: React.FC<CourseLookupProps> = ({ onSelect }) => {
  const formatOptions = (courses: CourseAPI.ListCourse[]) : Lookup[] => {
    return courses.map(course => ({
      value: course._id,
      label: course.name
    }));
  };

  const loadOptions = async (inputValue: string) => {
    if (!inputValue) return [];

    try {
      const courses = await CourseAPI.course_list(undefined, undefined, inputValue, undefined, undefined, true).then(res => res?.courses || []);

      return formatOptions(courses);
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const handleChange = (selectedOption: Lookup | null) => {
    if (selectedOption) {
      onSelect(selectedOption.value);
    } else {
      onSelect(undefined);
    }
  };

  return (
	<AsyncSelect
		placeholder="Search course by name..."
		loadOptions={loadOptions}
		onChange={handleChange}
		chakraStyles={{
		menu: (provided) => ({ ...provided, zIndex: 9999, boxShadow: 'lg' }),
		}}
	/>
  );
};