import { useMemo } from "react";
import { Coupon, InvoiceItem } from "../models/invoice";

export function useInvoiceTotals(items: InvoiceItem[], coupon?: Coupon) {
  
	const { subtotal, discount, discountUnit, grandTotal } = useMemo(() => {
	  const subtotalCalc = items.reduce((acc, item) => {
		const price = item.price || 0;
		const quantity = item.quantity || 0;
		return acc + price * quantity;
	  }, 0);
  
	  let discountCalc = 0;
	  let discountUnitCalc: string | undefined;
  
	  if (coupon && coupon.coupon_type) {
		if (coupon.coupon_type.Percentage) {
		  discountCalc = subtotalCalc * (coupon.coupon_type.Percentage / 100);
		  discountUnitCalc = '%';
		} else if (coupon.coupon_type.Amount) {
		  discountCalc = Number(coupon.coupon_type.Amount);
		  discountUnitCalc = `$${coupon.coupon_type.Amount} fixed`;
		}
	  }
  
	  if (discountCalc === 0) {
		discountUnitCalc = undefined;
	  }
  
	  const grandTotalCalc = subtotalCalc - discountCalc;
  
	  return {
		subtotal: subtotalCalc,
		discount: discountCalc,
		discountUnit: discountUnitCalc,
		grandTotal: grandTotalCalc,
	  };
	}, [items, coupon]);
  
	return { subtotal, discount, discountUnit, grandTotal };
  }