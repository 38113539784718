import { DateTime } from "../utils/DateTime";
import { ObjectId } from "../utils/ObjectId";
import { Family } from "./family";
import { Student } from "./student";
import { Transaction } from "./transaction";

export type CouponType = {
  Percentage?: number 
  Amount?: number
}

export interface CouponFormData {
  title: string;
  discountType: "Percentage" | "Amount";
  discountValue: number;
}

export enum InvoiceStatus {
  Paid = "Paid",
  NotPaid = "NotPaid",
  Overdue = "Overdue",
}

export const invoiceStatusArray = Object.values(InvoiceStatus).map((status) => ({
  value: status,
  label: status,
}));

export enum InvoiceUserCategory {
  Student = 'Student',
  Family = 'Family',
  Course = 'Course',
  Homeroom = 'Homeroom',
}

export const InvoiceUserCategoryArray = Object.values(InvoiceUserCategory).map((category) => ({
  value: category,
  label: category,
}));

export const InvoiceStatusColor: Record<InvoiceStatus, string> = {
  [InvoiceStatus.Paid]: 'green',
  [InvoiceStatus.NotPaid]: 'blue',
  [InvoiceStatus.Overdue]: 'red',
};

export const InvoiceUserCategoryColor: Record<InvoiceUserCategory, string> = {
  [InvoiceUserCategory.Student]: 'green',
  [InvoiceUserCategory.Family]: 'blue',
  [InvoiceUserCategory.Course]: 'purple',
  [InvoiceUserCategory.Homeroom]: 'orange',
};

export interface InvoiceItem {
  title: string;
  description: string;
  price: number;
  quantity: number;
}

export interface Coupon {
  title: string;
  coupon_type: CouponType;
}

export class Invoice {
  _id: ObjectId;
  organization: ObjectId;
  invoice_number: number;
  title: string;
  description: string;
  show_hst: boolean;
  disable_tax: boolean;
  user?: ObjectId;
  family?: ObjectId;
  items: InvoiceItem[];
  coupons: Coupon[];
  due_date?: Date;

  constructor(data : any) {
    this._id = data._id
    this.organization = data.organization
    this.invoice_number = data.invoice_number
    this.title = data.title
    this.description = data.description
    this.show_hst = data.show_hst
    this.disable_tax = data.disable_tax
    this.user = data.user
    this.family = data.family
    this.items = data.items
    this.coupons = data.coupons

    if (data.due_date) this.due_date = new DateTime(data.due_date).to_date()
  }
}

export interface InvoiceResponse {
  total_amount: number
  total_amount_paid: number
  status: InvoiceStatus
  transactions: Transaction[]
  invoice: Invoice
}

export interface InvoiceListResponse {
  invoices: InvoiceResponse[]
  invoices_count: number
}

export interface InvoiceFilter {
  per_page: number,
  page: number,
  title?: string,
  status?: InvoiceStatus
}

export interface CreateInvoice {
  title: string;
  description: string;
  show_hst: boolean;
  disable_tax: boolean;
  user?: ObjectId | null;
  family?: ObjectId | null;
  homeroom?: ObjectId | null;
  course?: ObjectId | null;
  items: InvoiceItem[];
  coupons: Coupon[];
  due_date?: Date | null;
}

export interface InvoiceUserInfo {
   userName: string, 
   userCategory: InvoiceUserCategory 
}

export function getInvoiceUserInfo(invoice?: Invoice, student?: Student, family?: Family): InvoiceUserInfo {    
  if (!invoice) return {
    userName: '',
    userCategory: InvoiceUserCategory.Student
  }
  if (invoice.user) 
    return { 
      userName: `${student?.first_name} ${student?.last_name}`,
      userCategory: InvoiceUserCategory.Student
    }
   else if (invoice.family) 
    return { 
      userName: family?.name || "",
      userCategory: InvoiceUserCategory.Family
    }
   else 
    return { 
      userName: "",
      userCategory: InvoiceUserCategory.Student
    }
}