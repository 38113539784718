import {
Box,
Button,
Divider,
Flex,
FormControl,
FormLabel,
Input,
Stack,
Select,
useColorModeValue,
useToast,
} from '@chakra-ui/react'
import * as React from 'react'
import 'react-datepicker/dist/react-datepicker.css';
import { StudentAPI } from '../../../api/StudentAPI';
import { Grades, Student, StudentProfileData } from '../../../models/student';
import { DateTime } from '../../../utils/DateTime';
import DatePicker from '../../DatePicker'

interface Card {
    student: Student
    set_student: React.Dispatch<React.SetStateAction<Student | undefined>>
}

export const ProfileCard = React.memo(({student, set_student}: Card) => {
    const toast = useToast()

    const required_info = () => {
        return student.first_name != "" && 
            student.last_name != "" && 
            student.gender != "" && 
            student.grade != ""
    }

    const update_profile_state = (name: any, value: any) => {
        set_student(
            prevState => (prevState ? {
                ...prevState,
                [name]: value
            } : undefined)
        );
    }

    const update_profile = () => {
        if(required_info()){
            StudentAPI.update_profile(student._id, {
                first_name: student.first_name, 
                last_name: student.last_name, 
                gender: student.gender, 
                grade: student.grade, 
                date_of_birth: student.date_of_birth
            } as StudentProfileData)
                .then((res) => {
                    StudentAPI.get_student(student._id.$oid).then(res => set_student(res!))
                    toast({
                        title: 'Success',
                        description: "Saved Profile",
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                    })
                })
                .catch((err) => {
                    toast({
                        title: 'Error.',
                        description: err.response.data,
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    })
                })
        }
        else {
            toast({
                title: 'Error.',
                description: "Please fill in all required fields.",
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        }
        
    }

    return (
        <Box
            as="form"
            bg="bg-surface"
            boxShadow={useColorModeValue('sm', 'sm-dark')}
            borderRadius="lg"
            flex="1"
        >
            <Stack spacing="5" px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
                <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                    <FormControl id="first_name" isRequired>
                        <FormLabel>First Name</FormLabel>
                        <Input 
                            value={student.first_name} 
                            onChange={(value) => {
                                update_profile_state(value.target.id, value.target.value)
                            }}
                        />
                    </FormControl>
                    <FormControl id="last_name" isRequired>
                        <FormLabel>Last Name</FormLabel>
                        <Input 
                            value={student.last_name}
                            onChange={(value) => {
                                update_profile_state(value.target.id, value.target.value)
                            }}
                        />
                    </FormControl>
                </Stack>
                <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                    <FormControl id="gender" isRequired>
                        <FormLabel>Gender</FormLabel>
                        <Select 
                            value={student.gender}
                            onChange={(value) => {
                                update_profile_state(value.target.id, value.target.value)
                            }}
                        >
                                <option value='F'>Female</option>
                                <option value='M'>Male</option>
                        </Select>
                    </FormControl>
                    <FormControl id="date_of_birth" isRequired>
                        <FormLabel>Date of Birth</FormLabel>
                        <DatePicker 
                            selectedDate={student.date_of_birth?.to_date()} 
                            onChange={(value) => {
                                update_profile_state("date_of_birth", DateTime.from_date(value as Date))
                            }}
                        />
                    </FormControl>
                    <FormControl id="grade" isRequired>
                        <FormLabel>Grade</FormLabel>
                        <Select 
                            value={student.grade}
                            onChange={(value) => {
                                update_profile_state(value.target.id, value.target.value)
                            }}
                        >
                            {Array.from(Grades).map(([key, value]) => (
                                <option value={key}>{value}</option>
                            ))
                            }
                        </Select>
                    </FormControl>
                </Stack>
            </Stack>
            <Divider />
            <Flex direction="row-reverse" py="4" px={{ base: '4', md: '6' }}>
                <Button onClick={update_profile}>
                    Save
                </Button>
            </Flex>
        </Box>
    )

})
