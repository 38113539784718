import React from "react"
import {
    Box,
    Stack,
    Skeleton,
    useToast,
    Input,
    Text,
    Alert,
    Badge,
    HStack,
    PopoverTrigger,
    Button,
    PopoverArrow,
    PopoverCloseButton,
    PopoverBody,
    PopoverHeader,
    PopoverContent, Popover, IconButton, Spacer,
}
    from '@chakra-ui/react'
import {TableContent} from "../../table/TableContent";
import 'react-datepicker/dist/react-datepicker.css';
import { Student } from "../../../models/student";
import {EvaluationEntryAPI} from "../../../api/EvaluationEntryAPI";
import {QuestionIcon} from "@chakra-ui/icons";

interface StudentMarkData {
    student: Student,
    final_mark?: number
}

interface MarkbookProps {
    entry_data: Array<StudentMarkData> | undefined,
}

export const StudentsFinalMarkTable = React.memo(({entry_data}: MarkbookProps) =>  {
    return (
        <Box>
            <HStack>
                <Spacer></Spacer>
                <Spacer></Spacer>
                <Spacer></Spacer>
                <Popover>
                  <PopoverTrigger>
                    <Button leftIcon={<QuestionIcon/>}>
                        Maximum Potential Mark
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverHeader>Maximum Potential Mark!</PopoverHeader>
                        <PopoverBody>The Maximum Potential Mark is the highest mark a student can achieve based on the available evaluations.</PopoverBody>
                        <PopoverBody>For instance, if a class has four tests, each worth 25%. And a student received a grade of 20/25 on their first test. So the student's maximum potential mark would be the remaining ungraded evaluations + their current average.</PopoverBody>
                        <PopoverBody>In this case, it would be 20% + 75% = 95%</PopoverBody>
                  </PopoverContent>
                </Popover>
                <Spacer></Spacer>
            </HStack>
            {entry_data ?
                    <TableContent data={entry_data} columns={MarkbookColumns}/>
                :
                    <Stack>
                        <Skeleton height='100px' />
                        <Skeleton height='100px' />
                        <Skeleton height='100px' />
                        <Skeleton height='100px' />
                        <Skeleton height='100px' />
                        <Skeleton height='100px' />
                        <Skeleton height='100px' />
                        <Skeleton height='100px' />
                        <Skeleton height='100px' />
                    </Stack>
            }
        </Box>
    );
} );

let MarkbookColumns = [
    {
        Header: "Student Name",
        accessor: "student",
        Cell: function StatusCell(student: Student) {

            return (
                <Box w='200px'>
                    <Text>{student.first_name + " " + student.last_name}</Text>
                </Box>
            )
        },
    },
    {
        Header: "Final Mark",
        accessor: "final_mark",
        Cell: function StatusCell(final_mark?: number) {


            if (final_mark) {
                return (
                    <Box w='120px'>
                        <Badge colorScheme='green'>
                            {final_mark!.toFixed(2) + "%"}
                        </Badge>
                    </Box>
                )
            } else {
                return (
                    <Box w='120px'>
                        <Badge colorScheme='orange'>
                            No Mark!
                        </Badge>
                    </Box>
                )
            }
        },
    },
    {
        Header: "Maximum Potential Mark",
        accessor: "maximum_potential_mark",
        Cell: function StatusCell(maximum_potential_mark?: number) {


            if (maximum_potential_mark) {
                return (
                    <Box w='120px'>
                        <Badge colorScheme='blue'>
                            {maximum_potential_mark!.toFixed(2) + "%"}
                        </Badge>
                    </Box>
                )
            } else {
                return (
                    <Box w='120px'>
                        <Badge colorScheme='orange'>
                            No Mark!
                        </Badge>
                    </Box>
                )
            }
        },
    },
]
